 
 
import React, { useRef, useContext, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, Grid,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { array, bool, func } from 'prop-types';
import ReactToPrint from 'react-to-print';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import PrintDropZoneDialogContainer from './PrintDropZoneDialogContainer';
import Button from '../../../components/Button';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../../components/tutorial/Tutorial.provider';

import './PrintDropZoneStyles.css';

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    @page {
      size: landscape !important;
    }
    .pagebreak {
      page-break-before: always !important;
    }
  }
`;

const PrintDropZoneDialog = ({
  printDropZoneData, mergeOrdersBasedOnStyleColors, printDialogOpen, setPrintDialogOpen,
}) => {
  const {
    stepIndex, setStepIndex, startTutorial, isbackClicked,
  } = useContext(tutorialContext);

  const printRef = useRef();

  useEffect(() => {
    if (startTutorial) {
      if ((printDialogOpen && !isbackClicked && !printRef?.current) || (isbackClicked && printDialogOpen && stepIndex === 13)) {
        setTimeout(() => {
          setStepIndex(13);
        }, 10);
      }
    }
  }, [startTutorial, printRef, stepIndex, isbackClicked, printDialogOpen, printRef?.current]);

  const { getMessage } = useContext(SimWebContext);

  const theme = createTheme(({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            overflowY: 'hidden',
          },
        },
      },
    },
  }));

  return (
    <div data-testid="printDropZoneDialog-container">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Dialog
            open={printDialogOpen}
            onClose={() => setPrintDialogOpen(false)}
            maxWidth="md"
            fullWidth
            data-testid="printDropZoneDialog"
          >
            <DialogContent style={{ marginTop: 30, position: 'inherit' }} ref={printRef}>
              {printDropZoneData?.map((item, index) => (
                <Grid
                  key={index}
                  style={{ pageBreakAfter: 'always' }}
                >
                  <PrintDropZoneDialogContainer
                    item={item}
                    mergeOrdersBasedOnStyleColors={mergeOrdersBasedOnStyleColors}
                  />
                </Grid>
              ))}
            </DialogContent>
            <DialogActions id="printDropZoneDialog-footer">
              <ReactToPrint
                trigger={() => (
                  <Button
                    id="sim-printDropZoneButton"
                    buttonText={getMessage('print')}
                  />
                )}
                content={() => printRef.current}
                onAfterPrint={() => {
                  newRelicAction('inbound-visibility-dropzone-printed', { isPrintModalEnabled: true });
                  setPrintDialogOpen(false);
                }}
                pageStyle={pageStyle}
              />
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

PrintDropZoneDialog.propTypes = {
  printDropZoneData: array,
  mergeOrdersBasedOnStyleColors: func.isRequired,
  setPrintDialogOpen: func.isRequired,
  printDialogOpen: bool.isRequired,
};

PrintDropZoneDialog.defaultProps = {
  printDropZoneData: [],
};

export default PrintDropZoneDialog;
