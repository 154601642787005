import LoginPage from './retailLogin/LoginPage';
import WrapperLoginPage from './WrapperLoginPage';
import DashboardPage from '../dashboardPage/DashboardPage';

/**
 * use the session storage variable source to determine which login page to use
 * if iOS then use wrapper login page
 * else use normal retail login page
 */
 
const loginFactory = () => {
  const source = sessionStorage.getItem('loginSource');
  if (source === 'iOS') return WrapperLoginPage;
  if (source === 'retail') return LoginPage;
  if (source === 'true') return DashboardPage;
};

export default loginFactory;
