 
import React, {
  useMemo,
  useState,
  useContext,
} from 'react';
import {
  object, node, array, bool, number, func,
} from 'prop-types';
import classNames from 'classnames';
import {
  Card,
  Grid,
  Typography,
  DialogContent,
  Tabs,
  Tab,
  Paper,
  TextField,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import SwipeableViews from 'react-swipeable-views';
import MUIDataTable from 'mui-datatables';
import Button from '../Button';
import ChevronButton from '../ChevronButton';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { getRfidTableHeader } from '../RFIDTableHeader';
import getStockOnHand from '../../utils/getStockOnHand';
import { sortSizes } from '../../utils/sort/sortSizes';
import { sortByStockOnHand } from '../../utils/sort/sortByStockOnHand';
import getFormattedPrice from '../../utils/getFormattedPrice';
import CustomSpinner from '../CustomSpinner';
import AdditionalProductDetails from './AdditionalProductDetails';
import ProductInformation from './ProductInformation';
import ShoeboxFragment from '../ShoeboxFragment';
import { defaultSort } from '../../utils/sort/defaultSort';

import { CPAContext } from '../../context/cpa/CPA.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../tutorial/Tutorial.provider';

import './ProductDetails.css';
// MUI is applying a default of 0 for the padding - need to override
const theme = (param) => createTheme(({
  components: {
    MUIDataTableViewCol: {
      styleOverrides: {
        root: {
          padding: '16px 24px 16px 24px !important',
        },
      },
    },
    ...param,
  },
}));
const loadingStyles = {
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
};

/**
 * A reusable card for modal/non-modal details on a give product
 * @param {object} product - the product in current view
 * @param {node} isModal - if the view is modal we add navigation to the card
 * @param {array} optionalColumns - a list of extra columns to display in the gtin table other than the default gtin, size and soh columns
 * @param {boolean} isUnitCountEnabled - get Inbound Units if true
 * @param {number} picklistIndex - the index of the picklist item to display
 * @param {function} setPicklistIndex - set the index of the picklist item
 * @param {array} selectedStyles - array of style colors to use for the picklist creation
 *  @param {boolean} isCreatingPicklist - bool indicating if the user is creating a picklist
 *  @param {function} setSelectedPicklistItems - function to set selected picklist items
 *  @param {array} picklistItems - styles selected for the picklist creation
 *  @param {function} setIsOpen -func that controls the open/closing of the picklist product modal
 *  @param {function} setFormOpen - func that controls the open/closing of the picklist form
*/
const ProductDetailCard = ({
  product, isModal, optionalColumns, isUnitCountEnabled, picklistIndex, setPicklistIndex,
  selectedStyles, isCreatingPicklist, setSelectedPicklistItems, picklistItems, setIsOpen, setFormOpen,
}) => {
  const { loading, error } = useContext(CPAContext);
  const {
    currencyCode, locale, isIOS, isOffsiteEnabled, isOffsiteInactive, getMessage, storeConfig, iso2Country,
  } = useContext(SimWebContext);

  const { startTutorial, isbackClicked, setBackWasClicked } = useContext(tutorialContext);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const [productPrice, setPrice] = useState(<CustomSpinner
    size="5px"
    color="#5CDAA8"
    loading
  />);
  const [sohTypeSelected, setSohTypeSelected] = useState(rfidEnabled ? 'RFID SOH' : 'SOH');
  const isRfidSelected = sohTypeSelected === 'RFID SOH';
  const [tabValue, setTabValue] = useState(0);

  const totalStyles = selectedStyles?.length;
  const index = picklistIndex + 1;

  const memoizedGtinTable = useMemo(() => {
    const { sizes } = product || {};

    if (!sizes) {
      return null;
    }

    const getQuantityLabel = () => (isRfidSelected ? getMessage('rfidStock') : getMessage('soh').toUpperCase());
    const rfidColumn = getRfidTableHeader('sohQuantity', getQuantityLabel(), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled);

    const columns = [{
      name: 'gtin',
      label: getMessage('upc'),
    },
    {
      name: 'sizeName',
      label: getMessage('size'),
    },
    rfidColumn,
    {
      name: 'dotcomAvailability',
      label: 'Nike.com',
      options: {
        display: ((!!iso2Country && optionalColumns.includes('dotcomAvailability')) && !isCreatingPicklist) || 'excluded', // iso2Country for merchGroup
      },
    },
    {
      name: 'inboundUnits',
      label: getMessage('inboundUnits'),
      options: {
        display: (isUnitCountEnabled && !isCreatingPicklist) || 'excluded',
      },
    },
    {
      name: 'quantity',
      label: getMessage('quantity'),
      options: {
        filter: false,
        sort: true,
        display: isCreatingPicklist || 'excluded',
        customBodyRender: (value, tableMeta) => {
          const gtin = tableMeta.rowData[0];
          const soh = tableMeta.rowData[2];
          const quantity = picklistItems?.find(item => (item.gtin === gtin))?.quantityRequested;
          const { rowIndex } = tableMeta; // Get the row index
          let currentValue = quantity ?? 0;

          if (soh === '-' || soh === '0 / 0') {
            return ('-');
          }

          return (
            <TextField
              data-testid={`quantity-input-${rowIndex}`}
              defaultValue={currentValue}
              value={currentValue}
              type="number"
              onChange={event => setSelectedPicklistItems(gtin, event.target.value)}
              InputProps={{
                readOnly: false,
                inputMode: 'numeric',
                style: {
                  width: '70px',
                },
                min: 0,
              }}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
              onKeyDown={(event) => {
                if (event?.code === 'ArrowDown' || event?.code === 'ArrowUp') {
                  event.preventDefault();
                }
              }}
              onInput={(e) => {
                const numericValue = parseFloat(e.target.value);
                // Check if the numeric value exceeds the max value (100)
                if (!Number.isNaN(numericValue) && numericValue > parseInt(soh, 10) && !soh?.includes('/')) {
                  e.target.value = parseInt(soh, 10);
                  currentValue = parseInt(soh, 10);
                } else {
                  const sohArray = soh.split('/');
                  const fullStoreSOH = sohArray?.map(Number).reduce((a, b) => a + b, 0);
                  if (!Number.isNaN(numericValue) && numericValue > parseInt(fullStoreSOH, 10)) {
                    e.target.value = parseInt(fullStoreSOH, 10);
                    currentValue = parseInt(fullStoreSOH, 10);
                  }
                }
              }}
              size="small"
            />
          );
        },
      },
    }];
    const rows = sizes?.map(_ => ({
      ..._,
      gtin: _?.gtin || '-',
      sizeName: _?.label || '-',
      sohQuantity: getStockOnHand(_?.quantity, isRfidSelected, isOffsiteEnabled),
      dotcomAvailability: _?.available ? 'YES' : 'NO',
      inboundUnits: _?.inboundUnits || '-',
      quantity: '0',
    }));

    return (
      <MUIDataTable
        data={rows}
        columns={columns}
        options={{
          selectableRows: 'none',
          rowsPerPage: isIOS ? 4 : 8,
          rowsPerPageOptions: [],
          filter: false,
          print: false,
          download: false,
          search: false,
          viewColumns: true,
          elevation: 0,
          sortOrder: {
            name: 'sizeName',
            direction: 'asc',
          },
          customSort: (data, dataIndex, rowIndex) => {
            if (dataIndex === 1) {
               
              return data && sortSizes(data, rowIndex, dataIndex);
            }
            if (dataIndex === 3) {
               
              return data && sortByStockOnHand(data, rowIndex, dataIndex, isRfidSelected);
            }
            return defaultSort(data, dataIndex, rowIndex);
          },
        }}
        className="MUIDataTable"
        data-testid="detailsGtinTable"
      />
    );
  }, [
    sohTypeSelected,
    isOffsiteEnabled,
    getMessage,
    isIOS,
    startTutorial,
    product,
    loading,
    isCreatingPicklist,
    picklistItems,
    picklistIndex,
    index,
  ]);

  const memoizedProductDetails = useMemo(() => {
    const {
      labelName, division, retailCategory, genderAge, content, prices,
    } = product || {};

    if (error || (!product && !loading)) {
      return <div data-testid="productDetail-noResult" className="productDetail-noResult"><ShoeboxFragment label={getMessage('noResults')} /></div>;
    }
    if ((loading && (!product && !startTutorial)) || loading) {
      return <div className="productDetail-loading"><LoadingIndicator width="100px" height="100px" /></div>;
    }

    if (startTutorial && isbackClicked && tabValue === 1) {
      setTabValue(0);
      setBackWasClicked(false); // for tutorial
    }

    setPrice(getFormattedPrice(prices?.currentPrice, locale, currencyCode, startTutorial));

    const nav = (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <ChevronButton
          type="button"
          shape="circle"
          onClick={() => setPicklistIndex(s => s - 1)}
          disabled={index <= 1}
          data-testid="scan-details-back-button"
          extraClasses="scan-details-back-button"
          chevron="left"
          darkMode
        />
        <Button
          buttonText={`${picklistIndex === 0 ? 1 : index} / ${totalStyles} ${index === totalStyles ? 'SAVE' : ''}`}
          dataTestId="picklist-save-button"
          disabled={index !== totalStyles}
          className={classNames('sim-button-darkMode-hoverIgnore', { 'sim-button-darkMode-hoverIgnore-disabled': index !== totalStyles })}
          onClick={index === totalStyles ? () => {
            setIsOpen(false);
            setFormOpen(true);
          } : undefined}
        />
        <ChevronButton
          type="button"
          shape="circle"
          onClick={() => setPicklistIndex(s => s + 1)}
          disabled={index === totalStyles}
          data-testid="scan-details-next-button"
          extraClasses="scan-details-next-button"
          chevron="right"
          darkMode
        />
      </div>
    );

    return (
      <DialogContent className={`productDetails flex-column ${isIOS ? 'iPad' : ''}`} data-testid="productDetails-content">
        <Grid container alignItems="flex-start">
          <Grid item xs>
            <Grid container direction="column" className="details">
              <Typography variant="h5">
                {labelName}
              </Typography>
              <Typography gutterBottom variant="subtitle2">
                {content?.colorDescription}
              </Typography>
              <Grid item style={{ marginTop: 10 }}>
                <Paper square>
                  <Tabs
                    value={tabValue}
                    onChange={(_, val) => setTabValue(val)}
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { backgroundColor: '#68d385' } }}
                  >
                    <Tab
                      sx={{ '&.Mui-selected': { color: '#666' } }}
                      icon={<DescriptionIcon />}
                      label={getMessage('details')}
                      data-testid="productDetailDetails"
                    />
                    <Tab
                      sx={{ '&.Mui-selected': { color: '#666' } }}
                      icon={<InfoIcon />}
                      label={getMessage('productInfo')}
                      data-testid="productDetailsInfo"
                    />
                  </Tabs>
                  <SwipeableViews
                    axis="x"
                    index={tabValue}
                    onChangeIndex={setTabValue}
                    style={{ marginTop: '30px' }}
                  >
                    <Typography component="div" className="flex-row" style={{ padding: '0 24px' }}>
                      <Grid container direction="row" alignItems="flex-start">
                        <ProductInformation
                          rfidEnabled={rfidEnabled}
                          sohTypeSelected={sohTypeSelected}
                          product={product}
                          productPrice={productPrice}
                          locale={locale}
                          currencyCode={currencyCode}
                          startTutorial={startTutorial}
                          setSohTypeSelected={setSohTypeSelected}
                          getMessage={getMessage}
                        />
                        <Grid item xs={8}>
                          {memoizedGtinTable}
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography component="div" className="flex-row" style={{ padding: '0 24px' }}>
                      <Grid container direction="row" alignItems="flex-start">
                        <div style={{ margin: 'auto', display: 'flex', alignItems: 'center' }}>
                          <AdditionalProductDetails
                            getMessage={getMessage}
                            genderAge={genderAge}
                            retailDivision={division}
                            retailCategory={retailCategory}
                          />
                        </div>
                        <Grid item xs={8}>
                          <div
                             
                            dangerouslySetInnerHTML={
                              {
                                __html: content?.description
                                || getMessage('noDescription'),
                              }
                            }
                            style={{ maxHeight: 450, overflowY: 'auto', paddingRight: 10 }}
                            className="productDescription"
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  </SwipeableViews>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isCreatingPicklist && nav}
      </DialogContent>
    );
  }, [
    memoizedGtinTable,
    tabValue,
    sohTypeSelected,
    rfidEnabled,
    getMessage,
    isIOS,
    startTutorial,
    isbackClicked,
    product,
    loading,
    error,
    locale,
    currencyCode,
    productPrice,
    picklistIndex,
    totalStyles,
    index,
  ]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={loading ? theme(loadingStyles) : theme()}>
        <div data-testid="productCard">
          <Card className="productCard" elevation={4}>
            {isModal}
            {memoizedProductDetails}
          </Card>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ProductDetailCard.propTypes = {
  product: object,
  isModal: node,
  optionalColumns: array,
  isUnitCountEnabled: bool,
  picklistIndex: number,
  setPicklistIndex: func,
  selectedStyles: array,
  isCreatingPicklist: bool,
  setSelectedPicklistItems: func,
  picklistItems: array,
  setIsOpen: func,
  setFormOpen: func,
};

ProductDetailCard.defaultProps = {
  product: {},
  isModal: null,
  optionalColumns: [],
  isUnitCountEnabled: false,
  picklistIndex: 1,
  setPicklistIndex: () => {},
  selectedStyles: [],
  isCreatingPicklist: false,
  setSelectedPicklistItems: () => {},
  picklistItems: [],
  setIsOpen: () => {},
  setFormOpen: () => {},
};

export default ProductDetailCard;
