 
 
import React from 'react';
import { func, object, string } from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';

const EstimatedVariancesWidget = ({ getMessage, variances, currencySymbol }) => (
  <Grid container data-testid="estimated-variances-widget-container" direction="column" style={{ padding: '10px', margin: '20px 0' }}>
    <Grid container justifyContent="space-evenly">
      <Typography variant="h5"><strong>{getMessage('missing')}</strong></Typography>
      <Typography variant="h5"><strong>{getMessage('extra')}</strong></Typography>
    </Grid>
    <Box className="widget" style={{ padding: '10px', margin: '0 20px 30px' }}>
      <Grid container justifyContent="space-evenly">
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-missing-value">
              {currencySymbol}
              {variances?.missing?.value || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-missing-units">{variances?.missing?.units || '-'}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderLeft: 1, borderColor: 'white', height: 'auto' }} />
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-extra-value">
              {currencySymbol}
              {variances?.extra?.value || '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-extra-units">{variances?.extra?.units || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    <Box component="p" sx={{ color: '#5f5f5f' }}>
      <Typography>{getMessage('estimatedVarianceNote')}</Typography>
    </Box>
  </Grid>
);

EstimatedVariancesWidget.propTypes = {
  getMessage: func.isRequired,
  variances: object,
  currencySymbol: string,
};

EstimatedVariancesWidget.defaultProps = {
  variances: null,
  currencySymbol: null,
};

export default EstimatedVariancesWidget;
