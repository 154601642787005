import React, { useContext } from 'react';
import {
  string, any, shape,
} from 'prop-types';
import CryptoJS from 'crypto-js';
import { LoadingIndicator } from '../../assets/Svgs/index';

import { TranslationsContext } from '../../context/Translations.provider';

import './ReceivingLogSummaryTable.css';

const quickHash = data => CryptoJS.SHA256(JSON.stringify(data)).toString(CryptoJS.enc.Base64);

const dataToJSX = ({ data }) => data?.map(subArray => (
  <div className="summary-table-row" key={quickHash(subArray)}>
    {subArray.map(elem => (
      <div className="object-group" key={`${quickHash(elem)}-${Math.random()}`}>
        <div className={`${elem.key.toLowerCase()}-key`}>
          { (elem.key.toLowerCase() !== 'cartons' && elem.key.toLowerCase() !== 'items') ? `${elem.key}:` : elem.key }
        </div>
        {/* if value is a negative number, the 'negative' class */}
        <div className={`value ${elem.key === 'Missing' ? 'negative' : ''}`}>
          {elem.value}
        </div>
      </div>
    ))}
  </div>
));

const ReceivingLogSummaryTable = ({ data }) => {
  const { getMessage } = useContext(TranslationsContext);

  const summaryDataArray = [
    [{ key: getMessage('cartons'), value: '' },
      { key: getMessage('items'), value: '' }],
    [{ key: getMessage('expected'), value: data?.cartonsExpected },
      { key: getMessage('expected'), value: data?.itemsExpected }],
    [{ key: getMessage('received'), value: data?.cartonsReceived },
      { key: getMessage('received'), value: data?.itemsReceived }],
    [{ key: getMessage('missing'), value: data?.cartonsMissing },
      { key: getMessage('missing'), value: data?.itemsMissing }],
  ];

  return (
    <div className="summary-table" data-testid="summary-table">
      {
        dataToJSX({ data: summaryDataArray }) || <LoadingIndicator />
      }
    </div>
  );
};

ReceivingLogSummaryTable.propTypes = {
  data: shape({
    key: string,
    value: any,
  }),
};

ReceivingLogSummaryTable.defaultProps = {
  data: {},
};

export default ReceivingLogSummaryTable;
