export const itemInquiryStepArray = [
  {
    content: 'itemInquiryS1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="styleInput"]',
    title: 'itemInquiryS1Title',
  },
  {
    content: 'itemInquiryS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="inquiryGoButton"]',
    title: 'itemInquiryS2Title',
  },
  {
    content: 'itemInquiryS3',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'left',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="productCard-summary-rfid-header"]',
    title: 'itemInquiryS3Title',
    largeScreen: true, // only large screens
  },
  {
    content: 'itemInquiryS4',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'left',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="View Columns-iconButton"]',
    title: 'itemInquiryS4Title',
  },
  {
    content: 'itemInquiryS5',
    disableBeacon: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    placement: 'left',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="toggle-SOH"]',
    title: 'itemInquiryS5Title',
    config: true, // only for rfid doors
  },
  {
    content: 'itemInquiryS6',
    disableBeacon: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="productDetailsInfo"]',
    title: 'itemInquiryS6Title',
  },
];

 
export const itemInquirySteps = (smallScreenSize, rfidEnabled, translatedSteps) => {
  const lgScreenStep = {
    content: 'itemInquiryS3',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'left',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="productCard-summary-rfid-header"]',
    title: 'itemInquiryS3Title',
    largeScreen: true, // only large screens
  };

  let newSteps = translatedSteps;

  const stepExists = newSteps?.some(value => value?.largeScreen === true); // only for large screens

  if (!rfidEnabled) {
    newSteps = newSteps?.filter(value => value.config !== true);
  }

  if ((smallScreenSize || window.innerWidth <= 959) && stepExists) {
    newSteps = newSteps?.filter(value => value?.largeScreen !== true);
  }

  if (!smallScreenSize || window.innerWidth >= 959) {
    if (!stepExists) {
      newSteps.splice(2, 0, lgScreenStep);
    }
  }
  return newSteps;
};
