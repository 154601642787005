/**
 * Will replace a give jsonObject's property with a new value
 * @param {object} source -jsonObject
 * @param {string} propertyName - property to be updated
 * @param {string} newValue - new value
 */
export const replace = (source, propertyName, newValue) => {
  const replacer = (key, value) => {
    if (key.toLowerCase() === propertyName) {
      return newValue;
    }
    return value;
  };

  return JSON.stringify(source, replacer);
};

/**
 * Will parse the give value to jsonl object or a string
 * @param {object} value - value to parse
 */
export const parse = (value) => {
  try {
    const json = JSON.parse(value);
    return (typeof json === 'object') ? json : JSON.stringify(value);
  } catch {
    return value;
  }
};

const defaultReplacements = [
  { key: 'authorization', value: 'XXXXXX' },
];

/**
 * Used to return a json object
 * and replace the following properties with a new value. Properties: 'Authorization'.
 * @param {object} value - value to parse
 */
const jsonParse = (value, replacements) => {
  const properties = replacements ?? defaultReplacements;
  const update = (candidate, key, value) => {
    const jsonObj = parse(candidate);

    if (typeof jsonObj === 'object') {
      const jsonStr = replace(jsonObj, key, value);
      return parse(jsonStr);
    }

    return candidate;
  };

  let result = value;
  properties.forEach(prop => {
    result = update(result, prop.key, prop.value);
  });

  return result;
};

export default jsonParse;
