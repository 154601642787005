 
 
import React, {
  useContext, useEffect, useState, useMemo,
} from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { bool } from 'prop-types';
import { LoadingIndicator } from '../../assets/Svgs/index';

import { logErrorAction, createNewRelicInteraction } from '../../utils/newRelicPageActions';
import Error from '../../components/alerts/Error';
import Button from '../../components/Button';
import { pageCodes, applicationErrors } from '../../constants/ErrorCodes';
import { version } from '../../environment';
import { routerUrls, baseUrl, endpoints } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

import { TranslationsContext } from '../../context/Translations.provider';
import { StoreViewsContext } from '../../context/StoreViews.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { LoginContext, getAccessToken } from '../../context/Login.provider';

/**
 * parses the OIDC token to display in error for support
 * @param {string} token - the accessToken passed to the application by the wrapper
 */
export const getTokenInfo = (token) => {
  const hideFields = ['prn', 'nike_athleteLogin', 'family_name', 'given_name'];
  let decoded = '';

  try {
    const { value } = JSON.parse(token ?? '');

    if (value?.access_token) {
      decoded = jwtDecode(value.access_token);
      hideFields.forEach(field => { decoded[field] = 'XXXX'; });
      decoded.roles = value?.roles ?? '';
      decoded.prn = value?.prn ?? '';
    }
  } catch (e) {
    decoded = e;
  }
  return decoded;
};

/**
 * Page to be shown after the nsp login page, but before the access token has been passed to the application
 * @param {boolean} hasError - boolean determining if there is an error
 */
const WrapperLoginPage = ({ hasError }) => {
  const history = useHistory();
  const interaction = createNewRelicInteraction('WrapperLoginPage');

  const {
    logout, storeId, isLoggedIn, storeViewsData, setCountry, setStoreNumber, addNewRelicAttributes,
  } = useContext(LoginContext);
  const { isInitialCall } = useContext(SimWebContext);
  const { getMessage, isReady, setLanguage } = useContext(TranslationsContext);
  const { storeInfo, isStoreInfoLoaded } = useContext(StoreViewsContext);

  const [showError, setShowError] = useState(hasError);
  const [additionalInfo, setAdditionalInfo] = useState({ storeId });
  const displayError = useMemo(() => (!isLoggedIn || !getAccessToken()) && showError, [isLoggedIn, getAccessToken(), showError]);

  // request accessToken from IOS wrapper application, only want to call once per component instantiation
  useEffect(() => {
    interaction.setAttribute('sim-web-wrapper', { action: 'getAccessToken' });
    window.webkit?.messageHandlers?.getAccessData?.postMessage('');
    return () => interaction.end();
  }, []);

  useEffect(() => {
    const getStoreInfo = async () => {
      const url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}/${storeId}`;
      const headers = { 'nike-api-caller-id': 'com.nike:retail.sim' };
      const store = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
      if (store) {
        const country = store?.address?.country;
        const storeNumber = store?.storeNumber;
        setCountry(country);
        setStoreNumber(storeNumber);

        // updating country & storeNumber for NSP doors where prn is an email
        addNewRelicAttributes(null, null, country, storeNumber, `${country}-${storeNumber}`);
      }
      return store;
    };
    if (storeId) {
      getStoreInfo();
    }
  }, [storeId]);

  useEffect(() => {
    if (isInitialCall === false) {
      const accessToken = sessionStorage.getItem('accessToken');

      if (!storeId || !accessToken) {
        const error = { message: 'StoreId or access token is null.' };
        const additionalInfo = { error, operationName: 'validate storeId and access token', data: { storeId, hasAccessToken: accessToken ? 'true' : 'false', simwebVersion: version } };
        setAdditionalInfo(additionalInfo);
        setShowError(true);
        return;
      }

      if (!isStoreInfoLoaded) {
        const token = getTokenInfo(sessionStorage.getItem('login'));
        const error = { message: 'StoreViewsProvider-storeInfoData is not getting loaded.' };
        const additionalInfo = { error, operationName: 'getStoreInfoIdData', data: { storeId, token, simwebVersion: version } };
        setAdditionalInfo(additionalInfo);
        setShowError(true);
        return;
      }

      storeViewsData(storeInfo);
    }
  }, [isStoreInfoLoaded, storeId, storeInfo?.storeNumber, isInitialCall]);

  useEffect(() => {
    if (isLoggedIn && !showError) {
      const variables = { country: storeInfo?.address?.country, storeNumber: storeInfo?.storeNumber };
      interaction.setAttribute('sim-web-wrapper', { action: 'login', result: 'success', variables });
    }

    if (displayError) {
      interaction.setAttribute('sim-web-wrapper', { eaction: 'login', result: 'failed', error: additionalInfo });
      logErrorAction('sim-web-wrapper-login-failure', additionalInfo.error, additionalInfo.operationName, additionalInfo.data);
    }
  }, [displayError, additionalInfo, isLoggedIn, showError]);

  function handleLogout(event) {
    event.preventDefault();
    logout();
    setLanguage('en-US');
  }

  const logOutButton = (
    <div style={{ textAlign: 'right', paddingRight: '25px' }} data-testid="ios-wrapper-logout-button">
      <Button buttonText={getMessage('signout')} onClick={handleLogout} />
    </div>
  );

  if (isLoggedIn && !showError) {
    const variables = { country: storeInfo?.address?.country, storeNumber: storeInfo?.storeNumber };
    interaction.setAttribute('sim-web-wrapper', { action: 'login', result: 'success', variables });
    history.push('/dashboard');
  }

  return (
    <div data-testid="wrapper-login-page">
      { displayError ? logOutButton : null }
      <div className="sim-login-container" data-testid="wrapper-login-page-header">
        <div className="header">
          <span className="g72-swoosh image-swoosh" />
          <div className="text">
            { isReady ? getMessage('retailWebTitle') : null}
          </div>
        </div>
        { displayError
          ? <Error pageCode={pageCodes.APPLICATION} apiName="Dashbaord API" errorObject={applicationErrors.iOSSrapperLoginFail} additionalInfo={additionalInfo} />
          : <LoadingIndicator extraClass="sim-login-loading-show" />}
      </div>
    </div>
  );
};

WrapperLoginPage.defaultProps = {
  hasError: false,
};

WrapperLoginPage.propTypes = {
  hasError: bool,
};

export default WrapperLoginPage;
