 
import { isEmpty } from 'lodash';
import { getLastUpdatedDate } from '../asnReport/asnTracking.utils';

export const getCache = (storeId, country) => {
  const key = `stockroomOrganization-${storeId}-${country}`;

  try {
    const cachedData = sessionStorage.getItem(key);
    return cachedData ? JSON.parse(cachedData) : null;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const setCache = (storeId, country, locale, styleColors) => {
  const key = `stockroomOrganization-${storeId}-${country}`;

  try {
    const lastUpdateDate = getLastUpdatedDate(locale);
    if (!isEmpty(styleColors)) sessionStorage.setItem(key, JSON.stringify({ lastUpdateDate, styleColors }));
  } catch (error) {
    console.error(error);
    sessionStorage.removeItem(key);
  }
};

export const isCacheExpired = (storeId, country) => {
  try {
    const cachedData = getCache(storeId, country);
    if (!cachedData) return true;

    const lastUpdateDate = new Date(Date.parse(cachedData.lastUpdateDate));
    const differenceInMs = new Date().getTime() - lastUpdateDate.getTime();
    if (Number.isNaN(differenceInMs)) throw new Error('Invalid lastUpdatedDate');
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
    return differenceInMinutes > 10;
  } catch (error) {
    console.error(error);
  }

  return true;
};
