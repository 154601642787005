 
import React from 'react';
import { camelCase } from 'lodash';
import ChallanPrintToolBar from './ChallanPrintToolBar';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

/**
 * A function that gets the list of selected row adjustments or transfers ids and opens the print dialog
 * @param {object} selectedRows the selected rows in the table
 * @param {object} displayData the data displayed in the table
 * @param {string} reportType the type of report to be displayed - adjustments or transfers
 * @param {function} getIdList function to get the list of selected
 */
export const getAdjustmentsOrTransferIds = (selectedRows, displayData, reportType, getIdList) => {
  const ids = [];
  const indexes = selectedRows?.data?.map((row) => row.dataIndex);

  if (indexes.length > 0) {
    if (reportType === 'transfers') {
      indexes.map(index => {
        ids.push(displayData?.[index]?.data?.[12]);
      });
    } else {
      indexes.map(index => {
        ids.push(JSON.parse(displayData?.[index]?.data?.[5]).adjustmentId);
      });
    }
  }

  getIdList(ids);
};

/**
 * A function that returns table options for the Adjustments or Transfer table
 * @param {string} reportType the type of report to be displayed - adjustments or transfers
 * @param {function} getMessage translation helper function
 * @param {function} getIdList function to get the list of selected row adjustments/transfers ids
 * @param {function} setPrintDialogOpen function to open the print dialog
 */
export const getTableOptions = (reportType, getMessage, getIdList, setPrintDialogOpen) => ({
  selectableRows: 'multiple',
  rowsPerPage: 100,
  rowsPerPageOptions: [15, 25, 50, 100, 1000],
  filter: true,
  download: false,
  print: false,
  textLabels: {
    body: {
      noMatch: getMessage('noData'),
    },
  },
  fixedHeader: true,
  customToolbarSelect: (selectedRows, displayData) => (
    <ChallanPrintToolBar
      printChallan={() => {
        setPrintDialogOpen(true);
        getAdjustmentsOrTransferIds(selectedRows, displayData, reportType, getIdList);
      }}
    />
  ),
});

/**
 * A function that returns the columns for the Transfers table
 * @param {boolean} displayGovernmentNumber a boolean to display the government number column
 * @param {boolean} displayReturnAuthorizationNumber a boolean to display the return authorization number column
 * @param {function} getMessage translation helper function
 */
export const getTransfersTableColumns = (displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage) => [
  {
    name: 'creationDate',
    label: getMessage('date'),
  },
  {
    name: 'targetType',
    label: getMessage('transfersType'),
  },
  {
    name: 'targetLocation',
    label: getMessage('shipTo'),
  },
  {
    name: 'purchaseOrderNumber',
    label: getMessage('poNumber'),
  },
  {
    name: 'totalCartons',
    label: getMessage('cartons'),
  },
  {
    name: 'totalItems',
    label: getMessage('units'),
  },
  {
    name: 'totalPrice',
    label: getMessage('totalPrice'),
  },
  {
    name: 'status',
    label: getMessage('status'),
  },
  {
    name: 'cartons',
    label: 'cartons',
    options: {
      display: 'excluded',
    },
  },
  {
    name: 'governmentNumbers',
    label: getMessage('governmentNumber'),
    options: {
      display: !!displayGovernmentNumber || 'excluded',
    },
  },
  {
    name: 'returnAuthorizationNumber',
    label: getMessage('raNumber'),
    options: {
      display: !!displayReturnAuthorizationNumber || 'excluded',
    },
  },
  {
    name: 'opId',
    label: getMessage('opId'),
  },
  {
    name: 'transferId',
    label: 'transferId',
    options: {
      display: 'excluded',
    },
  },
  // this used in print and download because governmentNumber could be a link
  {
    name: 'governmentNumbersData',
    label: 'governmentNumbersData',
    options: {
      display: 'excluded',
    },
  },
];

/**
 * A function that returns the columns for the Adjustments table
 * @param {object} SimDateTime date time helper function
 * @param {function} getMessage translation helper function
 */
export const getAdjustmentsTableColumns = (SimDateTime, getMessage) => [
  {
    name: 'creationDate',
    label: getMessage('date'),
    options: {
      customBodyRender: (value) => (
        <>{SimDateTime.toLocaleString(value, DATE_SHORT_WITH_APPENDED_ZEROS)}</>
      ),
    },
  },
  {
    name: 'creationDate',
    label: getMessage('time'),
    options: {
      customBodyRender: (value) => (
        <>{SimDateTime.toLocaleString(value, SimDateTime.TIME_SIMPLE)}</>
      ),
    },
  },
  {
    name: 'type',
    label: getMessage('type'),
    options: {
      customBodyRender: (value) => (
        <>{getMessage(camelCase(value?.toLowerCase()))?.toUpperCase() }</>
      ),
    },
  },
  {
    name: 'totalUnits',
    label: getMessage('units'),
    options: {
      setCellHeaderProps: () => ({
        'data-testid': 'columnStep',
      }),
    },
  },
  { name: 'opId', label: getMessage('opId') },
  { name: 'searchHelper', options: { display: 'excluded' } },
];
