 
import { createStep } from '../tutorial.utils';

export const steps = [
  {
    stepId: 1,
    title: 'Filter Size Compliance By Bin',
    content: 'Filter by Bin or no Bin to refiner your search results',
    target: '[data-testid="toggle-noBin"]',
    spotlightClicks: false,
    spotlightPadding: 10,
  },
  {
    stepId: 2,
    title: 'Size Compliance Facets',
    content: 'Filter data by clicking a facet checkbox to change results in the data table. Select/deselect multiple items in a facet group section for a flexible search result',
    target: '[data-testid="facet-division.Footwear"] > div:nth-child(1)',
    placement: 'top',
    spotlightPadding: 10,
  },
  {
    stepId: 3,
    title: 'Quick Search Tool',
    content: 'Narrow down your search by using the search box to modify to filter your specific item in the report. Hover over the info icon for additional details on how to customize your search. Key in your search details and click the magnifying glass to complete the search',
    target: '[data-testid="size-compliance-dynamic-search"]',
    spotlightPadding: 10,
  },
  {
    stepId: 4,
    title: 'inboundVisibilityS7Title',
    content: 'inboundVisibilityS7',
    target: '[data-testid="size-compliance-clear-facets-button"]',
    disableOverlayClose: true,
    spotlightPadding: 10,
  },
  {
    stepId: 5,
    title: 'inboundVisibilityS8Title',
    content: 'inboundVisibilityS8',
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    placement: 'top',
  },
  {
    stepId: 6,
    title: 'stockRoomOrgS2Title',
    content: 'inboundVisibilityS9',
    target: '[data-testid="columnStep"]',
    placement: 'top',
  },
  {
    stepId: 7,
    title: 'inboundVisibilityS10Title',
    content: 'inboundVisibilityS10',
    target: 'thead tr th', // select all checkboxes
    disableOverlayClose: true,
  },
  {
    stepId: 8,
    title: 'Selecting a Single Row Item',
    content: 'Click on a single checkbox for hand selecting row items from the list below.',
    target: '.MuiTableBody-root > tr',
    disableOverlayClose: true,
  },
  {
    stepId: 9,
    title: 'Create a Picklist',
    content: 'Click on the list icon to create a picklist for all the selected items in the size compliance data table.',
    target: '[data-testid="size-compliance-picklist-button"]',
    placement: 'top',
  },
  {
    stepId: 10,
    title: 'Name the Picklist',
    content: 'Enter the name of the picklist.',
    target: '[data-testid="size-compliance-picklist-form-input"]',
    disableOverlayClose: true,
    spotlightClicks: false,
    addZindex: true,
  },
  {
    stepId: 11,
    title: 'Create or Cancel the Picklist',
    content: 'Click the save button to create or cancel to discard.',
    target: '[data-testid="size-compliance-picklist-form-cancel"]',
    disableOverlayClose: true,
    spotlightClicks: true,
    addZindex: true,
  },
];

export const sizeComplianceStepsArray = steps.map(step => createStep(step));
export const sizeComplianceSteps = (isSizeComplianceRefillsEnabled, translatedSteps) => {
  const newSteps = translatedSteps;

  if (!isSizeComplianceRefillsEnabled) {
    newSteps.splice(6, 4);
  }

  return newSteps;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 */
export const setSizeComplianceTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked,
) => {
  const mainCheckbox = document.querySelectorAll('table thead tr input')[0];
  const listOfCheckboxes = document.querySelectorAll('table td input');

  if (startTutorial) {
    if (stepIndex === 5 && isbackClicked && mainCheckbox?.checked) {
      mainCheckbox?.click(); // deselect all
    }
    if (stepIndex === 6) {
      if (isbackClicked) {
        mainCheckbox?.click(); // deselect all - make sure no single checkboxes are clicked from the previous step
        setTimeout(() => { mainCheckbox?.click(); }, 400); // select all once again
      } else {
        mainCheckbox?.click(); // select all
      }
    }
    if (stepIndex === 7) {
      // handle unqiued case where user is clicking in the highlighted section and changing the default behavior
      if (!mainCheckbox?.checked) {
        mainCheckbox?.click(); // select all
        listOfCheckboxes?.[0].click(); // select single checkbox
      }
      mainCheckbox?.click(); // deselect all
      setTimeout(() => { listOfCheckboxes?.[0]?.click(); }, 600);// select single checkbox
    }
    // handle unqiued case where user is clicking in the highlighted section and changing the default behavior
     
    if ((stepIndex === 8 && isbackClicked && !listOfCheckboxes?.[0]?.checked) || (stepIndex === 8 && !listOfCheckboxes?.[0]?.checked)) {
      const cancelButton = document.querySelector('.cancel-button');
      cancelButton?.click();
      listOfCheckboxes?.[0].click(); // select single checkbox
    }

    if (stepIndex === 9) {
      const picklist = document.querySelector('[data-testid="size-compliance-picklist-button"]');
      picklist?.click();
    }

    if (stepIndex === 10) {
      const picklistName = document.querySelector('[data-testid="size-compliance-picklist-form-input"]'); //
      picklistName?.focus();
    }

    if (stepIndex === 11) {
      const cancelPicklistButton = document.querySelector('[data-testid="size-compliance-picklist-form-cancel"]'); //
      cancelPicklistButton?.click();
    }
  }

  if (restartTutorial) {
    if (mainCheckbox?.checked) mainCheckbox.click();
    if (listOfCheckboxes?.[0]?.checked && !mainCheckbox?.checked) listOfCheckboxes?.[0]?.click();
  }
};
