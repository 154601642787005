 
 
/* eslint-disable react/prop-types */
 
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      console.error('The tutorial failed to reload correct. Please refresh the page.');
      // window.location.reload();
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
