 
import React, { useState, useMemo, useContext } from 'react';
import {
  array, bool, string,
} from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { chunk, isEmpty } from 'lodash';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import classNames from 'classnames';
import {
  Close,
  LoadingIndicator,
  Chevron,
} from '../../../assets/Svgs/index';
import { changeMockDataScanTypeByConfigs } from './scanComplianceWidget.utils';

import './ScanComplianceWidget.css';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../tutorial/Tutorial.provider';

import mockedScanComplianceData from '../../tutorial/mockTutorialData/dashboard/scan-compliance-data.json';

/**
  * Creates a widget with data visuals for the Scan Compliance
  * @param {String} customClass additional string to cusomize styles
  * @param {Object} data calculated details to display in the widget
  * @param {Boolean} loading loading state
  * @return {Node} returns a new widget component with the data
*/
const ScanComplianceWidget = ({
  locale,
  customClass,
  data,
  loading,
}) => {
  const history = useHistory();
  const {
    storeConfig, getMessage, isOffsiteEnabled, isOffsiteInactive, SimDateTime,
  } = useContext(SimWebContext);
  const { activateTutorials } = useContext(tutorialContext);

  const cycleCountEnabled = storeConfig?.cycleCountEnabled?.value;
  const virtualWallEnabled = storeConfig?.virtualWallEnabled?.value;
  const scanData = activateTutorials ? changeMockDataScanTypeByConfigs(
    mockedScanComplianceData, cycleCountEnabled, virtualWallEnabled, isOffsiteEnabled,
  ) : data;
  const weeklyScans = chunk(scanData, 7);

  const [scanIndex, setScanIndex] = useState(null);

  const scanTypesClassNameMappings = {
    FULL_STORE_SCAN: 'scanComplianceWidget_fullStoreScan',
    SALES_FLOOR_SCAN: 'scanComplianceWidget_salesFloorScan',
    FILTERED_FULL_STORE_SCAN: 'scanComplianceWidget_cycleCountScan',
    OFFSITE_SCAN: 'scanComplianceWidget_offsiteScan',
  };

  const endDate = SimDateTime.endOfDay(SimDateTime.now()).toISO();

  if (scanData && weeklyScans.length && scanIndex === null) {
    setScanIndex(weeklyScans.length - 1);
  }

  const noWeeklyScanData = (
    <div className="scanComplianceWidget_date-error" data-testid="errorContainer_emptyData">
      <ErrorOutline color="error" fontSize="large" />
      <br />
      <p>{getMessage('noResults')}</p>
    </div>
  );

  const scanComplianceData = useMemo(() => {
    const handleNextWeek = () => {
      setScanIndex(scanIndex + 1);
    };

    const handlePreviousWeek = () => {
      setScanIndex(scanIndex - 1);
    };

    const onDateClicked = (id) => {
      history.push(`/scan/${id}/${endDate}`);
      return false;
    };

    if (loading) {
      return (<div className="scanComplianceWidget_card-loading"><LoadingIndicator width="45px" height="45px" fill="#FFFFFF" /></div>);
    }

    if (!scanData || scanData === undefined || scanData === null) {
      return (
        <div className="scanComplianceWidget_error" data-testid="errorContainer">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }
    return (
      <div className="scanComplianceWidget_card-details" data-testid="scanComplianceDataContainer">
        <div className="scan-accuracy-details">
          <div className="weekly-scanAccuracy">
            <div className="widget-title">
              <button
                className="scanComplianceWidget-buttonOverride"
                data-testid="scanComplianceWidget-previous-week"
                type="button"
                onClick={() => handlePreviousWeek()}
                disabled={scanIndex <= 0 || isEmpty(scanData)}
                aria-label="Scan Compliance Back"
              >
                <Chevron fill={scanIndex <= 0 || isEmpty(scanData) ? '#979698' : 'white'} />
              </button>
              <p>{getMessage('weeklyScans')}</p>
              <button
                className="scanComplianceWidget-buttonOverride"
                data-testid="scanComplianceWidget-next-week"
                type="button"
                onClick={() => handleNextWeek()}
                disabled={scanIndex >= (weeklyScans.length - 1) || isEmpty(scanData)}
                aria-label="Scan Compliance Next"
              >
                <Chevron
                  position="right"
                  fill={scanIndex >= (weeklyScans.length - 1) || isEmpty(scanData) ? '#979698' : 'white'}
                />
              </button>
            </div>
            <hr />
            <div className="scanComplianceWidget_weekly-scan-dates">
              {scanIndex !== null && scanIndex >= 0 ? weeklyScans?.[scanIndex]?.map(date => {
                const {
                  scanStatus, scanType, accuracyTotal, totalScansCompleted, value,
                } = { ...date };
                const id = scanStatus?.id;

                return (
                  <div
                    className={`scanComplianceWidget_weekly-scan-single-dates${id ? '-clickable' : ''}`}
                    data-testid={`scanComplianceWidget_weekly-scan-single-dates${id ? `-clickable-${id}` : ''}`}
                    key={value}
                  >
                    <p>{date.dayOfWeek}</p>
                    {(id && !activateTutorials) ? (
                      <Link
                        to={`/scan/${id}/${endDate}`}
                        style={{
                          textDecoration: 'none', color: 'inherit',
                        }}
                      >
                        <p className="scan-single-dates-values" onClick={() => onDateClicked(scanStatus.id)}>{new Date(value).toLocaleDateString(locale, { month: 'short', day: 'numeric' })}</p>
                      </Link>
                    )
                      : <p className="scan-single-dates-values">{new Date(date.value).toLocaleDateString(locale, { month: 'short', day: 'numeric' })}</p>}
                    {scanStatus?.scanDuration && (
                      <p className="scan-single-dates-duration">
                        {`${scanStatus.scanDuration} ${getMessage('mins')}`}
                      </p>
                    )}
                    <p
                      className={scanTypesClassNameMappings[scanType] || ''}
                      data-testid={`scan-single-dates-accuracy${id ? `-${id}` : ''}`}
                    >
                      {accuracyTotal === '-' ? <Close height="12px" width="12px" fill="#F45119" /> : `${accuracyTotal}%` }
                      {totalScansCompleted > 1 ? '+' : null}
                    </p>
                  </div>
                );
              }) : noWeeklyScanData}
            </div>
            {scanIndex !== null && scanIndex >= 0 ? (
              <div className="scanComplianceWidget_legend" data-testid="scanComplianceWidget_legend">
                <span className="scanComplianceWidget_fullStoreScan_legend" data-testid="scan-compliance-widget-full-store-legend" />
                <p>{getMessage('fullStore')}</p>
                {cycleCountEnabled && (
                  <>
                    <span className="scanComplianceWidget_cycleCount_legend" data-testid="scan-compliance-widget-cycle-count-legend" />
                    <p>{getMessage('cycleCount')}</p>
                  </>
                )}
                {!virtualWallEnabled && (
                  <>
                    <span className="scanComplianceWidget_salesFloorScan_legend" data-testid="scan-compliance-widget-sales-floor-scan-legend" />
                    <p>{getMessage('salesFloor')}</p>
                  </>
                )}
                {isOffsiteEnabled && (
                  <>
                    <span className="scanComplianceWidget_offsiteScan_legend" data-testid="scan-compliance-widget-offsite-legend" />
                    <p>
                      {getMessage('offsite')}
                      {isOffsiteInactive ? ` (${getMessage('inactive')})` : ''}
                    </p>
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
  [
    scanData,
    scanIndex,
    weeklyScans,
    loading,
    noWeeklyScanData,
    isOffsiteEnabled,
    isOffsiteInactive,
    getMessage,
    locale,
  ]);

  return (
    <div className={classNames('scanComplianceWidget_dashboard-card', `${customClass}`, 'borderStyles')} data-testid="scanComplianceWidget">
      <div>
        <p className="scanComplianceWidget_title">{getMessage('scan')}</p>
        { scanComplianceData }
      </div>
    </div>
  );
};

ScanComplianceWidget.propTypes = {
  locale: string,
  customClass: string,
  data: array,
  loading: bool,
};

ScanComplianceWidget.defaultProps = {
  locale: 'en-us',
  customClass: '',
  data: [],
  loading: false,
};

export default ScanComplianceWidget;
