const mockStyleColor = [{
  bins: [],
  content: {
    colorDescription: 'Wolf Grey/Sail/Midnight Fog/Wolf Grey',
    colors: [
      { type: 'SIMPLE', name: 'Grey', hex: '808080' },
      { type: 'PRIMARY', name: 'Wolf Grey', hex: 'A2A5AC' },
      { type: 'SECONDARY', name: 'Sail', hex: 'F2F1E5' },
      { type: 'TERTIARY', name: 'Midnight Fog', hex: '4D4C4C' },
      { type: 'LOGO', name: 'Wolf Grey', hex: 'A2A5AC' },
    ],
    description: '<div class=\'pi-tier3\'><div class=\'pi-pdpmainbody\'><p><b>MAX SNEAKER LOVE.</b></p><br><p>Flashing retro style, the Women&#39;s Nike Air Max Command Premium Shoe provides a classic look in total comfort. Leather in the upper offers plush style, while its rubber Waffle outsole gives you durable multi-surface traction.</p><br><p><b>Benefits</b></p><ul><li>Leather in the upper is durable and comfortable.</li><li>Full-length foam midsole cushions and offers stability.</li><li>Rubber Waffle outsole provides multi-surface traction.</li></ul></div></div>',
  },
  imageSet: {},
  division: 'Footwear',
  genderAge: 'Womens',
  labelName: 'WMNS AIR MAX COMMAND PRM',
  prices: { currency: 'USD', msrp: 110, currentPrice: 39.97 },
  productCode: '718896-005',
  retailCategory: 'Sportswear',
  sizes: [
    {
      available: false,
      gtin: '00886691439592',
      label: '5.5',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '00886691439592',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 0, stockRoomQuantity: 0 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '718896-005',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 0, stockRoomQuantity: 0 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
    {
      available: true,
      gtin: '00886691439382',
      label: '6',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '00886691439382',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 10, stockRoomQuantity: 1 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '837163-001',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 6, stockRoomQuantity: 0 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
    {
      available: true,
      gtin: '000376534940382',
      label: '7',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '000376534940382',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 8, stockRoomQuantity: 1 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '837163-001',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 2, stockRoomQuantity: 3 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
    {
      available: true,
      gtin: '00037653438245323',
      label: '7.5',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '00037653438245323',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 8, stockRoomQuantity: 1 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '837163-001',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 2, stockRoomQuantity: 4 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
    {
      available: true,
      gtin: '00065234824532354',
      label: '8',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '00065234824532354',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 8, stockRoomQuantity: 1 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '837163-001',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 2, stockRoomQuantity: 2 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
    {
      available: true,
      gtin: '0224545579032393',
      label: '8.5',
      quality: 'A_GRADE',
      quantity: {
        fiscalStock: { quantity: 0 },
        gtin: '0224545579032393',
        physicalStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 3, stockRoomQuantity: 5 },
        },
        physicalStockState: 'TRANSITIONED',
        productCode: '837163-001',
        sellableStock: {
          quantity: 0,
          segmentation: { offSiteQuantity: 0, salesFloorQuantity: 10, stockRoomQuantity: 1 },
        },
        sellableStockState: 'NOT_TRANSITIONED',
        storeId: '3A8ABD5D-2C54-4012-9CEE-3B65FF392C2E',
      },
    },
  ],
}];

export default mockStyleColor;
