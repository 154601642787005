 
 
 
 
import React, { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { bool, string } from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ErrorOutline } from '@mui/icons-material';
import LinkWithDisable from '../../LinkWithDisable';
import { LoadingIndicator } from '../../../assets/Svgs';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { AgedOrdersContext } from '../../../containers/agedOrders/AgedOrders.provider';
import { agedOrderValues, journeys } from '../../../containers/agedOrders/agedOrders.constants';
import { useTutorial } from '../../tutorial/useTutorial';

import mockedAgedOrdersData from '../../tutorial/mockTutorialData/dashboard/aged-orders-data.json';

import './AgedOrdersWidget.css';

const AgedOrdersWidget = ({ customClasses, addTitle, isReportWidget }) => {
  const {
    getMessage, storeConfig, SimDateTime, isAdmin, activateTutorials,
  } = useContext(SimWebContext);
  const { data: agedOrders, loading, error } = useContext(AgedOrdersContext);
  const { tutorial } = useTutorial('agedOrders');
  const isAgedOrdersEnabled = isAdmin || (storeConfig?.isAgedOrdersEnabled?.value || false);

  // move these methods to the provider
  const noAgedOrders = (journey) => (
    <div className="agedOrders-widget-success" data-testid={`agedOrders-widget-no${journey.key}Data`}>
      <CheckCircleOutlineIcon fontSize="large" />
      <br />
      <p>{`No '${journey.text }' Aged Orders`}</p>
    </div>
  );

  const summed = (agedOrderData) => {
    const orders = agedOrderData?.orders?.map((order) => ({
      date: SimDateTime.toLocaleString(order?.creationDate, DateTime.DATETIME_FULL),
      orderId: order?.orderId,
      status: order?.fulfillmentStatus,
      timeRange: order?.timeRange,
    }));

    const summed = orders.reduce((result, item) => {
      const { status, timeRange } = item;

      if (!result?.[status]) {
        result[status] = {};
      }

      if (!result?.[status]?.[timeRange]) {
        result[status][timeRange] = 0;
      }
      result[status][timeRange]++;
      return result;
    }, {});

    return Object.keys(summed).map(key => {
      const within7Days = summed?.[key]?.[agedOrderValues.HIGH_PRIORITY.key] ?? '0';
      const within2Days = summed?.[key]?.[agedOrderValues.MEDIUM_PRIORITY.key] ?? '0';
      const within1Day = summed?.[key]?.[agedOrderValues.LOW_PRIORITY.key] ?? '0';
      return `${agedOrderValues?.[key]?.text}: ${within7Days}/${within2Days}/${within1Day}`;
    });
  };

  const getColorCodedValue = (value, isHeader = true) => {
    const values = value.split('/');

    const additionalText = [];
    if (isHeader) {
      additionalText.push({ name: 'Priority', color: '##FFFFFF' });
      additionalText.push({ name: ' ( ', color: '##FFFFFF' });
    }

    additionalText.push({ name: `${values[0]} / `, color: '#d32f2f' });
    additionalText.push({ name: `${values[1]} / `, color: '#FF7F00' });
    additionalText.push({ name: `${values[2]}`, color: '#24CEAA' });

    if (isHeader) additionalText.push({ name: ' )', color: '##FFFFFF' });

    return (
      <div className="agedOrders-label" data-testid="aged-orders-widget">
        {additionalText.map((aggregate, index) => (
          <div
            className="agedOrders-values"
            style={{ color: aggregate.color }}
            key={`${aggregate.color}-${index}`}
          >
            {aggregate.name}
          </div>
        ))}
      </div>
    );
  };

  const getSummary = (journeyTypeData, summedValues, journey) => {
    if (!summedValues) return (noAgedOrders(journey));

    return (
      <table className="agedOrders-grid-container-summary">
        <tr>
          <th>Status</th>
          <th>
            {getColorCodedValue('HIGH/MEDIUM/LOW')}
          </th>
        </tr>
        {
          summedValues?.map((value, index) => (
            <tr key={index}>
              <td>
                {value.split(':')[0]}
                :
              </td>
              <td style={{ textAlign: 'center' }}>{getColorCodedValue(value.split(':')[1], false)}</td>
            </tr>
          ))
        }
        <tr>
          <td>Total Orders:</td>
          <td style={{ textAlign: 'center' }}>{journeyTypeData?.numOfOrders ?? 0}</td>
        </tr>
      </table>
    );
  };

  const buildAgedOrdersView = (agedOrder) => {
    const agedOrdersDetails = journeys?.map((journey, i) => {
      const journeyType = journey?.key;
      const journeyTypeData = agedOrder?.find(order => order?.journeyType === journeyType);
      const summedValues = journeyTypeData ? summed(journeyTypeData) : null;

      return (
        <div className="agedOrders-order-details-container-border" key={i}>
          <div
            className={`agedOrders-order-details-${journeyType.toLowerCase()}`}
            data-testid={`agedOrders-order-details-${journeyType.toLowerCase()}`}
          >
            <h3>{journeyType}</h3>
            <div className="agedOrders-grid-container">
              {getSummary(journeyTypeData, summedValues, journey)}
            </div>
            {journeyType === agedOrderValues.BOPIS.key ? <hr /> : null}
          </div>
        </div>
      );
    });

    return (
      <div className="agedOrders-order-details-container">
        { agedOrdersDetails }
      </div>
    );
  };

  const agedOrdersWidgetDetails = useMemo(() => {
    if (!isAgedOrdersEnabled) return null;

     
    const data = activateTutorials ? mockedAgedOrdersData : (loading ? [] : agedOrders);
    const currentView = buildAgedOrdersView(data);

    return (
      <div className={`agedOrders-dashboard-card ${customClasses}`} data-testid="agedOrders-widget-card">
        <div className={`agedOrders-widget-details-container ${activateTutorials && 'tutorial'}`}>
          {!isReportWidget && tutorial}
          {isAgedOrdersEnabled && addTitle && (
            <div className="agedOrders-widget-title-container">
              <p>Aged Orders</p>
            </div>
          )}
          {(!activateTutorials && loading) && (
            <div className="agedOrders-widget-loading" data-testid="agedOrders-widget-loading">
              <LoadingIndicator height="100px" width="100px" fill="#FFFFFF" />
            </div>
          )}
          {(!activateTutorials && error) && (
            <div className="agedOrders-widget-error" data-testid="agedOrders-widget-error">
              <ErrorOutline color="error" fontSize="large" />
              <br />
              <p>{getMessage('fatalError')}</p>
            </div>
          )}
          <LinkWithDisable disabled={!addTitle} to="/agedOrders" style={{ textDecoration: 'none', color: 'inherit' }} className="scanAccuracyWidget_dashboard-widget">
            {currentView}
          </LinkWithDisable>
        </div>
      </div>
    );
  }, [loading, error, agedOrders, activateTutorials, addTitle, isReportWidget, isAgedOrdersEnabled]);

  return agedOrdersWidgetDetails;
};

AgedOrdersWidget.propTypes = {
  customClasses: string,
  addTitle: bool,
  isReportWidget: bool,
};

AgedOrdersWidget.defaultProps = {
  customClasses: '',
  addTitle: true,
  isReportWidget: false,
};

export default AgedOrdersWidget;
