import { mockScanDetails } from '../mockTutorialData/scanReport/mockTutorialProductData';
 
export const scanReportSteps = [
  {
    content: 'Use the date range feature to filter the store scans by date',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="scanReport-datepicker"]',
    title: 'Select a date range',
  },
  {
    content: 'Use the table header buttons to download, print or filter the table results',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: 'div[role^="toolbar"]',
    title: 'Table Actions',
  },
  {
    content: 'Click any of the headers to sort the scans by your preference',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: 'table thead tr th',
    title: 'Sort the table results',
  },
  {
    content: 'Select any row to view scan details',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '.MuiTableBody-root > tr',
    title: 'Specific Scan Results',
  },
  {
    content: 'Here you will find the summary of the last full store scan that was done in the store',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="details-widget-container"]',
    title: 'Latest Full Store Scan Details',
  },
  {
    content: 'After the completion of an RFID scan managers can analyze the results of the scan, as well as submit Fullstore scans. There is a 5 hour window from when the scan begins for managers to resume/cancel/submit a scan. If the the 5 hour window expires the options will be disabled.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="manager-approval-widget-container"]',
    title: 'Manager Approval',
  },
  {
    content: 'This feature allows a Coach to resume the scan from where they left off, enabling them to continue scanning any areas that may have been missed. Note, that a scan can only be resumed once, and the button will be disabled after it has been used.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="scan-confirmation-modal-container"]',
    title: 'Manager Approval: Resume Scan',
  },
  {
    content: 'This will allow a Coach to cancel the scan and start over from the beginning',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="scan-confirmation-modal-container"]',
    title: 'Manager Approval: Cancel Scan',
  },
  {
    content: 'This will submit the scan, reset RFID inventory and generate Missing Styles Report/Product Refill',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="scan-confirmation-modal-container"]',
    title: 'Manager Approval: Submit Scan',
  },
  {
    content: 'This status indicated that the scan is still in progress',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="manager-approval-widget-container"]',
    title: 'Scan Status: Scanning',
  },
  {
    content: 'This status indicates that the scan has timed out and will need to be restarted',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="manager-approval-widget-container"]',
    title: 'Scan Status: Timed Out',
  },
  {
    content: 'Cancelled status will appear if a given scan has been cancelled for any reason',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="manager-approval-widget-container"]',
    title: 'Scan Status: Cancelled',
  },
  {
    content: 'Submitted status will appear once a scan has successfully completed and submitted',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="manager-approval-widget-container"]',
    title: 'Scan Status: Submitted',
  },
  {
    content: 'View the different unit counts for gender or category by using the toggle feature',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="toggle-vertical"]',
    title: 'Toggle by Gender or Category',
  },
  {
    content: 'Use the variance toggle to view the summary unit counts for either missing or extra',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="header-graph-table-title"]',
    title: 'Variance Toggle',
  },
  { // ScanDetailsPage.js
    content: 'Click on a row to see additional product details for a given style-color',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '.MuiTableBody-root > tr',
    title: 'Select a single style-color',
  },
  {
    content: 'Use the buttons on the header to navigate between style colors or exit the modal view',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="dialog-header"]',
    title: 'Navigate between style-colors',
  },
  {
    content: 'Here you will find the aggregation details of the selected style-color from the scan',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 20,
    target: '[data-testid="scan-product-detail-aggregations"]',
    title: 'Style-Color Scan Aggregations',
  },
  {
    content: 'Select any of the store locations to see specific count details for that area',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="salesFloor-gtins-button"]',
    title: 'Style-Color Details by Store Location',
  },
  {
    content: 'itemInquiryS5',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="toggle-horizontal"]',
    title: 'itemInquiryS5Title',
  },
];

 
/**
 * Util that handles setting/changing steps in the tutorial when clicking through the steps
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {func} setStepIndex - a function that handles setting the step index
 * @param {string} page - a string that represents the current page
 * @param {object} history - the history object from react-router-dom
 * @param {boolean} loadingTutorial - a boolean that represents if the tutorial is loading
 * @param {boolean} restartTutorial - a boolean that represents if the tutorial is restarting
 * @param {func} setTutorialRestart - a function that handles setting the tutorial restart
 * @param {boolena} mountedDetailsPage - a boolean that represents if the details page is mounted
 */

export const setScanReportTutorialSteps = (
  isbackClicked, startTutorial, stepIndex, setStepIndex, page, history, loadingTutorial, restartTutorial, setTutorialRestart, mountedDetailsPage,
) => {
  if (startTutorial) {
    if (stepIndex === 0 && page === 'details') {
      history?.push('/scanSummary');
    }
    if (stepIndex === 3 && isbackClicked && page === 'details') {
      history?.push('/scanSummary');
    }
    if (stepIndex === 4 && page === 'summary') {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
    }
    if (stepIndex === 4 && page === 'details') {
      setTimeout(() => {
        setStepIndex(4);
      }, 300);
    }
    if (stepIndex === 4 && page === 'summary') {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
    }
    if (stepIndex === 6) {
      setTimeout(() => {
        setStepIndex(6);
      }, 300);
    }
  }
  // route back to summary if the tutorial is reset - this is unique since we arent updatin state
  if (!startTutorial && !loadingTutorial) {
    if (page === 'summary') {
      setTutorialRestart(false); // edge case to handle resetting when routed to details page
    }
    if (stepIndex !== 0) {
      setStepIndex(0);
    }
    if (document.querySelector('[data-testid="modalCloseButton"]')) {
      document.querySelector('[data-testid="modalCloseButton"]')?.click(); // close modal
    }
    // route back to summary page if the tutorial is reset - this is unique since we arent updatin state
    if (restartTutorial && mountedDetailsPage) {
      history?.push('/scanSummary');
    }
  }
};
 
/**
 * Util that handles setting/changing mockdata for the manager approval portion
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setOpenModal - a function that handles setting the tutorial restart
 * @param {boolean} widgetData - mock data for the widget portion of the tutorial
 */
export const handleMockScanData = (stepIndex, isbackClicked, setOpenModal, widgetData) => {
  switch (stepIndex) {
    case 4:
      return mockScanDetails;
    case 5:
      if (isbackClicked) {
        setOpenModal(false);
      }
      return { ...widgetData, status: 'IN_REVIEW' };
    case 6:
    case 7:
    case 8:
      if (stepIndex === 8 && isbackClicked) {
        return { ...widgetData, status: 'IN_REVIEW' };
      }
      return { ...widgetData, status: 'IN_REVIEW' };
    case 9:
      setOpenModal(false);
      return { ...widgetData, status: 'SCANNING' };
    case 10:
      return { ...widgetData, status: 'TIMED_OUT' };
    case 11:
      return { ...widgetData, status: 'CANCELLED' };
    case 12:
      return { ...widgetData, status: 'SUBMITTED' };
    default:
      return widgetData;
  }
};
