 
import React, {
  createContext, useContext, useState, useMemo, useEffect, useCallback,
} from 'react';
import { object, node } from 'prop-types';

import {
  fetchInboundVisibilityProductShipmentsData, fetchInboundVisibilityOnOrderData,
} from './inboundVisibility.axios';
import { SimWebContext } from '../../context/SimWeb.provider';
import { getDefaultVariables } from './inboundVisibility.utils';
import { isWithinSearchableRange } from '../../utils/isOutsideDateRange';

export const InboundVisibilityContext = createContext({});

const InboundVisibilityProvider = ({ children, mockedValue }) => {
  const { Provider } = InboundVisibilityContext;
  const {
    country, inboundSearchDate, storeConfig, SimDateTime, storeInfo, store, region, iso2Country, storeId, isAdmin,
  } = useContext(SimWebContext);

  const eddEnabled = (storeConfig?.isEDDEnabled?.value) || false; // This is for table Columns ONLY
  const excelDownloadsEnabled = storeConfig?.excelDownloadsEnabled?.value || isAdmin; // isAdmin is for testing in Prod of WHQ Prod team, this is temp
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const isDropZonesEnabled = storeConfig?.isDropzonesEnabled?.value || false;
  const webCarrierAndTrackingID = storeConfig?.webCarrierAndTrackingID?.value || false;
  const searchedStartDate = (inboundSearchDate && SimDateTime.startOfDay(inboundSearchDate)) || (eddEnabled ? SimDateTime.startOfDay() : SimDateTime.startOfDay().minus({ days: 7 }));
  const searchedEndDate = (inboundSearchDate && SimDateTime.endOfDay(inboundSearchDate)) || (eddEnabled ? SimDateTime.endOfDay().plus({ days: 6 }) : SimDateTime.endOfDay());

  const [onOrderData, setOnOrderData] = useState([]);
  const [onOrderError, setOnOrderError] = useState(null);
  const [onOrderLoading, setOnOrderLoading] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [data, setData] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [endDate, setEndDate] = useState(searchedEndDate);
  const [error, setError] = useState(null);
  const [facets, setFacets] = useState({});
  const [eddDates, setEddDates] = useState({});
  const [filters, setFilters] = useState([]);
  const [getDataOnLoad, setGetDataOnLoad] = useState(false);
  const [hasFacetsSelected, setHasSelectedFacets] = useState(false);
  const [isDropZonesDataLoading, setIsDropZonesDataLoading] = useState(false);
  const [isPRO, setIsPRO] = useState(false);
  const [lastSelectedGroup, setLastSelectedGroup] = useState('');
  const [loading, setLoading] = useState(false);
  const [merchGroup, setMerchGroup] = useState(null);
  const [pageSearchText, setPageSearchText] = useState('');
  const [refetch, setRefetch] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedEdd, setSelectedEdd] = useState(null);
  const [selectedStyleColorSOH, setSelectedStyleColorSOH] = useState(null);
  const [supportedFilters, setSupportedFilters] = useState([]);
  const [startDate, setStartDate] = useState(searchedStartDate);
  const [tabValue, setTabValue] = useState(country === 'USA' ? 1 : 0);
  const [tooManyDaysSelected, setTooManyDaysSelected] = useState(false);

  const maxDaysBack = 180;
  const maxDaysForward = 60;
  const searchableDays = 60;
  const isOutsideRange = useCallback((date, type) => isWithinSearchableRange(date, type, startDate, maxDaysBack, maxDaysForward, searchableDays, eddEnabled, setTooManyDaysSelected), [startDate]);

  const onDateChange = (options) => {
    const start = options.startDate;
    const end = options.endDate;
    setStartDate(start);
    setEndDate(end);
    setRefetch(false);
  };

  const onGoClick = () => {
    setFilters([]);
    setFacets({});
    setSupportedFilters([]);
    setRefetch(true);
  };

  const getProductShipments = async (variables, firstPageOnly) => {
    setData([]);
    setError(null);
    setLoading(true);

    try {
      const { inboundVisibilityData } = await fetchInboundVisibilityProductShipmentsData(variables, firstPageOnly);
      setIsPRO(inboundVisibilityData?.numberDisplayName === 'PRO');

      const eddArray = [];
      inboundVisibilityData?.productShipments.map(shipment => {
        const dateString = SimDateTime.toDateTime(shipment.estimatedDeliveryDate)?.toISODate().split('T')[0];
        if (!eddArray.includes(dateString)) {
          eddArray.push(dateString);
        }
      });
      setEddDates(eddArray);
      setSupportedFilters(inboundVisibilityData?.supportedFilters || []);
      setData(inboundVisibilityData?.productShipments || []);
    } catch (error) {
      if (error.message === 'inboundMaxLimitedExceed') setAlertMessage(error.message);
      else setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getOnOrderProductShipments = async (variables, firstPageOnly) => {
    setOnOrderData([]);
    setOnOrderError(null);
    setOnOrderLoading(true);

    try {
      const { inboundVisibilityData } = await fetchInboundVisibilityOnOrderData(variables, firstPageOnly);
      setIsPRO(inboundVisibilityData?.numberDisplayName === 'PRO');
      setSupportedFilters(inboundVisibilityData?.supportedFilters || []);
      setOnOrderData(inboundVisibilityData?.salesOrders || []);
      setOnOrderLoading(false);
    } catch (error) {
      if (error.message === 'inboundMaxLimitedExceed') setAlertMessage(error.message);
      else setOnOrderError(error.message);
      setOnOrderLoading(false);
    }
  };

  const inboundVariables = useMemo(() => {
    if (!store || !storeId || !region || !startDate || !endDate) return null;

    return getDefaultVariables(store, storeId, storeInfo?.isEMEA, region, pageSearchText, filters, eddEnabled, startDate, endDate);
  }, [store, storeId, storeInfo?.isEMEA, region, pageSearchText, filters, startDate, endDate, eddEnabled]);

  useEffect(() => {
    if (iso2Country) {
      setMerchGroup(iso2Country);
    }
  }, [iso2Country]);

  // Gets the data
  useEffect(() => {
    if (inboundVariables && storeId && getDataOnLoad && refetch) getProductShipments(inboundVariables);
  }, [inboundVariables, storeId, getDataOnLoad, refetch]);

  return (
    <Provider
      value={mockedValue ?? {
        id: 'inboundVisibility',
        title: 'inboundVisibility',
        alertMessage,
        data,
        dialogData,
        loading,
        error,
        datepicker: {
          startDate,
          endDate,
          tooManyDaysSelected,
          maxSearchableDays: searchableDays,
          handleDateChange: onDateChange,
          onGoClick,
          isOutsideRange,
        },
        eddEnabled,
        excelDownloadsEnabled,
        hasFacetsSelected,
        isDropZonesEnabled,
        isDropZonesDataLoading,
        isPRO,
        facets,
        eddDates,
        filters,
        lastSelectedGroup,
        merchGroup,
        onOrderData,
        onOrderError,
        onOrderLoading,
        pageSearchText,
        rfidEnabled,
        searchText,
        selectedEdd,
        selectedItems,
        selectedStyleColorSOH,
        supportedFilters,
        tabValue,
        webCarrierAndTrackingID,
        getOnOrderProductShipments,
        getProductShipments,
        setGetDataOnLoad,
        setDialogData,
        setFacets,
        setFilters,
        setHasSelectedFacets,
        setIsDropZonesDataLoading,
        setLastSelectedGroup,
        setPageSearchText,
        setSearchText,
        setSelectedEdd,
        setSelectedItems,
        setSelectedStyleColorSOH,
        setTabValue,

        // onOrderData,
        // onOrderError,
        // onOrderLoading,
      }}
    >
      {children}
    </Provider>
  );
};

InboundVisibilityProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

InboundVisibilityProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default InboundVisibilityProvider;
