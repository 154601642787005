 
 
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import getLocalizedPercent from '../../utils/getLocalizedPercent';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

// TODO: rename this function and the variables if this is no longer for markdowns data only
/**
 * A helper function to build the summary object for Totals widget
 * @param {object} completionSummary the Markdowns completion summary object
 * @param {string} effectiveDate effective date value
 * @param {string} locale used to get local language copy
 * @param {function} getMessage translations helper func
 */
export const buildMarkdownsSummaryObject = (completionSummary, effectiveDate, locale, getMessage) => {
  if (isEmpty(completionSummary)) return {};

  return {
    title: getMessage('markdownCompletionSummary'),
    subMessage: `${getMessage('currentMarkdown')} - ${effectiveDate}`,
    objects: [
      {
        name: 'markdownsCompleted',
        label: getMessage('stylesMarkedDown'),
        value: completionSummary.markdownsCompleted,
      },
      {
        name: 'percentMarkdownsCompleted',
        label: getMessage('percentMarkdownsCompleted'),
        value: getLocalizedPercent(locale, completionSummary.percentMarkDownsCompleted),
      },
      {
        name: 'totalNotFound',
        label: getMessage('stylesNotFound'),
        value: completionSummary.totalNotFound,
      },
      {
        name: 'percentNotFound',
        label: getMessage('percentNotFound'),
        value: getLocalizedPercent(locale, completionSummary.percentNotFound),
      },
    ],
  };
};

/**
 * Extract data from the Price Changes summary response for Summary and Visual Widgets
 * @param {object} summaryObject the Price Changes summary
 * @param {string} locale used to get local language copy
 */
export const getSummaryAndVisualData = (summaryObject, locale) => {
  if (!summaryObject) return { summary: {}, visual: null, effectiveDate: '' };

  const { divisionAggregations, taskCompletionSummary: completionSummary, effectiveDate } = summaryObject;
  const visual = divisionAggregations.map(aggregation => ({
    ...aggregation,
    centerValue: getLocalizedPercent(locale, aggregation?.percentCompletedPriceChanges),
  }));

  return { summary: completionSummary, visual, effectiveDate: DateTime.fromISO(effectiveDate).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS) };
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {boolean} isOpen - a value that represents the table row/shipdate was selected - meaning the modal was mounted
 * @param {func} setOpenModal - a function that opens/closes the modal
 * @param {func} setStepIndex - a function that handles setting the step index
 */
export const setPriceChangesTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, isOpen, setOpenModal, setStepIndex,
) => {
  if (startTutorial) {
    // TODO: add an additional step once we have multpile tabs/views on this report
    if (stepIndex === 4 && isbackClicked) {
      setOpenModal(false);
    }
    if (stepIndex === 5 && !isOpen) {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click(); // click the single row
      setTimeout(() => {
        setStepIndex(5);
      }, 500);
    }

    if (stepIndex === 6 && isbackClicked) {
      setOpenModal(true);
      setTimeout(() => {
        setStepIndex(6);
      }, 500);
    }

    if (stepIndex === 7) {
      setOpenModal(false);
    }
    if (stepIndex === 7 && isbackClicked) {
      setOpenModal(true);
      setTimeout(() => {
        setStepIndex(7);
      }, 500);
    }
  }
  if (restartTutorial) {
    if (isOpen) setOpenModal(false);
  }
};

/**
 * custom sort by product status values
 * @param {array} data the Price Changes data
 * @param {integer | string} index of the column data to be sorted or the property name ('productStatus') of an object
 * @param {string} order sort order value, either 'desc' or 'asc'
* */
export const productStatusSort = (data, index, order = 'asc') => {
  if (!data) return [];

  const customOrder = ['Completed', 'Not Found', 'Incomplete'];
  const isIndexLookUp = Number.isInteger(index);
  const sortedValue = [...data];

  sortedValue.sort((a, b) => {
    const first = isIndexLookUp ? a?.data?.[index] : a?.[index];
    const second = isIndexLookUp ? b?.data?.[index] : b?.[index];

    const indexA = customOrder.indexOf(first);
    const indexB = customOrder.indexOf(second);

    if (indexA === -1) return 1; // If 'a' is not found in customOrder, move it to the end
    if (indexB === -1) return -1; // If 'b' is not found in customOrder, move it to the end

    if (order === 'desc') return indexB - indexA;

    return indexA - indexB;
  });

  return sortedValue;
};
