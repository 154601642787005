 
export const transfersStepsArray = [
  {
    content: 'inboundVisibilityS1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="date-pickers-container"]',
    title: 'inboundVisibilityS1Title',
  },
  {
    content: 'inboundVisibilityS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="date-range-submit-button"]',
    title: 'inboundVisibilityS2Title',
  },
  {
    content: 'Select a status to filter transfers by status. All, Completed/Assembled or Open/Unknown.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="transfers-filter-dropdown"]',
    title: 'Filter By Status',
  },
  {
    content: 'Select a type to filters transfers by transfer type. All, Store, Vendor or Warehouse.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="transfers-type-dropdown"]',
    title: 'Filter By Transfer Type',
  },
  {
    content: 'Use the options on the header to search, download or print the transfers data.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'Optional Table Functionality',
  },
  {
    content: 'To expand accordion and view transfer details, click on the arrow in the row. To collapse the accordion, click on the arrow a second time.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="MUIDataTableBodyRow-0"] button',
    title: 'View Transfer Details',
  },
];

export const transfersSteps = (country, transfersSteps) => {
  const newSteps = transfersSteps;

  const fiscalDocStep = {
    content: 'For stores that enabled fiscal document or dispatch note printing, select a document type to print.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="print-option-block"]',
    title: 'Print Fiscal Document or Dispatch Note',
  };

  if (country === 'ARG' || country === 'RUS' || country === 'GRC') {
    newSteps.push(fiscalDocStep);
  }

  return newSteps;
};
