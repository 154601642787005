 
import { DateTime } from 'luxon';
import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import env from '../../environment';

const cartonErrorCodeMapping = {
  CARTON_ALREADY_RECEIVED: 'shipmentAlreadyReceived',
  INVALID_CARTON_NUMBER: 'invalidCartonNumber',
  CARTON_MISSING_ERROR: 'cartonNotFound',
};

/**
 * A helper function to get the error message from axios request and translate the message if needed.
 * @param {object} error the error object from axios request
 * @param {function} getMessage a function to get translated message
 */
export const getErrorMessage = (error, getMessage) => {
  let message;

  if (error?.response?.status === 409) {
    message = getMessage(cartonErrorCodeMapping.CARTON_ALREADY_RECEIVED);
  } else if (error?.response?.data?.errors[0]) {
    const errorCodeMessage = cartonErrorCodeMapping[error.response.data.errors[0].code];
    message = errorCodeMessage ? getMessage(errorCodeMessage) : error.response.data.errors[0].message;
  } else {
    message = error?.message || '';
  }

  return message;
};

/**
 * A helper function to filter data with indexes of selected items
 * @param {array} data the receiving log data
 * @param {array} selectedItems an array of indexes of selected data
 */
export const filterSelectedData = (data, selectedItems) => {
  if (selectedItems.length > 0) {
    return data?.filter((item, i) => selectedItems.includes(i));
  }
  return data;
};

/**
 * A helper function to filter and build object for CSV download
 * @param {array} data the receiving log data
 * @param {array} selectedItems an array of indexes of selected data
 * @param {boolean} isIOS is logged on on iPad
 * @param {boolean} useProNumber use PRO or DOC number
 * @param {sting} timeZone used for date/time stamps
 */
export const buildDownloadData = (data, selectedItems, isIOS, useProNumber, timeZone) => {
  const csvFormat = !isIOS ? '"="' : ''; // forces csv value to string
  const selectedArray = filterSelectedData(data, selectedItems);
  const toDownload = [];
  const isProd = !env.isProd();

  selectedArray?.map(selected => {
    selected.purchaseOrders.map(purchaseOrder => {
      purchaseOrder.cartons.map(carton => {
        carton.cartonItems.map(item => {
          toDownload.push({
            ...(useProNumber
              ? { proNumber: `${csvFormat}${selected.proNumber}` }
              : { deliveryNumber: `${csvFormat}${selected.deliveryNumber}` }),
            poNumber: `${csvFormat}${purchaseOrder.purchaseOrderNumber}`,
            ...!isProd && { deliveryNumbers: carton?.deliveryNumbers?.join(',') },
            carton: `${csvFormat}${carton.cartonNumber}`,
            receivedDate: (carton.receiveDate && DateTime.fromISO(carton.receiveDate, { zone: timeZone }).toLocaleString(DATE_TIME_SHORT_WITH_APPENDED_ZEROS)) || '-',
            upc: `${csvFormat}${item.gtin}`,
            unitsExpected: item.unitsExpected,
            unitsReceived: item.unitsReceived,
            variance: Math.abs(item.variance),
            stockOnHand: item.stockOnHand,
          });
        });
      });
    });
  });
  return toDownload;
};
