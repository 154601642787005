 
 
import React, { useContext, useRef, useMemo } from 'react';
import { array } from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Paper from '@mui/material/Paper';
import { DateTime } from 'luxon';
import CustomTable from '../../components/customTable/CustomTable';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { buildDownloadData } from './oosAudits.utils';

import './OOSAuditDialogDetails.css';

import { SimWebContext } from '../../context/SimWeb.provider';

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    right: '23px',
    top: '7px',
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
  dialogHeader: {
    fontSize: '1.25rem', fontWeight: 500, padding: '16px 24px 0px',
  },
});

const OOSAuditDialogDetails = ({ dialogData }) => {
  const { isIOS, getMessage } = useContext(SimWebContext);
  const classes = useStyles();
  const csvLink = useRef();

  const downloadData = useMemo(() => buildDownloadData(dialogData), [dialogData]);

  if (!dialogData || !dialogData.length) {
    return null;
  }

  const downloadIcon = (
    <>
      <IconButton
        onClick={() => csvLink?.current?.link.click()}
        className={classes.icon}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
      <CSVLink
        data={downloadData}
        filename="OOS Audits"
        ref={csvLink}
        target="_blank"
      />
    </>
  );

  const printIcon = (
    <PrintIcon
      className={classes.icon}
      onClick={() => {
        window.print();
      }}
      style={{ width: '25px' }}
      data-testid="oos-dialog-muiDataTable-print"
    />
  );

  const getToolbarIcons = () => (isIOS ? downloadIcon : printIcon);

  return (
    <div>
      <div className={classes.dialogHeader}>
        {`${DateTime.fromISO(dialogData[0]?.date).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)} 
            - ${dialogData[0].auditScore}%`}
      </div>
      <Paper elevation={6} style={{ margin: 30 }}>
        <div data-testid="oos-dialog-muiDataTable" style={{ position: 'relative' }}>
          <DialogTitle>
            { getToolbarIcons()}
          </DialogTitle>
          <DialogContent>
            {dialogData?.map(item => (
              <div className="dropdown-table-detail" key={item?.key}>
                <div className="tables">
                  {item?.auditData?.map(audit => (
                    <CustomTable
                      key={audit.key}
                      columns={[
                        {
                          key: 'style', class: 'left style noBorder', dataType: 'string', label: getMessage('style'),
                        },
                        {
                          key: 'color', class: 'noBorder', dataType: 'string', label: getMessage('clr'),
                        },
                        {
                          key: 'size', class: '', dataType: 'string', label: getMessage('sizes'),
                        },
                        {
                          key: 'stockOnHand', class: '', dataType: 'string', label: getMessage('soh'),
                        },
                        {
                          key: 'passFail', class: '', dataType: 'passFailSummary', label: getMessage('passFail'),
                        },
                        {
                          key: 'pulled', class: '', dataType: 'string', label: getMessage('pulled'),
                        },
                      ]}
                      data={audit?.skus}
                      renderTotals
                    />
                  ))}
                </div>
              </div>
            ))}
          </DialogContent>
        </div>
      </Paper>
    </div>
  );
};

OOSAuditDialogDetails.propTypes = {
  dialogData: array.isRequired,
};

export default OOSAuditDialogDetails;
