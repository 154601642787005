 
 
import React, { useEffect, useMemo, useContext } from 'react';
import { bool, func } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { BarcodeScannerContext } from './BarcodeSannner.provider';

import './BarcodeScanner.css';

const BarcodeScanner = ({
  multiScanEnabled, updateSearchParam, setIsBarcodeOpen,
}) => {
  const {
    barcodeReader, videoInputDevices, selectedDeviceId, scanList, code,
    selectedSource, setScanList, setupDevices, decodeContinuously, setSelectedDeviceId,
  } = useContext(BarcodeScannerContext);
  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');

  const scanFrame = () => (
    <svg
      width={isMobile ? '100%' : '75%'}
      height={isMobile ? '100%' : '75%'}
      className="svg"
      viewBox="0 0 100 100"
    >
      <path d="M25,2 L2,2 L2,25" fill="none" stroke="white" strokeWidth="2" />
      <path d="M2,75 L2,98 L25,98" fill="none" stroke="white" strokeWidth="2" />
      <path d="M75,98 L98,98 L98,75" fill="none" stroke="white" strokeWidth="2" />
      <path d="M98,25 L98,2 L75,2" fill="none" stroke="white" strokeWidth="2" />
    </svg>
  );

  const onClosedClicked = () => {
    updateSearchParam(scanList);
    setIsBarcodeOpen(false);
  };

  useEffect(() => {
    if (multiScanEnabled && code) {
      if (!scanList?.includes(code)) {
        const scanListCopy = [...scanList];
        scanListCopy.push(code);
        setScanList(scanListCopy);
      }
    }
  }, [code, multiScanEnabled]);

  useEffect(() => {
    if (barcodeReader) {
      barcodeReader?.listVideoInputDevices()
        .then(videoInputDevices => setupDevices(videoInputDevices, document.getElementById('sourceSelect')))
        .catch(error => console.error(error));

      decodeContinuously(multiScanEnabled, selectedDeviceId, updateSearchParam, setIsBarcodeOpen);
    }
  }, [barcodeReader]);

  const video = isMobile ? <video id="video" width="900px" height="650px" /> : <video id="video" width="550px" height="500px" />;

  const scannedItems = useMemo(() => {
    const num = scanList?.length ?? 0;

    return (
      <div
        data-testid="barcode-scanner-items-count"
        className="barcode-scanner-items-count"
      >
        <h2>{`${num} item(s) scanned`}</h2>
      </div>
    );
  }, [scanList]);

  return (
    <div data-testid="barcode-scanner-container" className="barcode-scanner-container">
      <div className="barcode-scanner-header">
        <div id="sourceSelectPanel">
          <label htmlFor="sourceSelect">Change video source:</label>
          <select
            id="sourceSelect"
            onChange={() => setSelectedDeviceId(selectedSource.value)}
          >
            {videoInputDevices?.map((element) => (
              <option value={element.deviceId}>{element.label}</option>
            ))}
          </select>
        </div>
        <IconButton data-testid="modalCloseButton" onClick={onClosedClicked} size="large">
          <CloseIcon fontSize="large" />
        </IconButton>
      </div>
      <div className="webcam-container">
        {video}
        <div className="barcode-frame-container">
          {scanFrame()}
        </div>
      </div>
      {multiScanEnabled && scannedItems}
    </div>
  );
};

BarcodeScanner.propTypes = {
  updateSearchParam: func.isRequired,
  setIsBarcodeOpen: func.isRequired,
  multiScanEnabled: bool,
};

BarcodeScanner.defaultProps = {
  multiScanEnabled: false,
};

export default BarcodeScanner;
