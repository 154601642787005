import React, { useContext } from 'react';
import {
  any, arrayOf, string, bool, shape,
} from 'prop-types';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import getFormattedPrice from '../../utils/getFormattedPrice';

import { TranslationsContext } from '../../context/Translations.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

 
const CustomTable = ({
  columns, data, renderTotals,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const { currencyCode, locale, SimDateTime } = useContext(SimWebContext);

  const totals = {};
  const countedStrings = {};
  const filteredColumns = columns?.filter(c => c);
  filteredColumns?.forEach((column, index) => {
    totals[column.key] = 0;
    if (column.dataType.startsWith('passFail')) {
      totals[column.key] = true;
    }
    countedStrings[column.key] = {};
     
    if (!column.class) { filteredColumns[index].class = ''; }
  });

  return (
    <table className="custom-table">
      <thead>
        <tr>
          {filteredColumns?.map(column => (
            <td key={column?.key} className={column?.class}>
              {column?.label}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map(row => (
          <tr key={row.key || JSON.stringify(row)} className={row.customRowClassName}>
            {filteredColumns?.map((column) => {
              const value = row[column.key];

              // protect against having strings in numeric fields
              let columnType = column.dataType;
              if (typeof value === 'string' && columnType !== 'countedString') {
                columnType = 'string';
              }

              switch (column.dataType) {
                case 'number':
                  totals[column.key] += value;
                  return (
                    <td className={`${column.class} ${value < 0 ? 'red' : ''}`} key={column.key}>
                      {value.toLocaleString()}
                    </td>
                  );
                case 'negativeNumber':
                  totals[column.key] += value;
                  return (
                    <td className={`${column.class} ${value > 0 ? 'red' : ''}`} key={column.key}>
                      {value.toLocaleString()}
                    </td>
                  );
                case 'financialNumber':
                  totals[column.key] += value;
                  return (
                    <td className={`${column.class} ${value < 0 ? 'red' : ''}`} key={column.key}>
                      {Math.abs(value).toLocaleString()}
                    </td>
                  );
                case 'isoDate':
                  return (
                    <td className={column.class} key={column.key}>
                      {SimDateTime.toDateTime(value).isValid ? SimDateTime.toLocaleString(value, DATE_SHORT_WITH_APPENDED_ZEROS) : '-' }
                    </td>
                  );
                case 'countedString':
                  if (value && value !== '' && !countedStrings[column.key][value]) {
                    totals[column.key] += 1;
                    countedStrings[column.key][value] = true;
                  }
                  return (
                    <td className={column.class} key={column.key}>
                      {value}
                    </td>
                  );
                case 'passFail':
                  if (totals[column.key] === true) {
                    if (!value) {
                      totals[column.key] = false;
                    }
                  }
                  return (
                    <td className={`${column.class} ${value ? '' : 'red'}`} key={column.key}>
                      {value ? getMessage('pass') : getMessage('fail')}
                    </td>
                  );
                case 'passFailSummary':
                  if (totals[column.key] === true) {
                    if (!value) {
                      totals[column.key] = false;
                    }
                  }
                  return (
                    <td className={`${column.class} ${value ? '' : 'red'}`} key={column.key}>
                      {value ? getMessage('p') : getMessage('f')}
                    </td>
                  );
                case 'monetary':
                  if (value !== undefined && value !== null) {
                    totals[column.key] += value;
                  }
                  return (
                    <td className={`${column.class} ${value < 0 ? 'red' : ''}`} key={column.key}>
                      {getFormattedPrice(value, locale, currencyCode)}
                    </td>
                  );
                case 'node': {
                  let check;
                  if (value) {
                    check = value === 'true' ? <span className="g72-check" /> : <span className="g72-x-thick" />;
                  }
                  return (
                    <td className={column.class} key={column.key}>
                      {check || '-'}
                    </td>
                  );
                }
                case 'string':
                default:
                  return (
                    <td className={column.class} key={column.key}>
                      {(value === undefined || value === null) ? '-' : value}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
        {renderTotals ? (
          <tr className="totals">
            <td className={filteredColumns[0] && filteredColumns[0].class}>
              {getMessage('totals')}
            </td>
            {filteredColumns?.slice(1).map((column) => {
              if (column?.hideTotals) {
                 
                return <td key={column?.key} />;
              }

              const value = column.customTotal || totals[column.key];
              switch (column.dataType) {
                case 'number':
                  return (
                    <td
                      className={`${column.class} ${column.customTotalClass} ${value < 0 ? 'red' : ''}`}
                      key={column.key}
                    >
                      {value.toLocaleString()}
                    </td>
                  );
                case 'negativeNumber':
                  return (
                    <td
                      className={`${column.class} ${column.customTotalClass} ${value > 0 ? 'red' : ''}`}
                      key={column.key}
                    >
                      {value.toLocaleString()}
                    </td>
                  );
                case 'financialNumber':
                  return (
                    <td
                      className={`${column.class} ${column.customTotalClass} ${value < 0 ? 'red' : ''}`}
                      key={column.key}
                    >
                      {Math.abs(value).toLocaleString()}
                    </td>
                  );
                case 'countedString':
                  return (
                    <td className={`${column.class} ${column.customTotalClass}`} key={column.key}>
                      {value}
                    </td>
                  );
                case 'passFail':
                case 'passFailSummary':
                  return (
                    <td className={`${column.class} ${column.customTotalClass} ${value ? '' : 'red'}`} key={column.key}>
                      {value ? getMessage('pass') : getMessage('fail')}
                    </td>
                  );
                case 'monetary':
                  return (
                    <td className={`${column.class} ${column.customTotalClass} ${value < 0 ? 'red' : ''}`} key={column.key}>
                      {Math.abs(value).toLocaleString(locale, { style: 'currency', currency: currencyCode })}
                    </td>
                  );
                default:
                  return (
                    <td className={`${column.class} ${column.customTotalClass}`} key={column.key}>
                      {!!value && value}
                    </td>
                  );
              }
            })}
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

CustomTable.propTypes = {
  columns: arrayOf(shape({
    key: string.isRequired,
    class: string,
    dataType: string,
    label: string,
    hideTotals: bool,
    customTotal: any,
    customTotalClass: string,
  })),
  data: arrayOf(any),
  renderTotals: bool,
};

CustomTable.defaultProps = {
  columns: [],
  data: [],
  renderTotals: false,
};

export default CustomTable;
