import React, { useContext } from 'react';
import { bool, func } from 'prop-types';
import Card from '@mui/material/Card';
import Button from '../../components/Button';
import { TranslationsContext } from '../../context/Translations.provider';

import './productRefill.css';
import { ProductRefillContext } from './ProductRefill.provider';
import { useTutorial } from '../../components/tutorial/useTutorial';

/**
  * Creates a widget with data for the Product Refill Summary details
  * @param {Function} setOpenModal function that open the confirmation modal
  * @param {Boolean} disableMarkAllAsFilled handles the disabled state of the btn
  * @return {Node} returns a new component with the correct data
*/
const ProductRefillHeader = ({ setOpenModal, disableMarkAllAsFilled }) => {
  const { getMessage } = useContext(TranslationsContext);
  const { summary, isFetching } = useContext(ProductRefillContext);
  const { tutorial } = useTutorial('productRefill', isFetching);

  const refillSummaryData = (
    <div data-testid="product-refill-header-refillRev-data">
      <div className="pair">
        <span className="key">{`${getMessage('unitsInQueue')}:`}</span>
        <span className="value">{summary?.unitsInQueue}</span>
      </div>
      <div className="pair">
        <span className="key">{`${getMessage('openRefills')}:`}</span>
        <span className="value">{summary?.refills}</span>
      </div>
      <div className="pair">
        <span className="key">{`${getMessage('openPicklists')}:`}</span>
        <span className="value">{summary?.pickList}</span>
      </div>
      <div className="pair">
        <span className="key">{`${getMessage('openUnbinned')}:`}</span>
        <span className="value">{summary?.unbinned}</span>
      </div>
    </div>
  );

  const loadingProductRefillSummary = (
    <div className="new-info-box" data-testid="product-refill-header_loading">
      <div className="single">
        {getMessage('loading')}
      </div>
    </div>
  );

  return (
    <div className="feature-header-container" data-testid="product-refill-header">
      <div className="feature-header">
        <div className="left">
          <div className="feature-title">
            {getMessage('productRefill')}
          </div>
          {tutorial}
          <Card className="new-info-box">
            {isFetching ? loadingProductRefillSummary : refillSummaryData}
          </Card>
        </div>
        <div className="right">
          <Button
            onClick={() => setOpenModal(true)}
            buttonText={getMessage('markAllAsFilled')}
            extraClasses="mark-all-as-filled-button"
            isLoading={isFetching}
            disabled={isFetching || (summary?.lastReportDateTime === '') || disableMarkAllAsFilled}
            data-testid="product-refill-header_markTasksFilledButton"
          />
        </div>
      </div>
    </div>
  );
};

ProductRefillHeader.propTypes = {
  setOpenModal: func,
  disableMarkAllAsFilled: bool,
};

ProductRefillHeader.defaultProps = {
  setOpenModal: undefined,
  disableMarkAllAsFilled: false,
};

export default ProductRefillHeader;
