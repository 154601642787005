 
 
import jsonParse from "./jsonParse";
import env from '../environment';

/**
 * Adds a user-defined New Relic broswer interacton
 * @param {String} name - name of the browser interaction to create
 */
export const createNewRelicInteraction = (name) => {
  let interaction = {
    end: () => {},
    setAttribute: (name, value) => { console.log(`${name}`, { value }); },
  };

  if (window?.newrelic) {
    interaction = window.newrelic.interaction();
    interaction.setName(name).save();
    if (env.isTest()  && !env.isRunningTests()) {
      console.log(interaction);
    }
  }

  if (env.isTest() && !env.isRunningTests()) {
    console.log('newRelicInteraction', { name });
  }

  return interaction;
};

/**
 * Adds a user-defined New Relic attribute name and value to subsequent events on the page
 * @param {String} name - name or category of the New Relic action
 * @param {String} value - value of the New Relic attribute
 */
export const newRelicAttribute = (name, value) => {
  try {
    if (window?.newrelic?.setCustomAttribute) {
      window.newrelic.setCustomAttribute(name, value);
    }
  } catch (error) {
    env.isProd() && console.error('newRelicAttribute-Failed', JSON.stringify(error, null, 2));
  }

  if (env.isTest() && !env.isRunningTests()) {
    console.log(`${name}`, { value });
  }

  return Promise.resolve();
};

/**
 * Reports a browser PageAction event to New Relic
 * @param {String} name - name or category of the New Relic action
 * @param {Object} attributes - data object sent to New Relic
 */
export const newRelicAction = (name, attributes) => {
  try {
    if (window?.newrelic?.addPageAction) {
      window.newrelic.addPageAction(name, attributes);
    }
  } catch (error) {
    env.isProd() && console.error('newRelicAction-Failed', JSON.stringify(error, null, 2));
  }

  if (env.isTest() && !env.isRunningTests()) {
    console.log(`${name}`, attributes);
  }

  return Promise.resolve();
};

/**
 * A function for captureing errors in catch blocks and sending to New Relic
 * @param {String} type - type of error being caught and sent to New Relic
 * @param {Error} error - Optional. The error being caught and sent to New Relic
 * @param {Object} operation - Object containg the operationName, customMesage, variables
 * @param  {Object} data - Optional. Name/value pairs representing custom attributes
 */ 
export const logErrorAction = (type, error, operation, data) => {
  if(env.isLocalHost()) return;

  if (env.isTest() && !env.isRunningTests()) {
    console.error(`${type}-${operation?.operationName}`, { operation, error, data });
  }
  
  // Log errors/messages in production
  if (error?.stack && Error.captureStackTrace) {
    Error.captureStackTrace(error, Error);
  }

  newRelicAction('sim-web-log-error', {
    type,
    error: jsonParse(error),
    operation: jsonParse(operation),
    data: jsonParse(data),
  });

};
