 
export const columnIndex = {
  cartonId: 0,
  bin: 1,
  styleColor: 2,
  category: 3,
  gender: 4,
  description: 5,
  size: 6,
  unitsExpected: 7,
  soh: 8,
  totalSOH: 9,
  price: 10,
  available: 11,
};

export const proSeparatedDataIndex = {
  carrierCode: 0,
  trackingNumber: 1,
  poNumber: 2,
  cartonId: 3,
  bin: 4,
  styleColor: 5,
  gender: 6,
  description: 7,
  size: 8,
  unitsExpected: 9,
  soh: 10,
  totalSOH: 11,
  price: 12,
  available: 13,
  division: 14,
  category: 15,
};

export const sohIndex = 13;

/**
 * Format and modify columns of downlable table
 * @param {object} columns - columns within the muidatatable
 * @param {bool} rfidEnabled - check if RFID is enabled
 */
export const buildDownloadHeaders = (columns, rfidEnabled, excelDownloadsEnabled) => {
  // TODO Temporarily disabled: GOLINV-10756
  const RfidEnabledOrder = [
    'cartonId', 'bin', 'style', 'color', 'category', 'gender', 'description', 'size',
    'unitsExpected', 'fohSOH', 'bohSOH', 'offsiteSOH', 'totalSOH', 'price', 'available',
  ];

  const nonRfidOrder = [
    'cartonId', 'bin', 'style', 'color', 'category', 'gender', 'description', 'size',
    'unitsExpected', 'soh', 'totalSOH', 'price', 'available',
  ];

  const columnsOrder = rfidEnabled ? RfidEnabledOrder : nonRfidOrder;

  const headers = [];
  columnsOrder.forEach(item => {
    const header = columns.find(_ => _.name === item);
    if (header) {
      headers.push(excelDownloadsEnabled ? header.label : header);
    }
  });

  return headers;
};

export const getTotalExpectedCount = (rows) => {
  const total = new Map();
  if (rows) {
    rows.forEach(item => {
      if (!item) return;

      if (!total.has(item?.styleColor)) {
        total.set(item?.styleColor, item?.unitsExpected);
      } else {
        const previousCount = total.get(item?.styleColor);
        total.set(item?.styleColor, (previousCount + item?.unitsExpected));
      }
    });
  }
  return total;
};

/**
 * Format and modify data for purchase order downloads
 * @param {array} data - data returned from selecting a row in inbound vis report
 * @param {bool} rfidEnabled - check if RFID is enabled
 */
export const buildDownloadData = (data, rfidEnabled) => {
  const toDownload = [];

  data?.forEach(item => {
    const rowData = item.data;

    const styleColor = rowData?.[columnIndex.styleColor]; // style-color
    const [style, color] = (styleColor && [...styleColor.split('-')]) || [];
     /* eslint-disable-next-line no-unsafe-optional-chaining */
    const [sh, boh, offsite] = rowData?.[columnIndex.soh] ? [...rowData?.[columnIndex.soh]?.split('/')] : [];
    const sohValues = rfidEnabled ? [sh?.trim() ?? '', boh?.trim() ?? '', offsite?.trim() ?? ''] : [sh];

    const row = {
      data: [
        // TODO Temporarily disabled: GOLINV-10756
        // rowData[rowDataIndex.lirp],
        rowData[columnIndex.cartonId],
        rowData[columnIndex.bin],
        style,
        color,
        rowData[columnIndex.category],
        rowData[columnIndex.gender],
        rowData[columnIndex.description],
        rowData[columnIndex.size],
        rowData[columnIndex.unitsExpected],
        ...sohValues,
        rowData[columnIndex.totalSOH],
        rowData[columnIndex.price],
        rowData[columnIndex.available],
      ],
    };
    toDownload.push(row);
  });
  return toDownload;
};
