import React from 'react';
import { string } from 'prop-types';

 
export const Packed = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.25 3.75C13.01 3.75 12 4.76 12 6.5V13.5" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M20.25 9.75H3.75" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M14.25 3.75H18.64L20.25 9.75V20.25H3.75V9.75L5.36 3.75H10.5" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
    </svg>
  </div>
);

Packed.propTypes = {
  extraClasses: string,
};

Packed.defaultProps = {
  extraClasses: '',
};
