 
import { isEmpty } from 'lodash';
/**
 * Build data for donut chart
 * @param {object} data style compliance widget data
 * @param {boolean} activateTutorials is tutorial mode activated
 * @param {function} getMessage translations helper func
 * @param {function} newRelicAction New Relic logging function
 */
export const buildGraphData = (data, activateTutorials, getMessage, newRelicAction) => {
  const difference = 100;
  let apparelData;
  let footwearData;

  if (!isEmpty(data)) {
    const apparelAccuracy = data.apparelPercentageTotal;
    const footwearAccuracy = data.footwearPercentageTotal;
    const combinedAverage = Math.round((apparelAccuracy + footwearAccuracy) / 2);

    apparelData = [
      {
        value: apparelAccuracy,
        label: getMessage('represented'),
        title: getMessage('apparel'),
        missing: data.apparelMissing,
        total: data.apparelTotal === 0 ? 100 : data.apparelTotal,
        colors: {
          start: getComputedStyle(document.documentElement).getPropertyValue('--apparel-color'),
          end: getComputedStyle(document.documentElement).getPropertyValue('--apparel-color2'),
        },
      },
      {
        value: difference - apparelAccuracy,
        label: getMessage('total'),
        missing: difference - data.apparelMissing,
        total: data.apparelTotal === 0 ? 100 : data.apparelTotal,
      },
    ];

    footwearData = [
      {
        value: footwearAccuracy,
        label: getMessage('represented'),
        title: getMessage('footwear'),
        missing: data.footwearMissing,
        total: data.footwearTotal === 0 ? 100 : data.footwearTotal,
        colors: {
          start: getComputedStyle(document.documentElement).getPropertyValue('--footwear-color'),
          end: getComputedStyle(document.documentElement).getPropertyValue('--footwear-color2'),
        },
      },
      {
        value: difference - footwearAccuracy,
        label: getMessage('total'),
        missing: difference - data.footwearMissing,
        total: data.footwearTotal === 0 ? 100 : data.footwearTotal,
      },
    ];

    if (!activateTutorials && apparelAccuracy && footwearAccuracy) {
      newRelicAction('sim-size-accuracy', { action: { apparelAccuracy, footwearAccuracy, combinedAverage } });
    }
  }

  return { apparelData, footwearData };
};
