import React, {
  useContext, useMemo, useState, useEffect,
} from 'react';
import { orderBy } from 'lodash';
import MUIDataTable from 'mui-datatables';
import { Dialog } from '@mui/material';
import { LoadingIndicator } from '../../assets/Svgs/index';
import ProductRefillTaskDialog from './ProductRefillTaskDialog';
import { filterTasksData } from './productRefill.utils';

import './productRefill.css';
import { SimWebContext } from '../../context/SimWeb.provider';
import { ProductRefillContext } from './ProductRefill.provider';
import RefreshButton from '../../components/refresh/Refresh';

const ProductRefillList = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    tasks, error, isFetching, setRefetchTasks,
  } = useContext(ProductRefillContext);

  const [isOpen, setIsOpen] = useState(false);
  const [taskIndex, setTaskIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedTask, setSelectedTask] = useState();

  useEffect(() => {
    setSelectedTask(tasks?.find(task => task.id === rows?.[taskIndex]?.key));
  }, [taskIndex]);

  useEffect(() => {
    if (tasks) setRows(filterTasksData(tasks));
  }, [tasks]);

  const columns = [{
    name: 'bin',
    label: getMessage('bin'),
    options: {
      filterType: 'textField',
    },
  }, {
    name: 'picklistName',
    label: getMessage('picklistName'),
    options: {
      filterType: 'textField',
    },
  }, {
    name: 'units',
    label: getMessage('units'),
    options: {
      filter: false,
    },
  }, {
    name: 'date',
    label: getMessage('date'),
    options: {
      filter: false,
    },
  }, {
    name: 'key',
    label: 'Row Key',
    options: {
      filter: false,
      display: 'excluded',
    },
  }];

  const title = useMemo(() => (isFetching ? <LoadingIndicator /> : <RefreshButton id="product-refill" classes="product-refill-date" refetch={() => setRefetchTasks(true)} />), [isFetching]);

  const productList = useMemo(() => (
    <div data-testid="refill-table" className="product-refill-list">
      {isFetching && <LoadingIndicator dataTestId="product-refill-page-loading-indicator" />}
      {!isFetching && (
        <MUIDataTable
          title={title}
          data={rows}
          columns={columns}
          className="product-refill-table"
          data-testid="product-refill-table"
          options={{
            selectableRows: 'none',
            rowsPerPage: 100,
            rowsPerPageOptions: [15, 25, 50, 100],
            print: false,
            sort: true,
            onRowClick: (rowData, { dataIndex }) => {
              setSelectedTask(tasks.find(x => x.id === rowData[4]));
              setIsOpen(true);
              setTaskIndex(dataIndex);
            },
            textLabels: {
              body: {
                noMatch: getMessage('noData'),
              },
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            onColumnSortChange: (changedColumn, direction) => {
              setRows(orderBy(rows, changedColumn, [`${direction}`]));
            },
            sortOrder: {
              name: 'date',
              direction: 'desc',
            },
            // ignoring lint rule bc the data isn't rendering properly if i update to the perfered-template
             
            onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
          }}
        />
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          style: {
            minHeight: '90%',
            maxHeight: '90%',
            minWidth: '90%',
            maxWidth: '90%',
          },
        }}
      >
        <ProductRefillTaskDialog
          parentDataRowCount={rows?.length}
          selectedTask={selectedTask}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setTaskIndex={setTaskIndex}
          taskIndex={taskIndex}
        />
      </Dialog>
    </div>
  ), [rows, error, isFetching, getMessage, columns, taskIndex, selectedTask, isOpen]);

  return productList;
};

export default ProductRefillList;
