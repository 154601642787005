const mockScanDetails = [
  {
    accuracy: 99,
    date: '12/31/2024, 5:01 AM - 12/31/2024, 6:13 AM',
    expected: 30379,
    extra: 347,
    id: '9e7f0d08-2716-3851-9cd6-4b8d5ec0c832',
    lastScanStatus: 'SUBMITTED',
    missing: 339,
    scanDuration: 60,
    scanType: 'FULL_STORE_SCAN',
    scanned: 30387,
    totalValueVarianceExtra: 18862,
    totalValueVarianceMissing: 22281,
  }, {
    accuracy: 99,
    date: '1/17/2025, 5:03 AM - 1/17/2025, 6:02 AM',
    expected: 33131,
    extra: 612,
    id: '936f4d5c-1649-3b43-8161-4bdfde2b3395',
    lastScanStatus: 'SUBMITTED',
    missing: 480,
    scanDuration: 57,
    scanType: 'FULL_STORE_SCAN',
    scanned: 33263,
    totalValueVarianceExtra: 28372,
    totalValueVarianceMissing: 27944,
  }, {
    accuracy: 99,
    date: '1/14/2025, 6:06 AM - 1/14/2025, 6:53 AM',
    expected: 30481,
    extra: 461,
    id: '7b9d54bf-86c6-3abe-bbd2-6db1df1012dc',
    lastScanStatus: 'SUBMITTED',
    missing: 424,
    scanDuration: 41,
    scanType: 'FULL_STORE_SCAN',
    scanned: 30518,
    totalValueVarianceExtra: 28470,
    totalValueVarianceMissing: 25662,
  }, {
    accuracy: 97,
    date: '1/37/2025, 5:03 AM - 1/17/2025, 6:02 AM',
    expected: 53423,
    extra: 343,
    id: '936f4d5c-1649-3b43-8161-4bdfde2531301',
    lastScanStatus: 'SUBMITTED',
    missing: 180,
    scanDuration: 43,
    scanType: 'FULL_STORE_SCAN',
    scanned: 346642,
    totalValueVarianceExtra: 23453,
    totalValueVarianceMissing: 35223,
  },
];

export default mockScanDetails;
