 
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import setApplicationSource from './utils/setApplicationSource';
import { newRelicAttribute } from './utils/newRelicPageActions';
import { environment } from './environment';

import App from './App';
import regionMap from './constants/region-map.json';
import TranslationsProvider from './context/Translations.provider';

// the order of these imports is apparently pretty important. Wrong order and the app looks weird.
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './common.css';
import LoginProvider from './context/Login.provider';
import ErrorProvider from './context/Error.provider';
import StoreConfigProvider from './context/StoreConfig.provider';
import StoreViewsProvider from './context/StoreViews.provider';
import SimWebProvider from './context/SimWeb.provider';

const country = localStorage.getItem('country') || localStorage.getItem('trueStoreId.country');
const region = regionMap[country] || 'us-east-1';
global.SIM_REGION = region;

newRelicAttribute('sim-environment', environment);

setApplicationSource();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <TranslationsProvider>
        <ErrorProvider>
          <LoginProvider>
            <StoreConfigProvider>
              <StoreViewsProvider>
                <SimWebProvider>
                  <SnackbarProvider dense preventDuplicate maxSnack={3} style={{ width: '500px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <App />
                  </SnackbarProvider>
                </SimWebProvider>
              </StoreViewsProvider>
            </StoreConfigProvider>
          </LoginProvider>
        </ErrorProvider>
      </TranslationsProvider>
    </Router>
  </React.StrictMode>,
);
