import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { SizeComplianceWidget } from '../../../components/dashboardWidgets';

const SizeCompliance = () => {
  const { scansLoading, data } = useContext(DashboardContext);
  const { storeConfig, storeInfo } = useContext(SimWebContext);

  const rfidSizeComplianceWidgetEnabled = storeConfig?.sizeComplianceEnabled?.value;
  const [nikeDoor, setNikeDoor] = useState('');
  const [storeType, setStoreType] = useState('');

  useEffect(() => {
    if (storeInfo) {
      setNikeDoor(storeInfo.facilityType);
      setStoreType(storeInfo.businessConcept);
    }
  }, [storeInfo]);

  return (rfidSizeComplianceWidgetEnabled
      && (
        <div className="sizeComplianceWidget_dashboard_container">
          <SizeComplianceWidget loading={scansLoading} data={data?.sizeCompliance} nikeDoor={nikeDoor} storeType={storeType} />
        </div>
      )
  );
};

export default SizeCompliance;
