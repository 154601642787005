import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
// import { ReactComponent as Shoe } from '../assets/images/backdrop_shoe.svg';

const ShoeboxFragment = ({ label }) => (
  <div className="shoebox flex-column" data-testid="shoebox">
    {/* <Shoe /> */}
    <Typography variant="h5" gutterBottom>
      {label}
    </Typography>
  </div>
);

ShoeboxFragment.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ShoeboxFragment;
