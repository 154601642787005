 
import {
  func, object,
} from 'prop-types';
import React, { useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { buildOOSTrackingTableRowsData } from './oosAudits.utils';

import { SimWebContext } from '../../context/SimWeb.provider';

const OOSTrackingTable = ({
  dataForRender, setDialogData, setOpen,
}) => {
  const { isPrintingEnabled, getMessage } = useContext(SimWebContext);
  const columns = [
    getMessage('date'),
    getMessage('auditScore'),
    getMessage('passed'),
    getMessage('auditNumber'),
    getMessage('opId'),
  ];

  const handleModalOpen = (setOpen) => {
    setOpen(true);
  };

  const theme = createTheme(({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
    },
  }));
  return (
    <div data-testid="oosTrackingReportTable" className="oos-tracking-table">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={buildOOSTrackingTableRowsData(dataForRender)}
            columns={columns}
            options={{
              selectableRows: 'none',
              rowsPerPage: 100,
              rowsPerPageOptions: [],
              filter: false,
              viewColumns: false,
              print: isPrintingEnabled,
              responsive: 'standard',
              download: false,
              search: false,
              textLabels: {
                body: {
                  noMatch: getMessage('noData'),
                },
              },
              selectToolbarPlacement: 'replace',
              onRowClick: (rowData, { dataIndex }) => {
                const newDialogData = [
                  {
                    ...dataForRender?.audits[dataIndex],
                    documentNumber: rowData[0],
                  },
                ];
                setDialogData(newDialogData);
                handleModalOpen(setOpen);
              },
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

OOSTrackingTable.defaultProps = {
  dataForRender: [],
  setDialogData: () => {},
  setOpen: () => {},
};

OOSTrackingTable.propTypes = {
  dataForRender: object,
  setDialogData: func,
  setOpen: func,
};

export default OOSTrackingTable;
