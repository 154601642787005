import React, {
  useState, useMemo, useContext,
} from 'react';
import { object } from 'prop-types';
import Card from '@mui/material/Card';
import { Dialog, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import BarcodeScannerButton from '../../barcodeScanner/BarcodeScannerButton';
import BarcodeScanner from '../../barcodeScanner/BarcodeScanner';
import Button from '../../Button';
// import { ReactComponent as Shoe } from '../../../assets/images/backdrop_shoe.svg';
import { onGoClick, onEnterPressed } from './itemInquiryWidget.utils';
import ItemProductDetails from './ItemProductDetails';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { CPAContext } from '../../../context/cpa/CPA.provider';
import ProductImageCarousel from '../../ProductImageCarousel';
import { useTutorial } from '../../tutorial/useTutorial';

import mockedStyleColorData from '../../../__test__/__mocks__/cpa/cpa-merged.json';

import './ItemInquiryWidget.css';

const styles = ({
  textField: {
    borderRadius: '15px',
    marginLeft: '8px',
    marginRight: '8px',
    minWidth: '200px',
    borderWidth: '.05px',
    '& .Mui-focused legend': {
      minWidth: '135px',
    },
    '& .MuiInputBase-input': {
      color: '#FFFFFF',
    },
    caretColor: '#FFFFFF',
  },
  cssOutlinedInput: {
    borderRadius: '8px',
  },
  notchedOutline: {
    borderWidth: '.05px',
    borderColor: '#FFFFFF !important',
  },
});

/**
  * Creates a widget with data visuals for the Item Inquiry
  * @param {object} classes additional obj of string to cusomize styles
*/
const ItemInquiryWidget = ({ classes }) => {
  const {
    isMobile, getMessage, storeConfig, activateTutorials,
  } = useContext(SimWebContext);
  const {
    data, error, loading, getProducts,
  } = useContext(CPAContext);
  const {
    stepIndex, startTutorial, dashboardWidget, tutorial,
  } = useTutorial('itemInquiry');
  const isBarcodeScannerEnabled = storeConfig?.isItemInquiryBarcodeScannerEnabled?.value;

  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const updateSearchParam = (val) => {
    if (val !== '') {
      setIsBarcodeOpen(false);
      setSearchValue(val);
      onGoClick(
        searchValue,
        getProducts,
      );
    }
  };

  const errorState = (
    <div className={classNames('itemInquiryWidget_card-stateLayout', 'itemInquiryWidget_card-error')} data-testid="errorContainer_emptyData">
      <ErrorOutline color="error" fontSize="large" />
      <br />
      <p>{getMessage('noResults')}</p>
    </div>
  );

  const loadingState = (
    <div className={classNames('itemInquiryWidget_card-stateLayout', 'itemInquiryWidget_card-loading')}>
      <LoadingIndicator height="80px" width="80px" fill="#FFFFFF" extraClass="itemInquiryWidget_card-loading" />
    </div>
  );

  const noData = (
    <div className={classNames('itemInquiryWidget_card-stateLayout', 'default-shoebox-state')} data-testid="itemInquiryWidget_card_noData">
      <p>{getMessage('searchStyleColorUpc')}</p>
      {/* <Shoe /> */}
    </div>
  );

  const itemProductDetails = useMemo(() => {
     
    const productData = stepIndex >= 2 && dashboardWidget === 'itemInquiry' ? mockedStyleColorData[0] : data;
    if (!startTutorial && stepIndex === 0 && dashboardWidget === 'itemInquiry' && searchValue === '') {
      setSearchValue(''); // clear search val after tutorial
    }

    if (error) {
      return errorState;
    }

    if (loading) {
      return loadingState;
    }

    if (!productData || productData.length === 0) {
      return noData;
    }

    return (
      <>
        <ItemProductDetails />
        <Link
          to={`/itemInquiry/${searchValue}`}
        >
          <Button
            data-testid="product-detail-to-report-button"
            id="inquiryGoToReportButton"
            buttonText={getMessage('viewInItemInquiry')}
            darkMode
          />
        </Link>
      </>
    );
  }, [data, loading, error, activateTutorials, stepIndex]);

  const styleColorSearchFragment = useMemo(() => (
    <div>
      <div className="itemInquiryWidget_data" data-testid="itemInquiryWidgetData">
        <TextField
          type="text"
          id="styleInput"
          data-testid="styleInput"
          label={getMessage('styleColorOrUpc')}
          className={classes.textField}
          variant="outlined"
          margin="normal"
          fullWidth
          onKeyPress={(e) => onEnterPressed(
            e,
            searchValue,
            getProducts,
          )}
          onChange={(e) => { setSearchValue(e.target.value); }}
          value={startTutorial ? 'DR1924-394' : searchValue}
          InputLabelProps={{
            sx: {
              // set the color of the label when not shrinked
              color: '#ffffff',
              [`&.${inputLabelClasses.shrink}`]: {
                // set the color of the label when shrinked (usually when the TextField is focused)
                color: '#ffffff',
              },
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssOutlinedInput,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
        <Button
          extraClasses="itemInquiryWidget_goButton"
          data-testid="inquiryGoButton"
          id="inquiryGoButton"
          buttonText={getMessage('go')}
          onClick={() => onGoClick(
            searchValue,
            getProducts,
            startTutorial,
          )}
          darkMode
        />
        <div />
        { isBarcodeScannerEnabled && (
          <BarcodeScannerButton
            stroke="white"
            setIsBarcodeOpen={setIsBarcodeOpen}
          />
        ) }
      </div>
    </div>
  ), [startTutorial, searchValue]);

  return (
    <>
      <Card className="itemInquiryWidget_dashboard-card" elevation={8} data-testid="itemInquiryWidget">
        <CardContent>
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            {activateTutorials && tutorial}
          </div>
          <p className="itemInquiryWidget_title">{getMessage('itemInquiry')}</p>
          <div className="itemInquiryWidget_dashboard-container">
            {styleColorSearchFragment}
            <div className="itemInquiryWidget_dashboard-card-details">
              {itemProductDetails}
            </div>
            {data && data.length > 0 && (
              <div className="image-carousel">
                <ProductImageCarousel
                  imageSet={data[0]?.content?.imageSet}
                  noImageLabel={getMessage('noImage')}
                />
              </div>
            )}
          </div>
        </CardContent>
        {isBarcodeScannerEnabled && (
          <Dialog
            data-testid="inquiryWidgetbarcodeScanner"
            open={isBarcodeOpen}
            onClose={() => {
              setIsBarcodeOpen(false);
              setSearchValue('');
            }}
            fullScreen={isMobile}
            maxWidth="lg"
          >
            <BarcodeScanner
              updateSearchParam={updateSearchParam}
              setIsBarcodeOpen={setIsBarcodeOpen}
            />
          </Dialog>
        )}
      </Card>
    </>
  );
};

ItemInquiryWidget.propTypes = {
  classes: object.isRequired,
};

export default (withStyles(styles)(ItemInquiryWidget));
