import React from 'react';
import { bool, func } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingIndicator } from '../../assets/Svgs';

const ChallanPrintToolTip = withStyles({
  tooltip: {
    backgroundColor: '#292929',
    color: '#DEDEDE',
    accentColor: '#292929',
    fontWeight: 500,
    fontSize: '12px',
    border: '1px solid black',
    padding: '10px',
  },
})(Tooltip);

/**
 * Custom datatable toolbar used on the Challan page,
 * allows the user to create a challan with selected rows in the table
 * @param {func} printChallan - the function to print challans with selected rows when clicked
 */
const ChallanPrintToolBar = ({
  challanLoading, printChallan,
}) => (
  <div className="challan-print-toolbar-container" data-testid="challan-print-toolbar-container">
    {!challanLoading && (
      <ChallanPrintToolTip className="scan-progress-tool-tip" data-testid="scan-progress-tooltip" title="Print Challan" placement="top">
        <IconButton
          className="challan-print-button"
          data-testid="challan-print-button"
          onClick={printChallan}
          size="large"
        >
          <PrintIcon />
        </IconButton>
      </ChallanPrintToolTip>
    )}
    {challanLoading && (
      <LoadingIndicator height="100px" width="100px" extraClass="challan-print-loader" />
    )}
  </div>
);

ChallanPrintToolBar.propTypes = {
  challanLoading: bool,
  printChallan: func.isRequired,
};

ChallanPrintToolBar.defaultProps = {
  challanLoading: false,
};

export default ChallanPrintToolBar;
