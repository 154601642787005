 
 
import React, { useContext } from 'react';
import { string, object } from 'prop-types';
import { camelCase } from 'lodash';
import getFormattedPrice from '../../utils/getFormattedPrice';

import { TranslationsContext } from '../../context/Translations.provider';

import './AdjustmentsPage.css';

export const adjustmentTypes = ['adjustIn', 'adjustOut'];

const SummaryTable = ({ data, locale, currencyCode }) => {
  const { getMessage } = useContext(TranslationsContext);

  const getParentColStyle = (data, adjustmentType) => {
    const { adjustIn, adjustOut } = { ...data };
    const inTypeSummaries = adjustIn?.typeSummaries;
    const outTypeSummaries = adjustOut?.typeSummaries;
    const style = outTypeSummaries?.length > inTypeSummaries?.length ? 'adjustOut-body' : '';
    return (adjustmentType === 'adjustOut') ? style : '';
  };

  const getChildColStyle = (data, adjustmentType) => {
    const { adjustIn, adjustOut } = { ...data };
    const inTypeSummaries = adjustIn?.typeSummaries;
    const outTypeSummaries = adjustOut?.typeSummaries;
    const style = inTypeSummaries?.length > outTypeSummaries?.length ? 'adjustIn-body' : '';
    return adjustmentType === 'adjustIn' ? style : '';
  };

  const getAdjustmentSummary = (data, adjustmentType) => {
    const { typeSummaries, totalPrice } = { ...data?.[adjustmentType] };
    const columnStyle = adjustmentType === 'adjustOut' ? `column ${adjustmentType}-price` : 'column';
    const styleColor = adjustmentType === 'adjustOut' ? { color: 'red' } : {};

    return (
      <div className={`column ${getParentColStyle(data, adjustmentType)}`}>
        <span className="adjust-type-title">{getMessage(adjustmentType)}</span>
        <div className="row">
          <div className={`column ${getChildColStyle(data, adjustmentType)}`}>
            <div className="row row-header">
              <span className="column">{getMessage('type')}</span>
              <span className="column">{getMessage('units')}</span>
              <span className="column">{getMessage('totalPrice')}</span>
            </div>
            {typeSummaries?.map(type => (
              <div className={`row ${adjustmentType}-row`}>
                <span className="column">{getMessage(camelCase(type.type)).toUpperCase()}</span>
                <span className="column">{type.totalUnits || 0}</span>
                <span className={columnStyle} style={styleColor}>
                  {`(${getFormattedPrice(type?.totalPrice, locale, currencyCode)})`}
                </span>
              </div>
            ))}
            {totalPrice > 0 && (
              <div className="row row-totals">
                <span className="column">{getMessage('totals').toUpperCase()}</span>
                <span className="column" />
                <span className={`column ${adjustmentType}-totals`}>
                  {`(${getFormattedPrice(totalPrice, locale, currencyCode)})`}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="summary-table-container">
      <span className="summary-table-title">{`${getMessage('adjustments')} ${getMessage('summary')}`}</span>
      <span style={{ display: 'block' }}>{`${getMessage('totalUnits')}: ${data?.totalUnits || 0}`}</span>
      <div className="summary-table-data-container">
        <div className="adjustment-summary-table">
          <div className="row">
            {adjustmentTypes.map(adjustmentType => getAdjustmentSummary(data, adjustmentType))}
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryTable.propTypes = {
  data: object,
  currencyCode: string,
  locale: string,
};

SummaryTable.defaultProps = {
  data: {},
  currencyCode: 'USD',
  locale: 'en-us',
};

export default SummaryTable;
