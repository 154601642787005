import React from 'react';
import { useParams } from 'react-router-dom';

import ItemInquiryPage from './ItemInquiryPage';
import CPAProvider from '../../context/cpa/CPA.provider';
import TutorialProvider from '../../components/tutorial/Tutorial.provider';

const ItemInquiry = () => {
  const { searchValue } = useParams();
  return (
    <TutorialProvider>
      <CPAProvider>
        <ItemInquiryPage itemInquirySearchValue={searchValue} />
      </CPAProvider>
    </TutorialProvider>
  );
};

export default ItemInquiry;
