import React, { Fragment, useContext } from 'react';
import { bool, string } from 'prop-types';
import { SimWebContext } from '../../../context/SimWeb.provider';
import DashboardWidgetContainer from '../../../components/dashboardWidgets/DashboardWidgetContainer';
import {
  ScanAcuracy, ScanTimer, SizeCompliance, StyleCompliance, ScanCompliance,
} from './index';
import TutorialProvider from '../../../components/tutorial/Tutorial.provider';

const RfidWidget = ({ isRfidDataEnabled, rfidFullStoreScanDate }) => {
  const { getMessage, activateTutorials } = useContext(SimWebContext);

  const complianceWidget = (
    <div className="rfid-flex-complianceWidgets">
      <StyleCompliance />
      <div style={{ paddingTop: activateTutorials ? '31px' : '' }}><SizeCompliance /></div>
    </div>
  );

  const widgets = [
    <ScanTimer />,
    <ScanAcuracy />,
    <ScanCompliance rfidFullStoreScanDate={rfidFullStoreScanDate} />,
    complianceWidget,
  ];

  return (isRfidDataEnabled
      && (
        <DashboardWidgetContainer title={getMessage('rfid')} testid="dashboard_rfidWidget">
          { widgets.map((widget, index) => <Fragment key={index}><TutorialProvider>{widget}</TutorialProvider></Fragment>) }
        </DashboardWidgetContainer>
      ));
};

RfidWidget.propTypes = {
  isRfidDataEnabled: bool,
  rfidFullStoreScanDate: string,
};

RfidWidget.defaultProps = {
  isRfidDataEnabled: false,
  rfidFullStoreScanDate: '',
};

export default RfidWidget;
