 
import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { createProductRefillSummaryData, sortByCreationDate } from './productRefill.utils';
import { getProductRefillTasks, getRefillCountsData } from './productRefill.axios';

export const ProductRefillContext = createContext({});

/**
  * An ProductRefill Provider that handles ProductRefills state and values.
  *
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
*/
const ProductRefillProvider = ({ children, mockedValue }) => {
  const { Provider } = ProductRefillContext;
  const { storeId, storeConfig, getMessage } = useContext(SimWebContext);

  const [tasks, setTasks] = useState([]);
  const [refillCount, setRefillCount] = useState();
  const [summary, setSummary] = useState({});
  const [refetchTasks, setRefetchTasks] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const hideUnbinnedStatus = storeConfig?.hideUnbinnedStatus?.value;
  const footwearRefill = storeConfig?.footwearRefill?.value;
  const taskService = storeConfig?.tasksVersion?.value;

  const headers = { appId: 'simweb' };

  useEffect(() => {
    const getTasks = async () => {
      setIsFetching(true);
      setError(null);
      let productRefillTasks = [];

      try {
        productRefillTasks = await getProductRefillTasks(storeId, headers, taskService, getMessage);
        setTasks(sortByCreationDate(productRefillTasks));
      } catch (e) {
        setError(e);
      }

      setIsFetching(false);
      setRefetchTasks(false);
    };

    if (storeId) {
      getTasks();
    }
  }, [storeId, refetchTasks]);

  /**
   * Gets Units in Queue count for the summary.  These units are items that are not included in a picklist.
   */
  useEffect(() => {
    const getRefillCounts = async () => {
      try {
        const count = await getRefillCountsData(storeId, footwearRefill, hideUnbinnedStatus);
        setRefillCount(count);
      } catch (e) {
        setError(e);
      }
    };
    if (!error && storeId && storeConfig) {
      getRefillCounts();
    }
  }, [storeId, storeConfig]);

  /**
   * Creates the summary object used in the header.
   */
  useEffect(() => {
    const summaryData = createProductRefillSummaryData(tasks, refillCount);
    setSummary(summaryData);
  }, [tasks, refillCount]);

  return (
    <Provider
      value={mockedValue ?? {
        tasks,
        summary,
        error,
        isFetching,
        setRefetchTasks,
      }}
    >
      {children}
    </Provider>
  );
};

ProductRefillProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

ProductRefillProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ProductRefillProvider;
