 
 
import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import {
  object, bool, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import DonutChart from '../../visualSummaries/donutChart/DonutChart';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { tutorialContext } from '../../tutorial/Tutorial.provider';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import { handleToolTip } from '../dashboardWidgetUtils/complianceWidget.utils';
import { buildGraphData } from './sizeComplianceWidget.utils';

import mockedSizeComplianceData from '../../tutorial/mockTutorialData/dashboard/size-compliance-data.json';

import './SizeComplianceWidget.css';
import { SimWebContext } from '../../../context/SimWeb.provider';

/**
  * Creates a widget with data visuals for the Size Compliance
  * @param {String} customClass additional string to cusomize styles
  * @param {Object} data calculated details to display in the widget
  * @param {Boolean} loading loading state
  * @param {String} nikeDoor tells us if a nike door is NSO/NFO
  * @param {String} storeType type of nike store ex) factory/inline/company
  * @param {Boolean} link whether the sizeComp report is enabled of not
  * @return {Node} returns a new component with the correct data
*/
const SizeComplianceWidget = ({
  customClass,
  data,
  loading,
  nikeDoor,
  storeType,
}) => {
  const { activateTutorials, getMessage } = useContext(SimWebContext);
  const { startTutorial } = useContext(tutorialContext);
  const [apparelData, setApparelData] = useState([]);
  const [footwearData, setFootwearData] = useState([]);
  const [sizeComplianceData, setComplianceData] = useState(null);

  useEffect(() => {
    const sizeComplianceData = activateTutorials ? mockedSizeComplianceData : data;
    setComplianceData(sizeComplianceData);

    const { apparelData, footwearData } = buildGraphData(sizeComplianceData, activateTutorials, getMessage, newRelicAction);
    setApparelData(apparelData);
    setFootwearData(footwearData);
  }, [activateTutorials, data, mockedSizeComplianceData]);

  const apparelGraph = useMemo(() => (
    <DonutChart
      chartType="compliance"
      data={!isEmpty(sizeComplianceData) ? apparelData : []}
      title={getMessage('apparel')}
      legendEnabled
      displayCenterText
      extraClass=""
      width="170px"
      customToolTip={handleToolTip}
      stopAnimation={startTutorial}
      isDashboardWidget
    />
  ), [apparelData, sizeComplianceData, getMessage, startTutorial]);

  const footwearGraph = useMemo(() => (
    <DonutChart
      chartType="compliance"
      data={!isEmpty(sizeComplianceData) ? footwearData : []}
      title={getMessage('footwear')}
      legendEnabled
      displayCenterText
      extraClass=""
      width="170px"
      customToolTip={handleToolTip}
      stopAnimation={startTutorial}
      isDashboardWidget
    />
  ), [footwearData, sizeComplianceData, getMessage, startTutorial]);

  const sizeComplianceDetails = useMemo(() => {
    const noData = (
      <div className="sizeComplianceWidget_no-data" data-testid="errorContainer_emptyData">
        <ErrorOutline color="error" fontSize="large" />
        <br />
        <p>{getMessage('noResultsSizeCompliance')}</p>
      </div>
    );

    if (!activateTutorials && loading) {
      return (<div className="sizeComplianceWidget_card-loading"><LoadingIndicator width="45px" height="45px" fill="#FFFFFF" /></div>);
    }

    if (!activateTutorials && (!data || data?.error)) {
      return (
        <div className="sizeComplianceWidget_card-error" data-testid="errorContainer">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    return (
      <div className="sizeComplianceWidget_card-details" data-testid="sizeComplianceWidget_data">
        <div className="">
          <div className="sizeComplianceWidget_card-body">
            <div data-testid="sizeComplianceWidget_data_visuals" className="sizeComplianceWidget_data_visuals">
              <div>
                {/* beacon is technically an inline door - they do not have footwear on the floor */}
                {(footwearData && nikeDoor && nikeDoor !== 'NIKE_OWNED_STORE' && storeType && storeType !== 'BEACON') || (footwearData && nikeDoor && nikeDoor === 'NIKE_OWNED_STORE' && storeType === 'FACTORY') ? footwearGraph : null}
              </div>
              <div>
                {apparelData && apparelGraph}
              </div>
            </div>
            {isEmpty(sizeComplianceData) && noData}
          </div>
        </div>
      </div>
    );
  }, [
    data,
    sizeComplianceData,
    loading,
    footwearData,
    apparelData,
    footwearGraph,
    apparelGraph,
    storeType,
    nikeDoor,
    getMessage,
  ]);

  const cardContent = (
    <div className="sizeComplianceWidget_body">
      <p className="sizeComplianceWidget_title">{getMessage('size')}</p>
      {sizeComplianceDetails}
    </div>
  );

  const cardWrapper = !activateTutorials
    ? (
      <Link to="/SizeCompliance" className="sizeComplianceWidget_dashboard-widget">
        {cardContent}
      </Link>
    ) : (
      <div className="sizeComplianceWidget_dashboard-widget">
        {cardContent}
      </div>
    );

  return (
    <div
      className={classNames('sizeComplianceWidget_dashboard', `${customClass}`, 'borderStyles')}
      data-testid="sizeComplianceWidget_dashboard"
    >
      {cardWrapper}
    </div>
  );
};

SizeComplianceWidget.propTypes = {
  customClass: string,
  data: object,
  loading: bool,
  nikeDoor: string,
  storeType: string,
};

SizeComplianceWidget.defaultProps = {
  customClass: '',
  data: {},
  loading: false,
  nikeDoor: null,
  storeType: null,
};

export default SizeComplianceWidget;
