import React, { useContext, useMemo } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '../../components/Button';

import { LoadingIndicator } from '../../assets/Svgs/index';

import { SimWebContext } from '../../context/SimWeb.provider';
import { StoreConfigContext } from '../../context/StoreConfig.provider';
import { AdminContext } from './Admin.provider';

import './AdminPage.css';

const AdminPage = () => {
  const { getMessage, storeInfo } = useContext(SimWebContext);
  const {
    configsArray, handleSubmit, loadingConfigs, setHandleSubmit, updateFieldChanged,
    updatedConfigs, displayStatusMessage,
  } = useContext(AdminContext);
  const { refetchStoreConfig, setRefetch, configStatusMessage } = useContext(StoreConfigContext);

  const statusMessage = useMemo(() => displayStatusMessage && (
    <div className="admin-config-status-message">
      <p>{configStatusMessage}</p>
    </div>
  ), [configStatusMessage, displayStatusMessage]);

  const formConfigs = useMemo(() => {
    if (loadingConfigs) {
      return <LoadingIndicator dataTestId="loadingIndicator-adminPage" fill="#FFFFFF" height="45px" width="45px" />;
    }

    return updatedConfigs?.map((config, index) => (
      <FormControlLabel
        value="top"
        control={(
          <Switch
            checked={config?.value}
            onChange={() => updateFieldChanged(config, index)}
            name={config?.label}
            color="primary"
          />
        )}
        label={config?.label}
        labelPlacement="start"
        key={config?.label}
      />
    ));
  }, [updatedConfigs, configsArray, loadingConfigs]);

  // eslint-disable-next-line react/prop-types
  const InfoItem = ({ label, value, createSpan = (value) => <span>{value}</span> }) => (
    <p>
      <span className="admin-label">
        {`${label}:`}
      </span>
      {createSpan(value)}
    </p>
  );

  const storeDetails = useMemo(() => (
    <div className="storeDetails-container">
      <h3>{getMessage('storeDetails')}</h3>
      <InfoItem label={getMessage('storeName')} value={storeInfo?.name} />
      <InfoItem label={getMessage('brand')} value={storeInfo?.brand} />
      <InfoItem label={getMessage('businessConcept')} value={storeInfo?.businessConcept} />
      <InfoItem label={getMessage('offerings')} createSpan={() => storeInfo?.offerings.map((offering, index) => <li key={index}>{offering.name}</li>)} />
    </div>
  ), [storeInfo]);

  const submitButton = useMemo(() => (
    <Button
      darkMode
      type="submit"
      variant="contained"
      onClick={() => {
        setHandleSubmit(!handleSubmit);
        setRefetch(!refetchStoreConfig);
      }}
      data-testid="admin-config-submission-button"
      buttonText={getMessage('save')}
    />
  ), [handleSubmit, refetchStoreConfig]);

  return (
    <div>
      <div className="title-block">
        <div className="feature-title">{getMessage('adminPage')}</div>
        <div className="admin-container" data-testid="admin-container">
          <FormControl component="fieldset" className="config-container">
            <h3>{getMessage('configs')}</h3>
            <FormGroup aria-label="position" column style={{ alignItems: 'baseline' }}>
              {formConfigs}
            </FormGroup>
            <br />
            {submitButton}
            {statusMessage}
          </FormControl>
          {storeDetails}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
