 
 
import { defaultNoValueSort } from './defaultNoValueSort';

export const defaultSort = (data, index, direction) => (
  data && data.sort((a, b) => {
    const sortValue = defaultNoValueSort(a, b, index, direction);
    if (sortValue !== undefined) return sortValue;

    return direction === 'desc' ? (a.data[index] < b.data[index] ? 1 : -1) : (b.data[index] > a.data[index] ? -1 : 1);
  })
);
