 
import { adjustmentSteps, adjustmentsArray } from './adjustments';
import { dashboardSteps, dashboardArray } from './dashboard';
import { inboundVisibilitySteps, inboundStepArray } from './inboundVisibility';
import { itemInquirySteps, itemInquiryStepArray } from './itemInquiry';
import { oosAuditSteps } from './oosAudit';
import { physicalInventoryUploadSteps } from './physicalInventoryUpload';
import { priceChangesSteps } from './priceChanges';
import { scanReportSteps } from './scanReport';
import { sizeComplianceSteps, sizeComplianceStepsArray, setSizeComplianceTutorialSteps } from './sizeCompliance';
import { stockRoomOrgSteps } from './stockRoomOrganization';
import { styleManagementSteps, styleManagementStepsArray, setStyleManagementTutorialSteps } from './styleManagement';
import { transfersStepsArray, transfersSteps } from './transfers';

 
export const stepMap = new Map([
  ['adjustments', { steps: adjustmentsArray, getFilteredSteps: adjustmentSteps }],
  ['dashboard', { steps: dashboardArray, getFilteredSteps: dashboardSteps }],
  ['inboundVisibility', { steps: inboundStepArray, getFilteredSteps: inboundVisibilitySteps }],
  ['itemInquiry', { steps: itemInquiryStepArray, getFilteredSteps: itemInquirySteps }],
  ['OOSAudits', { steps: oosAuditSteps, getFilteredSteps: () => oosAuditSteps }],
  ['physicalInventoryUpload', { steps: physicalInventoryUploadSteps, getFilteredSteps: () => physicalInventoryUploadSteps }],
  ['priceChanges', { steps: priceChangesSteps, getFilteredSteps: () => priceChangesSteps }],
  ['scanSummary', { steps: scanReportSteps, getFilteredSteps: () => scanReportSteps }],
  ['scan', { steps: scanReportSteps, getFilteredSteps: () => scanReportSteps }],
  ['sizeCompliance', { steps: sizeComplianceStepsArray, getFilteredSteps: sizeComplianceSteps, actions: setSizeComplianceTutorialSteps }],
  ['stockroomOrganization', { steps: stockRoomOrgSteps, getFilteredSteps: () => stockRoomOrgSteps }],
  ['styleManagement', { steps: styleManagementStepsArray, getFilteredSteps: styleManagementSteps, actions: setStyleManagementTutorialSteps }],
  ['transfers', { steps: transfersStepsArray, getFilteredSteps: transfersSteps }],
]);
