import pkg from '../package.json';

const buildMode = process.env.VITE_APP_BUILD_MODE || 'test';
export const version = `v${pkg.version}`;

const isLocalHost = () => (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '');

let badge = '';

if (isLocalHost()) {
  badge = 'Local';
}

if (buildMode === 'test' && !isLocalHost()) {
  badge = 'Dev';
}

export const navigationBadge = badge;
export const environment = buildMode === 'preview' ? 'prod' : buildMode;

const isProd = () => environment === 'prod';
const isTest = () => environment === 'test';
const isRunningTests = () => process.env?.VITE_APP_IS_RUNNING_TESTS === 'true';

const env = {
  isLocalHost,
  isProd,
  isTest,
  isRunningTests
};

export default env;
