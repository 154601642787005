 
import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { object } from 'prop-types';
import Card from '@mui/material/Card';
import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { SimWebContext } from '../../context/SimWeb.provider';

/**
 * A widget with data for the Physical Inventory Upload Summary details
 * @param {object} data - the summary data of the uploaded files
 */
const PhysicalInventoryUploadSummary = ({ data }) => {
  const { getMessage, locale } = useContext(SimWebContext);
  const renderDate = (date) => (date && new Date(date).toLocaleString(locale, DATE_TIME_SHORT_WITH_APPENDED_ZEROS)) || '-';

  return (
    <Card className="physical-inventory-upload-summary-box" data-testid="physical-inventory-upload-summary-box">
      <div data-testid="physical-inventory-upload-summary-data">
        <div className="pair">
          <span className="key"><Typography style={{ fontWeight: 600 }} variant="body1">{'Session-Id:'}</Typography></span>
          <span className="value" data-testid="physical-inventory-upload-summary-sessionId"><Typography style={{ fontWeight: 600 }} variant="body1">{data?.sessionId || '-'}</Typography></span>
        </div>
        <div className="pair">
          <span className="key"><Typography style={{ fontWeight: 600 }} variant="body1">{`${getMessage('date')}:`}</Typography></span>
          <span className="value" data-testid="physical-inventory-upload-summary-date"><Typography style={{ fontWeight: 600 }} variant="body1">{ renderDate(data?.creationDate)}</Typography></span>
        </div>
        <hr />
        <div className="pair">
          <span className="key">{`${getMessage('filesUploaded')}:`}</span>
          <span className="value" data-testid="physical-inventory-upload-summary-files">{data?.files || '-'}</span>
        </div>
        <div className="pair">
          <span className="key">{`${getMessage('areaNumbers')}:`}</span>
          <span className="value" data-testid="physical-inventory-upload-summary-locations">{data?.locations || '-'}</span>
        </div>
        <div className="pair">
          <span className="key">{`${getMessage('totalUnits')}:`}</span>
          <span className="value" data-testid="physical-inventory-upload-summary-quantity">{data?.quantity || '-'}</span>
        </div>
        <div className="pair">
          <span className="key">{`${getMessage('uniqueItemNumbers')}:`}</span>
          <span className="value" data-testid="physical-inventory-upload-summary-gtins">{data?.gtins || '-'}</span>
        </div>
      </div>
    </Card>
  );
};

PhysicalInventoryUploadSummary.propTypes = {
  data: object,
};

PhysicalInventoryUploadSummary.defaultProps = {
  data: null,
};

export default PhysicalInventoryUploadSummary;
