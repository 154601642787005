import React from 'react';
import { SortArrow } from '../../assets/Svgs/index';

const arrowSVGTop = <SortArrow height="20" width="20" position="top" />;
const arrowSVGBottom = <SortArrow height="20" width="20" position="bottom" />;

export const defaultPagination = {
  starting: 0,
  offset: 0,
  currentPageNumber: 1,
  currentCount: 10,
};

export const aggregatedItems = 'MISSING,SCANNED,ACCURACY,EXTRA,EXPECTED';
export const missingCountSort = { field: 'MISSING_COUNT', order: 'DESC' };
export const missingCountTableSort = {
  label: 'Missing Count', value: 'MISSING COUNT', color: '#00B8D9', isFixed: true, order: 'DESC', icon: arrowSVGBottom,
};

export const scanTypes = {
  FullStore: 'FULL_STORE_SCAN',
  SalesFloor: 'SALES_FLOOR_SCAN',
  Offsite: 'OFFSITE_SCAN',
  CycleCount: 'FILTERED_FULL_STORE_SCAN',
  StockRoom: 'STOCK_ROOM_SCAN',
  Display: 'DISPLAY_SCAN',
  isFullStore: (scanType) => scanType?.toUpperCase() === scanTypes.FullStore,
  isDisplay: (scanType) => scanType?.toUpperCase() === scanTypes.Display,
  isCycleCount: (scanType) => scanType?.toUpperCase() === scanTypes.CycleCount,
};

export const scanTypesLabel = {
  FULL_STORE_SCAN: 'fullstore',
  SALES_FLOOR_SCAN: 'salesFloor',
  OFFSITE_SCAN: 'offsite',
  FILTERED_FULL_STORE_SCAN: 'cycleCount',
  STOCK_ROOM_SCAN: 'stockRoom',
  DISPLAY_SCAN: 'display',
};

export const scanStatuses = {
  IN_REVIEW: { name: 'IN_REVIEW', label: ':inReview', color: '#F45119' }, // orange
  SUBMITTED: { name: 'SUBMITTED', label: ':submitted', color: 'var(--green-success)' }, // green
  TIMED_OUT: { name: 'TIMED_OUT', label: ':timedOut', color: '#f71c2d' }, // red
  CANCELLED: { name: 'CANCELLED', label: ':cancelled', color: '#6664FF' }, // blue
  SCANNING: { name: 'SCANNING', label: ':scanning', color: '#999a99' }, // grey
  isInReview: (status) => status?.toUpperCase() === scanStatuses.IN_REVIEW.name,
  isSubmitted: (status) => status?.toUpperCase() === scanStatuses.SUBMITTED.name,
  isTimedout: (status) => status?.toUpperCase() === scanStatuses.TIMED_OUT.name,
  isCancelled: (status) => status?.toUpperCase() === scanStatuses.CANCELLED.name,
  isScanning: (status) => status?.toUpperCase() === scanStatuses.SCANNING.name,
  getColor: (status) => scanStatuses[status]?.color ?? '#999a99',
  getLabel: (status) => scanStatuses[status]?.label ?? '',
  getTranslatedLabel: (status, getMessage) => (scanStatuses[status]?.label ? getMessage(scanStatuses[status]?.label) : '-'),
};

export const genderLabel = {
  MENS: 'Mens',
  WOMENS: 'Womens',
  KIDS: 'Kids',
  OTHER: 'Other',
  'ADULT UNISEX': 'Mens',
  'BOYS BIG KID': 'Kids',
  'BOYS INFANT/TODDLER': 'Kids',
  'BOYS LITTLE KID': 'Kids',
  'GIRLS BIG KID': 'Kids',
  'GIRLS INFANT/TODDLER': 'Kids',
  'GIRLS LITTLE KID': 'Kids',
  'UNISEX BIG KID': 'Kids',
  'UNISEX INFANT/TODDLER': 'Kids',
  'UNISEX LITTLE KID': 'Kids',
  displayScanAllList: () => [genderLabel.MENS, genderLabel.WOMENS, genderLabel.KIDS],
  fullList: () => [genderLabel.MENS, genderLabel.WOMENS, genderLabel.KIDS, genderLabel.OTHER],

};

export const category = {
  ActionSports: { name: 'Action Sports', active: true },
  AmericanFootball: { name: 'American Football', active: true },
  AthleticTraining: { name: 'Athletic Training', active: true },
  BaseballSoftball: { name: 'Baseball/Softball', active: true },
  Basketball: { name: 'Basketball', active: true },
  Causes: { name: 'Causes', active: true },
  ColeHaan: { name: 'Cole Haan', active: true },
  Collegiate: { name: 'Collegiate', active: true },
  Converse: { name: 'Converse', active: true },
  DigitalSport: { name: 'Digital Sport', active: true },
  FootballSoccer: { name: 'Football/Soccer', active: true },
  Golf: { name: 'Golf', active: true },
  Hurley: { name: 'Hurley', active: true },
  Jordan: { name: 'Jordan', active: true },
  Lacrosse: { name: 'Lacrosse', active: true },
  Livestrong: { name: 'Livestrong', active: true },
  MensTraining: { name: 'Mens Training', active: true },
  NikeSB: { name: 'Nike SB', active: true },
  Other: { name: 'Other', active: true },
  PerformanceSwim: { name: 'Performance Swim', active: true },
  Rugby: { name: 'Rugby', active: true },
  Running: { name: 'Running', active: true },
  Sportswear: { name: 'Sportswear', active: true },
  Tennis: { name: 'Tennis', active: true },
  Umbro: { name: 'Umbro', active: true },
  Volleyball: { name: 'Volleyball', active: true },
  Walking: { name: 'Walking', active: true },
  WomensTraining: { name: 'Womens Training', active: true },
  YoungAthletes: { name: 'Young Athletes', active: true },
  Bras: { name: 'Bras', active: false },
  NikeSportswear: { name: 'Nike Sportswear', active: false },
  JordanBrand: { name: 'Jordan Brand', active: false },
  NikeBasketball: { name: 'Nike Basketball', active: false },
  NikeGolf: { name: 'Nike Golf', active: false },
  BaseballOtherFieldSports: { name: 'Baseball Other Field Sports', active: false },
  AllOther: { name: 'All Other', active: false },
  list: () => [
    category.ActionSports,
    category.AmericanFootball,
    category.AthleticTraining,
    category.BaseballSoftball,
    category.Basketball,
    category.Causes,
    category.ColeHaan,
    category.Collegiate,
    category.Converse,
    category.DigitalSport,
    category.FootballSoccer,
    category.Golf,
    category.Hurley,
    category.Jordan,
    category.Lacrosse,
    category.Livestrong,
    category.MensTraining,
    category.NikeSB,
    category.Other,
    category.PerformanceSwim,
    category.Rugby,
    category.Running,
    category.Sportswear,
    category.Tennis,
    category.Umbro,
    category.Volleyball,
    category.Walking,
    category.WomensTraining,
    category.YoungAthletes,
    category.Bras,
    category.NikeSportswear,
    category.JordanBrand,
    category.NikeBasketball,
    category.NikeGolf,
    category.BaseballOtherFieldSports,
    category.AllOther,
  ],
  activeList: () => category.list().filter((cat) => cat.active).map((cat) => cat.name),
  depricatedLIst: () => category.list().filter((cat) => !cat.active).map((cat) => cat.name),
};

export const sortByOptions = [
  {
    label: 'Missing Count', value: 'MISSING COUNT', color: '#00B8D9', isFixed: true, order: 'DESC', icon: arrowSVGBottom,
  },
  {
    label: 'Missing Count', value: 'MISSING COUNT_ASC', color: '#00B8D9', isFixed: true, order: 'ASC', icon: arrowSVGTop,
  },
  {
    label: 'Extra Count', value: 'EXTRA COUNT', color: '#00B8D9', isFixed: true, order: 'DESC', icon: arrowSVGBottom,
  },
  {
    label: 'Extra Count', value: 'EXTRA COUNT_ASC', color: '#00B8D9', isFixed: true, order: 'ASC', icon: arrowSVGTop,
  },
];

export const scanAggregationColors = {
  MISSING: '#c05850',
  SCANNED: '#ffffff',
  ACCURACY: '#8aa58f',
  EXTRA: '#d0b487',
  EXPECTED: '#ffffff',
  SALESFLOOR: '#3f51b5',
  STOCKROOM: '#17a2b8',
  OFFSITE: '#c05850',
};
