import React from 'react';

 
export const Offsite = (
  <svg height="25" viewBox="0 0 64 64" width="25" xmlns="http://www.w3.org/2000/svg">
    <path d="m63.946 35.227c-.019-.066-.038-.129-.069-.189-.012-.022-.012-.047-.025-.068l-8-13c-.021-.033-.053-.052-.076-.082-.042-.054-.086-.104-.139-.148-.05-.042-.102-.075-.158-.106-.054-.03-.107-.056-.167-.076-.067-.022-.134-.033-.204-.041-.037-.004-.069-.021-.107-.021h-11v-9.98c0-1.655-1.347-3.002-3.002-3.002h-30.975c-1.579 0-2.862 1.229-2.979 2.778-.018.071-.045.142-.045.221v16.991c0 .002.001.003.001.005v11.979h-6.001c-.553 0-1 .447-1 1v13.001c0 .553.447 1 1 1h14.002 14.002c.553 0 1-.447 1-1v-6.001h14.076c.488 3.387 3.401 6 6.92 6s6.432-2.613 6.92-6h5.08c.553 0 1-.447 1-1v-6-5.995c0-.026-.013-.048-.015-.073-.005-.066-.02-.128-.039-.193zm-54.946-23.603c.005-.038.022-.071.022-.111 0-.553.449-1.002 1.002-1.002h30.975c.553 0 1.002.449 1.002 1.002v24.977h-18.998v-7.986c0-.553-.447-1-1-1h-13.003zm13.991 30.864v2.018h-2v-2.001h1.012c.029 0 .054-.015.082-.017zm-6.989-12.984v2.009h-2v-2.009zm-9.001 12.984h.918c.028.002.053.017.082.017h1v2.001h-2zm7.001 11.001h-12.002v-11.001h3.001v3.018c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-3.001h3.001zm-5.001-13.001v-10.984h3.001v3.009c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-3.009h3.001v10.984h-6.001zm19.003 6.942c-.001.021-.012.038-.012.059s.011.038.012.059v5.942h-12.002v-10.985h2.989v3.001c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-3.018h3.013zm22.996 5.058c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm11-11v5h-4.08c-.488-3.387-3.401-6-6.92-6s-6.432 2.613-6.92 6h-14.076v-5c0-.553-.447-1-1-1h-6.001v-1.998h18.998v.999c0 .553.447 1 1 1s1-.447 1-1v-15.996h10.44l7.559 12.283z" />
    <path d="m51 44.488c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z" />
    <path d="m53.848 24.881c-.041-.051-.081-.098-.13-.14-.054-.045-.109-.081-.17-.114-.05-.027-.099-.051-.154-.069-.072-.025-.145-.037-.221-.045-.033-.003-.063-.02-.097-.02h-3.065c-.004 0-.007-.002-.01-.002s-.006.002-.01.002h-3.99c-.553 0-1 .447-1 1v8c0 .553.447 1 1 1h11.999.001c.115 0 .229-.028.342-.069.032-.012.063-.025.093-.04.029-.015.061-.021.089-.039.033-.021.052-.053.081-.076.055-.042.104-.086.149-.14.042-.049.074-.1.104-.155.031-.055.058-.109.078-.171.021-.066.033-.131.041-.2.004-.039.022-.071.022-.11 0-.025-.013-.047-.015-.071-.005-.067-.021-.131-.039-.196-.019-.066-.038-.129-.069-.188-.012-.021-.012-.047-.025-.068l-4.924-8.002c-.022-.036-.056-.056-.08-.087zm-4.847 7.612h-2v-6h2zm2 0v-6h1.518l3.692 6z" />
    <path d="m47.001 35.49h-1c-.553 0-1 .447-1 1s.447 1 1 1h1c.553 0 1-.447 1-1s-.447-1-1-1z" />
  </svg>
);
