 
import React, {
  useContext, useEffect, useMemo,
} from 'react';
import {
  bool, func, number, object, array,
} from 'prop-types';
import Card from '@mui/material/Card';
import { useDropzone } from 'react-dropzone';
import Cancel from '../../assets/Svgs/Cancel';
import { formatBytes } from './physicalInventoryUpload.utils';
import { TranslationsContext } from '../../context/Translations.provider';
import PIUploadStatus from './PIUploadStatus';
import { PhysicalInventoryUploadContext, SESSION_KEY } from './PhysicalInventoryUpload.provider';

const DragDropFileUploader = ({
  fileTypes, numberOfUploads, buttons, onFileUpload, isDisabled, onCancelClick, tutorial,
}) => {
  const baseStyle = {
    height: '150px',
    width: '100%',
    borderColor: '#999a99',
    color: '#999a99',
    backgroundColor: '#fafafa',
  };

  const disabledStyle = {
    height: '150px',
    width: '100%',
    borderColor: '#434244',
    color: '#434244',
    backgroundColor: '#fafafa',
  };

  const activeStyle = { ...baseStyle };
  const tutorialCurrentFiles = JSON.parse(sessionStorage.getItem('piUploadFilesTutorial')) ?? [];

  const { getMessage, isReady } = useContext(TranslationsContext);
  const {
    uploadStatus, currentFiles, setCurrentFiles, setAlertMessage,
  } = useContext(PhysicalInventoryUploadContext);

  const {
    getRootProps, getInputProps, isDragActive, open, acceptedFiles,
  } = useDropzone({
    accept: fileTypes,
    noClick: 'true',
    onKeyBoard: 'true',
    maxFiles: numberOfUploads,
    disabled: isDisabled,
  });

  const style = useMemo(() => {
    if (isDisabled) { return disabledStyle; }
    if (isDragActive) { return activeStyle; }
    return baseStyle;
  }, [isDragActive, isDisabled]);

  useEffect(() => {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(currentFiles));
  }, [currentFiles, SESSION_KEY]);

  useEffect(() => {
    if (!uploadStatus) return;

    if (PIUploadStatus.isSessionCanceled(uploadStatus)
        || PIUploadStatus.isFileCalculated(uploadStatus)
        || PIUploadStatus.isInProgress(uploadStatus)
        || PIUploadStatus.isDuplicateFile(uploadStatus)) {
      setCurrentFiles([]);
      return;
    }

    let files = [...currentFiles];
    if (files.length === 0) return;

    if (PIUploadStatus.isFileUploadInProgress(uploadStatus) || PIUploadStatus.isFileUploadComplete(uploadStatus) || PIUploadStatus.isFileUploadFailed(uploadStatus)) {
      files.reverse();
      files[0].uploadStatus = uploadStatus;
      files.reverse();
    }

    files = files.filter(file => !PIUploadStatus.isFileUploadFailed(file.uploadStatus));
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(files));
    setCurrentFiles(files);
  }, [uploadStatus]);

  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      const files = [...currentFiles];
      const file = acceptedFiles[0];
      const fileNameSplit = file.name.split('.');
      const fileName = fileNameSplit[0].concat('.', fileNameSplit[1].toLowerCase());

      const isDuplicate = currentFiles.filter(candidate => candidate.name === file.name).length > 0;

      if (isDuplicate) {
        setAlertMessage(PIUploadStatus.DUPLICATE_FILE_UPLOADING);
      } else {
        files.push({
          name: fileName, size: file.size, type: file.type, lastModifiedDate: file.lastModifiedDate, uploadStatus,
        });
        setCurrentFiles(files);
        onFileUpload(acceptedFiles);
      }
    }
  }, [acceptedFiles]);

  const preview = useMemo(() => {
    const getPreview = (index, name, size, isUploaded, uploadStatus) => {
      const statusInfo = getMessage(PIUploadStatus.getUploadStatusKeyString(uploadStatus));
      const title = `${name || '?'}, ${formatBytes(size)} (${statusInfo})`;
       
      const progressValue = isUploaded || uploadStatus === PIUploadStatus.FILE_UPLOAD_COMPLETED.name ? 100 : (uploadStatus === PIUploadStatus.FILE_UPLOAD_IN_PROGRESS.name ? 25 : 0);

      return (
        <div key={`previewStatusContainer-${index}`} className="previewContainer" data-testid={`previewStatusContainer-${index}`}>
          <span className="previewFileName" style={{ margin: '10px 3%', fontFamily: 'Helvetica', fontSize: '18px' }}>
            {title}
          </span>
          <progress max="100" value={progressValue} />
          <Cancel className="cancel" height="15px" width="15px" onClick={onCancelClick} />
        </div>
      );
    };
    const files = tutorial ? tutorialCurrentFiles : currentFiles;

    return files?.map((file, index) => getPreview(index, file.name, file.size, file.isUploaded, file.uploadStatus));
  }, [uploadStatus, currentFiles, onCancelClick, tutorial]);

  return isReady ? (
    <Card className="uploader" data-testid="dragDropFileUploader">
      <div style={{ minHeight: '50px' }}>
        {preview}
      </div>
      <div data-testid="dragDropFileUploader-dropzone" {...getRootProps({ style })} onClick={open}>
        <input data-testid="dropzone" {...getInputProps()} />
        <div className="uploadMessage" style={{ color: isDisabled ? 'grey' : 'black' }}>{getMessage('fileDragDrop')}</div>
      </div>
      {buttons}
    </Card>
  ) : null;
};

DragDropFileUploader.propTypes = {
  buttons: object,
  numberOfUploads: number,
  fileTypes: array,
  onFileUpload: func.isRequired,
  onCancelClick: func.isRequired,
  isDisabled: bool,
  tutorial: bool,
};

DragDropFileUploader.defaultProps = {
  buttons: null,
  numberOfUploads: 1,
  fileTypes: '',
  isDisabled: false,
  tutorial: false,
};

export default DragDropFileUploader;
