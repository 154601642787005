import React, {
  useMemo, useContext, useCallback, useEffect,
} from 'react';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import supportedLanguages from '../../../utils/supportedTranslations';

import { TranslationsContext } from '../../../context/Translations.provider';

import './LanguageDropdown.css';

const LanguageDropdown = () => {
  const { getMessage, setLanguage, language } = useContext(TranslationsContext);

  const options = supportedLanguages?.map((lang) => {
    const option = {
      label: lang.name, value: lang.tag, color: '#00B8D9', isFixed: true,
    };
    return option;
  });

  const getLanguage = (candidate) => {
    const result = options?.filter(lang => lang?.value === candidate);
    return result?.length ? result : getLanguage('en');
  };

  let defaultLanguage = getLanguage(language);

  useEffect(() => {
    if (language) defaultLanguage = getLanguage(language);
  }, [language, defaultLanguage]);

  const handleLanguageChange = useCallback((event) => {
    setLanguage(event.value);
    sessionStorage.setItem('language', event.value);
    newRelicAction('sim-language-setting', { action: event.value });
  }, [setLanguage]);

  const dropdownLanguageSelector = useMemo(() => defaultLanguage?.length && (
    <CustomSelect
      label={getMessage('language')}
      options={options}
      onChange={e => handleLanguageChange(e)}
      defaultValue={defaultLanguage}
      customClass="flex-row"
      customLabelClass="profile-label"
    />
  ), [defaultLanguage, options]);

  return (
    <div className="language-dropdown-container" data-testid="language-dropdown-container">
      <div className="language-dropdown" data-testid="language-dropdown">
        {dropdownLanguageSelector}
      </div>
    </div>
  );
};

export default LanguageDropdown;
