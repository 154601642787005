 
import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';

import { fetchMissingScanReportSessions as fetchMissingStyles, fetchMissingStylesDetails } from './missingStyles.axios';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';

export const MissingStylesContext = createContext({});

/**
  * An MissingStyles Provider that handles MissingStyless within the application and displaying them on
  * the appropriate page with the accurate messaging
  *
  * @param {node} children - data that we iterate on and create data visuals
*/
const MissingStylesProvider = ({ children, mockedValue }) => {
  const { Provider } = MissingStylesContext;

  const {
    storeId, locale, SimDateTime, storeInfo,
  } = useContext(SimWebContext);

  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());
  const [error, setError] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);

  // modal variables
  const [sessionDetails, setSessionDetails] = useState([]);
  const [jobId, setJobId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [fetchingModalData, setFetchingModalData] = useState(false);
  const [modalError, setModalError] = useState(null);

  // modal index management
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [missingStyleDate, setMissingStyleDate] = useState(null);
  const [newSort, setNewSort] = useState(false);

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  const onGoClick = (options) => {
    const { startDate, endDate } = options;
    setStartDate(SimDateTime.toUtcISO(startDate));
    setEndDate(SimDateTime.toUtcISO(endDate));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    if (rows?.[index]) {
      const { sessionId, date } = rows[index];
      setMissingStyleDate(date);
      setSessionId(sessionId);
    }
  }, [index]);

  useEffect(() => {
    setFetchingData(true);

    const fetchMissingStylesData = async () => {
      setRows([]);
      setError(null);
      try {
        const response = await fetchMissingStyles(storeId, startDate, endDate, storeInfo?.timezone, locale);

        setRows(response?.sessions);
        setRowCount(response?.sessions?.length);
        setJobId(response?.jobId);
      } catch (error) {
        setError(error);
      }
      setFetchingData(false);
    };

    if (storeId) {
      fetchMissingStylesData();
    }
  }, [storeId, startDate, endDate, storeInfo?.timezone, locale]);

  /**
   * Retrieve the details for the modal
   */
  useEffect(() => {
    const fetchMissingStylesDialogData = async () => {
      setModalError(null);
      setFetchingModalData(true);

      try {
        const details = await fetchMissingStylesDetails(jobId, sessionId);

        setSessionDetails(details);
      } catch (error) {
        setModalError(error);
      }
      setFetchingModalData(false);
    };

    if (jobId && sessionId) {
      fetchMissingStylesDialogData();
    }
  }, [jobId, sessionId]);

  return (
    <Provider
      value={mockedValue ?? {
        rows,
        fetchingData,
        sessionDetails,
        fetchingModalData,
        modalError,
        startDate,
        endDate,
        error,
        index,
        isDialogOpen,
        missingStyleDate,
        rowCount,
        newSort,
        setDialogOpen,
        handleDialogOpen,
        isOutsideRange,
        setNewSort,
        setMissingStyleDate,
        setJobId,
        setIndex,
        setSessionId,
        setRows,
        setStartDate,
        setEndDate,
        onGoClick,
      }}
    >
      {children}
    </Provider>
  );
};

MissingStylesProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

MissingStylesProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default MissingStylesProvider;
