import { camelCase } from 'lodash';
/**
 * Builds the table headers that are needed for download.
 * @param {array} columns columns for download
 * @param {boolean} isOffsiteEnabled flag to check if offsite is enabled
 * @returns an ordered array of headers for download
 */
export const buildDownloadHeaders = (columns, isOffsiteEnabled) => {
  const columnsOrder = [
    'Bin', 'Product Description', 'Style Color', 'Division', 'Gender', 'Category',
    'Size', 'GTIN', 'Price', 'Total SOH', 'FoH SOH', 'BoH SOH',
  ];

  if (isOffsiteEnabled) columnsOrder.push('Offsite SOH');

  const headers = [];
  columnsOrder.map(item => {
     
    columns.find(_ => {
      if (_.name.toLowerCase() === camelCase(item).toLowerCase()) {
        headers.push({
          value: item,
          fontWeight: 'bold',
        });
      }
    });
  });
  return headers;
};

/**
 * Rearrange the data to match the headers order
 * @param {array} data table data for download
 * @param {object} dataKey data index mapping
 * @param {boolean} isOffsiteEnabled flag to check if offsite is enabled
 */
export const buildDownloadData = (data, dataKey, isOffsiteEnabled) => {
  const temp = [];

  data?.forEach(item => {
    const rowData = item?.data;
    const row = {
      data: [
        rowData[dataKey.bin],
        rowData[dataKey.productDescription],
        rowData[dataKey.styleColor],
        rowData[dataKey.division],
        rowData[dataKey.gender],
        rowData[dataKey.category],
        rowData[dataKey.size],
        rowData[dataKey.gtin],
        rowData[dataKey.price],
        rowData[dataKey.totalSOH] || '-',
        rowData[dataKey.fohSOH] || '-',
        rowData[dataKey.bohSOH] || '-',
      ],
    };

    if (isOffsiteEnabled) {
      row.data.push(rowData[dataKey.offsiteSOH] || '-');
    }

    temp.push(row);
  });
  const toDownload = temp.map(row => row.data.map(value => ({ value: value?.toString() ?? '', type: String })));
  return toDownload;
};
