 
import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import {
  array, func, string,
} from 'prop-types';
import { FormControl, TextField } from '@mui/material';
import Button from '../../../components/Button';
import { SimWebContext } from '../../../context/SimWeb.provider';
import CustomSelect from '../../../components/customSelect/CustomSelect';

export const onChange = (event, setter, setStoreNumber, setCountry) => {
  // if store details are are added to url
  if (window.location.search) {
    const newStore = window.location.search.split('=').pop().split('-');
    setCountry(newStore?.[0]);
    setStoreNumber(newStore?.[1]);
  }
  setter(event?.target?.value);
};

/**
 * Form for the login page, has 4 fields
 * employeeId
 * password
 * country
 * storeNumber
 * @param {array} countries - array of all countries with a nike sim door
 * @param {string} defaultCountry - what to initially set the country input to
 * @param {string} defaultStoreNumber - what to initially set the store number input to
 * @param {func} fetchStoreNumbers - function to get the new list of store numbers in a country,
 * called when a new country is selected
 * @param {func} submit - function to call when form is submitted
 * @param {array} storeNumbers - array of all store numbers in the selected country
 */
const Form = ({
  countries,
  defaultCountry,
  defaultStoreNumber,
  submit,
  storeNumbers,
}) => {
  const {
    storeNumber, setStoreNumber, country, setCountry, isStoreInfoLoaded, fetchTranslations, isReady, getMessage,
  } = useContext(SimWebContext);

  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputCountry, setInputCountry] = useState('');
  const [inputStoreNum, setInputStoreNum] = useState('');

  useEffect(() => {
    if (defaultCountry) setInputCountry(defaultCountry);
    if (defaultStoreNumber) setInputStoreNum(defaultStoreNumber);
  }, [defaultCountry, defaultStoreNumber]);

  const handleChange = setter => (event) => {
    onChange(event, setter, setStoreNumber, setCountry);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submit({
      employeeId,
      password,
      country,
      storeNumber,
    });
    setFormSubmitted(true);
  };

  useEffect(() => {
    if (inputStoreNum === '' || isStoreInfoLoaded) return;
    fetchTranslations();
    setStoreNumber(inputStoreNum);
  }, [inputStoreNum, isStoreInfoLoaded]);

  useEffect(() => {
    if (inputCountry) {
      setCountry(inputCountry);
    }
  }, [inputCountry]);

  const countriesSelect = useMemo(() => {
    if (!countries?.length > 0) return null;

    return (
      <CustomSelect
        label={`${getMessage('country')}`}
        options={countries}
        defaultValue={countries.filter(option => option.value === country)}
        customLabelClass="select-label"
        customSelectClass="country-select"
        onChange={(event) => {
          setInputStoreNum(null);
          setInputCountry(event.value.toUpperCase());
        }}
      />
    );
  }, [countries, getMessage]);

  const storeNumberSelect = useMemo(() => {
    if (!storeNumbers?.length > 0) return null;
    const defaultValue = !inputStoreNum ? null : storeNumbers.filter(option => option.value === inputStoreNum);

    return (
      <CustomSelect
        label={`${getMessage('storeNumber')}`}
        options={storeNumbers}
        defaultValue={defaultValue}
        clearDefault={!inputStoreNum}
        customLabelClass="select-label"
        customSelectClass="store-number-select"
        onChange={(event) => {
          setInputStoreNum(event.value);
        }}
      />
    );
  }, [storeNumbers, inputStoreNum, getMessage]);

  const userAndPassword = useMemo(() => (
    <div style={{ display: 'flex', flexDirection: 'inherit' }}>
      <TextField
        type="text"
        name="employeeId"
        id="retail-login-form-employeeId"
        data-testid="retail-login-form-employeeId"
        label={getMessage('employeeId')}
        variant="outlined"
        required
        onChange={handleChange(setEmployeeId)}
        value={employeeId}
        sx={{ marginBottom: '40px' }}
        InputLabelProps={{
          shrink: !!employeeId,
        }}
      />
      <TextField
        type="password"
        name="password"
        id="retail-login-form-password"
        data-testid="reatil-login-form-password"
        label={getMessage('password')}
        variant="outlined"
        required
        onChange={handleChange(setPassword)}
        value={password}
        sx={{ marginBottom: '20px' }}
        InputLabelProps={{
          shrink: !!password,
        }}
      />
    </div>
  ), [password, employeeId]);

  return isReady ? (
    <form
      onSubmit={handleSubmit}
      data-testid="retail-login-form"
    >
      <FormControl>
        {userAndPassword}
      </FormControl>
      <br />
      <div className="dropdown-container">
        <FormControl>
          {countriesSelect}
        </FormControl>
        <FormControl>
          {storeNumberSelect}
        </FormControl>
      </div>
      <br />
      <Button
        data-testid="retail-login-form-submit"
        type="submit"
        buttonText={getMessage('signInLabel')}
        onClick={handleSubmit}
        isSelected={formSubmitted}
        extraClasses="login-button"
      />
    </form>
  ) : null;
};

Form.defaultProps = {
  defaultCountry: '',
  defaultStoreNumber: '',
};

Form.propTypes = {
  countries: array.isRequired,
  defaultCountry: string,
  defaultStoreNumber: string,
  submit: func.isRequired,
  storeNumbers: array.isRequired,
};

export default Form;
