import React, { useContext, useMemo } from 'react';
import LinkWithDisable from '../../LinkWithDisable';
import SummaryWidget from '../../visualSummaries/summaryWidget/SummaryWidget';
import { buildMarkdownsSummaryObject } from '../../../containers/priceChanges/priceChanges.utils';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { PriceChangesContext } from '../../../containers/priceChanges/PriceChanges.provider';
import { useTutorial } from '../../tutorial/useTutorial';

import mockedMarkdownsSummaryData from '../../tutorial/mockTutorialData/dashboard/markdowns-data.json';

import './MarkdownsWidget.css';

const MarkdownsWidget = () => {
  const { summaryData, currentEffectiveDate, loading } = useContext(PriceChangesContext);
  const { locale, getMessage, activateTutorials } = useContext(SimWebContext);
  const { tutorial } = useTutorial('markdowns');

  const markdownsSummaryData = activateTutorials ? mockedMarkdownsSummaryData : summaryData;
  const effectiveDate = activateTutorials ? '01/01/2023' : currentEffectiveDate;
  const summaryObject = buildMarkdownsSummaryObject(markdownsSummaryData, effectiveDate, locale, getMessage);

  const summaryWidget = useMemo(() => {
    const { objects, title, subMessage } = summaryObject;
    return (
      <LinkWithDisable disabled={activateTutorials} to="/priceChanges" style={{ textDecoration: 'none', color: 'inherit' }} className="markdowns-widget-dashboard-card">
        <div
          className={`markdown--summary-widget markdowns-widget-totals-table ${activateTutorials ? 'tutorial' : ''}`}
          data-testid={`markdowns-widget-totals-table${activateTutorials ? '-tutorial' : ''}`}
        >
          <div style={{ color: 'white', paddingLeft: '10px' }}>
            {activateTutorials && tutorial}
          </div>
          <div
            className="markdowns-widget-total-container"
            data-testid="markdowns-widget-total-container"
          >
            <SummaryWidget
              loading={activateTutorials ? false : loading}
              data={objects}
              locale={locale}
              title={title}
              subMessage={subMessage}
            />
          </div>
        </div>
      </LinkWithDisable>
    );
  },

  [loading, summaryObject, activateTutorials]);

  return (
    <>
      {summaryWidget}
    </>
  );
};

export default MarkdownsWidget;
