 
import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { generalAxiosRequest } from '../axios/axiosFunctions';
import { baseUrl, routerUrls, endpoints } from '../axios/endpoints';
import { SimWebContext } from './SimWeb.provider';
import { countDownTimer } from '../utils/timer';

export const ScanTimerContext = createContext({});

export const scanTypes = {
  FULL_STORE_SCAN: 'fullstore',
  FILTERED_FULL_STORE_SCAN: 'cycleCount',
};

export const addLeadingZeros = (time) => {
  if (time === 0 || time < 10) return (String(time).padStart(2, '0'));
  return time;
};

const HOUR_IN_MILLISECONDS = 1000 * 60 * 60;

const ScanTimerProvider = ({ children, mockedValue }) => {
  const { Provider } = ScanTimerContext;
  const { storeId, region } = useContext(SimWebContext);

  const [inReviewScanId, setInReviewScanId] = useState(null);
  const [scanData, setScanData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scanStatus, setScanStatus] = useState(null);
  const [scanType, setScanType] = useState(null);
  const [scanStartDate, setScanStartDate] = useState(null);
  const [scanTimedOut, setScanTimedOut] = useState(false);
  const [scanTimer, setScanTimer] = useState(null);
  const [hideScanTimer, setHideScanTimer] = useState(false);
  const [sendNotification, setSendNotification] = useState(null);
  const [scanProgressIntervalId, setScanProgressIntervalId] = useState(null);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [scanProgress, setScanProgress] = useState(0);
  const [showScan, setShowScan] = useState(false);
  const [lastScanProgressPercentage, setLastScanProgressPercentage] = useState(0);
  const [scanProgressCheckIntervalId, setScanProgressCheckIntervalId] = useState(null);
  const [isScanNotProccessing, setIsScanNotProccessing] = useState(false);
  const currentDate = new Date(Date.now()).getTime();

  const getScanData = async () => {
    setScanData(null);
    setLoading(true);

    try {
      const variables = {
        storeId,
        aggregateBy: 'SCANNED,EXPECTED,MISSING,EXTRA,ACCURACY',
        filters: [],
      };
      const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.SCAN_TIMER.url}`;
      const data = await generalAxiosRequest('POST', url, endpoints.SCAN_TIMER, true, null, { region, variables });
      if (data) setScanData(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getIsScanTimedOut = (startDate, timeOutHours) => {
    const timeOutTime = new Date(startDate).getTime() + (timeOutHours * HOUR_IN_MILLISECONDS);
    return currentDate > timeOutTime;
  };

  const validateScanStatusDuration = (scanDate) => {
    const timeOutTime = new Date(scanDate).getTime() + (6 * HOUR_IN_MILLISECONDS);
    const hasHourPassedSinceChange = currentDate > timeOutTime;
    setHideScanTimer(hasHourPassedSinceChange);
  };

  useEffect(() => {
    if (storeId && region) getScanData();
  }, [storeId, region]);

  useEffect(() => {
    if (scanData) {
      const {
        id, scanType, status, date, scanProgressPercentage,
      } = scanData;

      if (scanTypes[scanType]) {
        validateScanStatusDuration(date);
        const isScanTimedOut = getIsScanTimedOut(date, 5);
        if (!isScanTimedOut && id && status === 'IN_REVIEW') {
          setScanProgress(scanProgressPercentage);

          if (!scanProgressCheckIntervalId && scanProgressPercentage < 100 && scanProgressPercentage === lastScanProgressPercentage) {
            // if scan events are not processing for 5 minutes, then notify the manager approval component to enable the resume button
            const progressCheckIntervalId = setInterval(() => {
              setIsScanNotProccessing(true);
              clearInterval(progressCheckIntervalId);
            }, 300 * 1000);
            setScanProgressCheckIntervalId(progressCheckIntervalId);
          } else if (scanProgressPercentage >= 100 || scanProgressPercentage !== lastScanProgressPercentage) {
            setIsScanNotProccessing(false);
            clearInterval(scanProgressCheckIntervalId);
            setScanProgressCheckIntervalId(null);
            setLastScanProgressPercentage(scanProgressPercentage);
          }

          if (!scanProgressIntervalId && scanProgressPercentage < 100) {
            const scanIntervalId = setInterval(() => { getScanData(); }, 10 * 1000); // re-call every 10 sec to get latest progress
            setScanProgressIntervalId(scanIntervalId);
          } else if (scanProgressPercentage >= 100) {
            clearInterval(scanProgressIntervalId);
            setScanProgressIntervalId(null);
            setSendNotification('NEW_SCAN');
          }
        }

        setScanType(scanType);
        setScanStatus(status);
        setScanStartDate(date);
        setInReviewScanId(id);
      } else {
        setHideScanTimer(true);
      }
    }
  }, [scanData]);

  useEffect(() => {
    if (!timerIntervalId && scanStartDate && !hideScanTimer) {
      const milliSecondsRemaining = new Date(scanStartDate).getTime();
      const intervalId = setInterval(() => countDownTimer(
        milliSecondsRemaining, (5 * HOUR_IN_MILLISECONDS), sendNotification, setScanTimer, setScanTimedOut, setSendNotification,
      ), 1000);
      setTimerIntervalId(intervalId);
    }

    if (scanTimedOut && scanStatus !== 'IN_REVIEW') clearInterval(timerIntervalId);
  }, [scanStartDate, hideScanTimer, scanStatus]);

  useEffect(() => () => {
    clearInterval(timerIntervalId);
  }, [timerIntervalId]);

  useEffect(() => () => {
    clearInterval(scanProgressIntervalId);
  }, [scanProgressIntervalId]);

  return (
    <Provider
      value={mockedValue ?? {
        inReviewScanId,
        isScanNotProccessing,
        hideScanTimer,
        loading,
        scanProgress,
        scanStatus,
        scanType,
        scanStartDate,
        scanTimedOut,
        scanTimer,
        sendNotification,
        showScan,
        addLeadingZeros,
        setShowScan,
      }}
    >
      {children}
    </Provider>
  );
};

ScanTimerProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

ScanTimerProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ScanTimerProvider;
