 
 
import React, { useContext } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { func, bool, string } from 'prop-types';
import {
  Backdrop,
  Button,
  Checkbox,
  TextField,
} from '@mui/material';
import { TranslationsContext } from '../../../context/Translations.provider';
import { AdjustmentsContext } from '../Adjustments.provider';

import './AddressModal.css';

const AddressModal = ({
  onClose,
  open = false,
  adjustmentId,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const {
    defaultSelected, address1, address2, city, postalCode,
    handleFiscalPrint, setGreekAddress, setAddress1, setAddress2, setCity, setPostalCode,
  } = useContext(AdjustmentsContext);

  /* istanbul ignore next */
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  /* istanbul ignore next */
  const handleCheckbox = (event) => {
    setGreekAddress(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleFiscalPrint(adjustmentId);
  };

  return (
    <div className="address-modal-container" data-testid="address-modal">
      <Modal
        aria-labelledby="address-modal-label"
        aria-describedby="address-modal-description"
        open={open}
        onClose={onClose}
        className="address-modal"
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className="address-modal-body" data-testid="address-modal-content">
            <form className="flex-column address-modal-form" onSubmit={handleSubmit}>
              <div className="flex-row address-modal-form-inputs">
                <label id="address-modal-label">
                  {getMessage('address:')}
                </label>
                <div className="flex-column address-modal-textField-wrapper" data-testid="address-form-textFields">
                  <TextField
                    type="text"
                    fullWidth
                    value={address1}
                    data-testid="address-modal-address-1"
                    placeholder={getMessage('addreessLine1')}
                    name="address1"
                    disabled={defaultSelected}
                    onChange={handleChange(setAddress1)}
                  />
                  <TextField
                    type="text"
                    fullWidth
                    value={address2}
                    data-testid="address-modal-address-2"
                    placeholder={getMessage('addreessLine2')}
                    name="address2"
                    disabled={defaultSelected}
                    onChange={handleChange(setAddress2)}
                  />
                  <TextField
                    type="text"
                    fullWidth
                    value={city}
                    data-testid="address-modal-city"
                    placeholder={getMessage('city')}
                    name="city"
                    disabled={defaultSelected}
                    onChange={handleChange(setCity)}
                  />
                  <TextField
                    type="text"
                    fullWidth
                    value={postalCode}
                    data-testid="address-modal-postal-code"
                    placeholder={getMessage('postalCode')}
                    name="postalCode"
                    disabled={defaultSelected}
                    onChange={handleChange(setPostalCode)}
                  />
                </div>
              </div>
              <div className="flex-row address-modal-form-footer">
                <div>
                  <Checkbox
                    iconStyle={{
                      fill: 'black',
                    }}
                    data-testid="address-modal-checkbox"
                    color="primary"
                    type="checkbox"
                    checked={defaultSelected}
                    name="default"
                    onChange={handleCheckbox}
                  />
                  <label htmlFor="address-modal-checkbox">
                    {getMessage('defaultAddress')}
                  </label>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  data-testid="address-modal-submit-button"
                >
                  {getMessage('submit')}
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

AddressModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  adjustmentId: string.isRequired,
};

export default AddressModal;
