import React from 'react';
import { string } from 'prop-types';

 
export const UnbinnedBox = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93574 0L7.26249 0.490106C7.74723 1.21718 8.48367 2.19977 9.10796 3.01133C9.41828 3.41475 9.69769 3.77177 9.89949 4.0279C10.0004 4.15592 10.0817 4.25863 10.1378 4.32921L10.2022 4.41009L10.2187 4.43083L10.2229 4.436L10.2239 4.43725L10.2241 4.43754L10.2241 4.43759L10.5592 4.85635L10.2712 5.30878L7.18652 10.1561H13.6384V7.15614C13.6384 6.21429 13.8207 5.41425 14.3457 4.86189C14.8823 4.29745 15.6181 4.15611 16.3202 4.15611H21.3202H21.9556L22.06 4.78282L23.06 10.7828L23.0702 10.844V10.9061V21.4061V22.1561H22.3202H5.82025H5.07025V21.4061V10.9061V10.6877L5.18751 10.5035L5.51857 9.98323L5.00761 10.3117L0.507611 3.31168L0 2.52206L0.882191 2.20127L6.38216 0.2013L6.93574 0ZM6.04643 9.15375L8.71925 4.95367C8.51495 4.69435 8.23268 4.33365 7.91903 3.9259C7.41792 3.27448 6.82841 2.49224 6.3488 1.80952L2.27699 3.29017L6.04643 9.15375ZM13.6384 11.6561V14.1561H15.1384V11.6561H21.5702V20.6561H6.57025V11.6561H13.6384ZM21.4349 10.1561H15.1384V7.15614C15.1384 6.35798 15.3021 6.03301 15.4329 5.89536C15.5523 5.76979 15.7824 5.65611 16.3202 5.65611H20.6849L21.4349 10.1561Z"
        fill="currentColor"
      />
    </svg>
  </div>
);

UnbinnedBox.propTypes = {
  extraClasses: string,
};

UnbinnedBox.defaultProps = {
  extraClasses: string,
};
