import React from 'react';
import { string } from 'prop-types';

import './svgs.css';

 
export const BackInTime = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 6.5V12.5H17.25" stroke="currentColor" strokeWidth="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.25 12.5C20.25 17.057 16.556 20.75 12 20.75C7.444 20.75 3.75 17.057 3.75 12.5C3.75 7.943 7.444 4.25 12 4.25C16.556 4.25 20.25 7.943 20.25 12.5Z" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  </div>
);

BackInTime.propTypes = {
  extraClasses: string,
};

BackInTime.defaultProps = {
  extraClasses: '',
};
