 
export const priceChangesSteps = [
  {
    content: 'Hover over a given chart to see the breakdown of the styles marked down for a given division',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: false,
    spotlightPadding: 10,
    target: '[data-testid="priceChanges-toggleGroup"]',
    title: 'inboundVisibilityS5Title',
  },
  {
    content: 'inboundVisibilityS6',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="toggle-vertical"]',
    title: 'inboundVisibilityS6Title',
  },
  {
    content: 'inboundVisibilityS8',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'styleManagementS7',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'styleManagementS7Title',
  },
  {
    content: 'Select a single row for additional markdown details for each style color',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'Markdown Task Product Details',
  },
  { // click will happen here
    content: 'inboundVisibilityS17',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="modal-next-button"]',
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
    title: 'inboundVisibilityS17Title',
  },
  {
    content: 'inboundVisibilityS18',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="modalCloseButton"]',
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
    title: 'inboundVisibilityS18Title',
  },
  {
    content: 'The date here represents the last time the report was generated. Select the refresh button to update the Price Change report for the latest product details',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="priceChanges-refresh"]',
    title: 'Refresh the Price Change Report',
  },
];
