 
import React from 'react';
import { object } from 'prop-types';
import Grid from '@mui/material/Grid2';

const styles = {
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    paddingLeft: '4px',
  },
  textAlignRight: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'right',
    paddingRight: '4px',
  },
  tWide: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '370px',
    paddingLeft: '4px',
  },
  totals: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '150px',
    textAlign: 'right',
    paddingRight: '4px',
  },
  borderless: {
    border: 'none',
  },
  borderlessTextAlign: {
    border: 'none',
    textAlign: 'right',
  },
};

const ProductTable = ({ data }) => data?.products?.map((product) => (
  <Grid container style={{ maxWidth: '100%', padding: '0 !important' }}>
    <Grid sm={12} style={{ padding: '0 !important' }}>
      <table aria-label="challan product data table" border="1" style={{ width: '100%' }}>
        <tr>
          <th style={styles.td}>Material HSN Code</th>
          <th style={styles.tWide}>Product Description</th>
          <th style={styles.td}>PrlD</th>
          <th style={styles.td}>Size</th>
          <th style={styles.td}>UOM</th>
          <th style={styles.td}>
            Quantity
            <br />
            Ordered
          </th>
          <th style={styles.td}>
            Quantity
            <br />
            Shipped
          </th>
          <th style={styles.textAlignRight}>Net Unit Price</th>
          <th style={styles.textAlignRight}>SGST</th>
          <th style={styles.textAlignRight}>CGST</th>
          <th style={styles.textAlignRight}>IGST</th>
          <th style={styles.totals}>Total</th>
        </tr>
        {product?.sizes?.length === 1 ? (
          <>
            <tr key={product?.styleColor}>
              <td style={styles.td} rowSpan={product?.sizes?.length + 1}>
                {product?.styleColor}
                <br />
                {product?.hsnCode}
              </td>
              <td style={styles.td} rowSpan={product?.sizes?.length + 1}>{product?.desc}</td>
              {product?.sizes?.map((row) => (
                <>
                  <td style={styles.td}>{row.prlD}</td>
                  <td style={styles.td}>{row.size}</td>
                  <td style={styles.td}>{row.uom}</td>
                  <td style={styles.textAlignRight}>{row.qtyOrdered}</td>
                  <td style={styles.textAlignRight}>{row.qtyShipped}</td>
                  <td style={styles.textAlignRight}>{row.unitPrice}</td>
                  <td style={styles.textAlignRight}>{row.SGST}</td>
                  <td style={styles.textAlignRight}>{row.CGST}</td>
                  <td style={styles.textAlignRight}>{row.IGST}</td>
                  <td style={styles.textAlignRight} colSpan="4">{product?.subtotals?.total}</td>
                </>
              ))}
            </tr>
            <tr key={product?.styleColor}>
              <td style={styles.textAlignRight}>{product?.subtotals?.qtyOrdered}</td>
              <td style={styles.textAlignRight}>{product?.subtotals?.qtyShipped}</td>
              <td style={styles.textAlignRight}>{product?.subtotals?.netUnitPrice}</td>
              <td style={styles.textAlignRight}>{product?.subtotals?.SGST}</td>
              <td style={styles.textAlignRight}>{product?.subtotals?.CGST}</td>
              <td style={styles.td}>{product?.subtotals?.IGST}</td>
              <td style={styles.textAlignRight}>{product?.subtotals?.total}</td>
            </tr>
          </>
        ) : (
          <>
            <tr key={product?.styleColor}>
              <td style={styles.td} rowSpan={product?.sizes?.length + 1}>
                {product?.styleColor}
                <br />
                {product?.hsnCode}
              </td>
              <td style={styles.td} rowSpan={product?.sizes?.length + 1}>{product?.desc}</td>
              <td style={styles.td}>{product?.sizes[0].prlD}</td>
              <td style={styles.td}>{product?.sizes[0].size}</td>
              <td style={styles.td}>{product?.sizes[0].uom}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].qtyOrdered}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].qtyShipped}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].unitPrice}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].SGST}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].CGST}</td>
              <td style={styles.textAlignRight}>{product?.sizes[0].IGST}</td>
            </tr>
            {product?.sizes?.slice(1).map((row) => (
              <>
                <tr key={product?.styleColor}>
                  <td style={styles.td}>{row.prlD}</td>
                  <td style={styles.td}>{row.size}</td>
                  <td style={styles.td}>{row.uom}</td>
                  <td style={styles.textAlignRight}>{row.qtyOrdered}</td>
                  <td style={styles.textAlignRight}>{row.qtyShipped}</td>
                  <td style={styles.textAlignRight}>{row.unitPrice}</td>
                  <td style={styles.textAlignRight}>{row.SGST}</td>
                  <td style={styles.textAlignRight}>{row.CGST}</td>
                  <td style={styles.textAlignRight}>{row.IGST}</td>
                </tr>
              </>
            ))}
          </>
        )}
        <tr key={product?.styleColor}>
          <td style={styles.borderless}>** Subtotals</td>
          <td style={styles.borderless}>{}</td>
          <td style={styles.borderless}>{}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.qtyOrdered}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.qtyShipped}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.netUnitPrice}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.SGST}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.CGST}</td>
          <td style={styles.textAlignRight}>{product?.subtotals?.IGST}</td>
          <td style={styles.borderlessTextAlign}>{product?.subtotals?.total}</td>
        </tr>
      </table>
    </Grid>
  </Grid>
));

export default ProductTable;

ProductTable.propTypes = {
  data: object,
};

ProductTable.defaultProps = {
  data: {},
};
