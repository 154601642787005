 
import React, { useContext } from 'react';
import { Tabs, Tab } from '@mui/material';
import { SimWebContext } from '../../context/SimWeb.provider';
import { InboundVisibilityContext } from './InboundVisibility.provider';

const InboundVisibilityTabs = () => {
  const { getMessage } = useContext(SimWebContext);
  const { setTabValue } = useContext(InboundVisibilityContext);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Tabs value={0} onChange={handleChange} sx={{ '.MuiTabs-indicator': { backgroundColor: 'black' } }}>
      <Tab sx={{ color: '#666', '&.Mui-selected': { color: '#666' } }} label={getMessage('inTransit')} />
      {/* {isOnOrderEnabled && <Tab sx={{ color: '#666', '&.Mui-selected': { color: '#666' } }} label={getMessage('onOrder')} />} */}
    </Tabs>

  );
};

export default InboundVisibilityTabs;
