 
import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';

import Arrow from '../assets/Svgs/Arrow';

/**
 * Custom table header used on the size compliance page, allows the header sort functionality to be disabled
 * @param {object} columnMeta - all the information about the column the header is for
 * @param {func} handleToggleColumn - the function that should be called when the header is clicked
 * @param {bool} disabled - if true then sorting based of this column is disabled
 */
const CustomMuiTableHeader = ({
  columnMeta,
  getClasses,
  handleToggleColumn,
  dataTestId,
  disabled,
  fixedHeader,
  secondaryInfo,
  sortDirection,
}) => {
  const handleClick = () => {
    // disabled since sorting is not enabled on the backend
    if (!disabled) {
      handleToggleColumn(columnMeta.index);
    }
  };

  const classNames = getClasses(columnMeta, disabled, fixedHeader);

  return (
    <th
      data-testid={dataTestId}
      key={columnMeta.label}
      className={classNames}
      onClick={handleClick}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        background: 'white',
      }}
    >
      {
        columnMeta.label
      }
      <span>
        {sortDirection !== 'none' ? <Arrow flip={sortDirection === 'asc'} /> : null}
      </span>
      {secondaryInfo}
    </th>
  );
};

CustomMuiTableHeader.propTypes = {
  columnMeta: object.isRequired,
  handleToggleColumn: func.isRequired,
  getClasses: func.isRequired,
  dataTestId: string.isRequired,
  disabled: bool.isRequired,
  fixedHeader: bool,
  secondaryInfo: object,
  sortDirection: string,
};

CustomMuiTableHeader.defaultProps = {
  fixedHeader: false,
  secondaryInfo: null,
  sortDirection: 'none',
};

export default CustomMuiTableHeader;
