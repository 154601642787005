import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { node, object } from 'prop-types';
import { baseUrl, routerUrls, endpoints } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { createAndMonitorJob, retrieveJobData } from '../../axios/jobPollAndDataFetchAxiosFunctions';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';
import { constructTableData } from '../transfers/transfers.utils';
import { SimWebContext } from '../../context/SimWeb.provider';

export const ChallanContext = createContext();

const ChallanProvider = ({ children, mockedValue }) => {
  const { Provider } = ChallanContext;

  const {
    currencyCode, locale, region, store, SimDateTime,
  } = useContext(SimWebContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [challanData, setChallanData] = useState(null);
  const [challanLoading, setChallanLoading] = useState(false);
  const [reportType, setReportType] = useState('adjustments');
  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());
  const [idList, setIdList] = useState([]);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  // states for Transfers
  const [displayGovernmentNumber, setDisplayGovernmentNumber] = useState(false);
  const [displayReturnAuthorizationNumber, setDisplayReturnAuthorizationNumber] = useState(false);

  const id = 'challanPage';
  const minDate = SimDateTime.startOfDay().minus({ years: 8 });
  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, minDate, maxDate);

  useEffect(() => {
    setLoading(true);

    const fetchChallanData = async () => {
      setData(null);
      setError(null);
      try {
        if (reportType === 'adjustments') {
          const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.ADJUSTMENTS.url}`
          + `?store=${store}`
          + `&region=${region}`
          + `&creationDateAfterEq=${SimDateTime.toUtcISO(startDate)}`
          + `&creationDateBeforeEq=${SimDateTime.toUtcISO(endDate)}`;

          const response = await generalAxiosRequest('GET', url, endpoints.ADJUSTMENTS);
          const staffDressAdjustmentsData = response.details.filter(detail => detail.type !== 'staffDress');
          const { newData, hasGovernmentNumber, hasReturnAuthorizationNumber } = constructTableData(staffDressAdjustmentsData, locale, currencyCode);
          setData(newData);
          setDisplayGovernmentNumber(hasGovernmentNumber);
          setDisplayReturnAuthorizationNumber(hasReturnAuthorizationNumber);
        }
        if (reportType === 'transfers') {
          const detailsPayload = {
            creationDateAfterEq: startDate,
            creationDateBeforeEq: endDate,
            fromLocation: store,
          };
          const jobId = await createAndMonitorJob(routerUrls.DELEDGE,
            endpoints.TRANSFERS, detailsPayload);
          const detailsResponse = await retrieveJobData(jobId, routerUrls.DELEDGE, endpoints.TRANSFERS);
          setData(detailsResponse);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (startDate && endDate && store) {
      fetchChallanData();
    }
  }, [startDate, endDate, reportType, store]);

  useEffect(() => {
    // TODO: update this after BFF work is completed
    const getChallans = async () => {
      setChallanLoading(true);
      try {
        const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.CHALLAN.url}/${reportType}`;
        // const url = `${baseUrl(routerUrls.SIMWEB_BFF)}/simweb_bff/challan/v1/challanId`;
        const response = await generalAxiosRequest('GET', url, endpoints.CHALLAN);
        const blob = new Blob([response], { type: 'application/pdf' });
        setChallanData(URL.createObjectURL(blob));
      } catch (error) {
        setError(error);
      } finally {
        setChallanLoading(false);
      }
    };
    if (idList.length > 0) {
      getChallans();
    }
  }, [idList]);

  const onGoClick = (options) => {
    const { startDate, endDate } = options;
    setStartDate(startDate);
    setEndDate(endDate);
    setIdList([]);
  };

  return (
    <Provider value={mockedValue ?? {
      challanData,
      challanLoading,
      data,
      displayGovernmentNumber,
      displayReturnAuthorizationNumber,
      endDate,
      error,
      id,
      loading,
      printDialogOpen,
      reportType,
      startDate,
      isOutsideRange,
      onGoClick,
      setIdList,
      setPrintDialogOpen,
      setReportType,
    }}
    >
      {children}
    </Provider>
  );
};

ChallanProvider.propTypes = {
  children: node,
  mockedValue: object,
};

ChallanProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

export default ChallanProvider;
