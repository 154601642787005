 
import { routerUrls, endpoints, baseUrl } from '../../../axios/endpoints';
import { generalAxiosRequest } from '../../../axios/axiosFunctions';

const headers = { 'nike-api-caller-id': 'com.nike:retail.sim' };
const statuses = ['OPEN', 'UNOPENED', 'CLOSED'];
const filterStatus = statuses.map(status => `storeStatus==${status}`).join(' or ');

export const fetchAllCountries = async () => {
  let anchor = null;
  const countries = [];
  let url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}?search=(${filterStatus})&fields=address.country&count=2000`;
  do {
    if (anchor) {
      url = `${baseUrl(routerUrls.NIKE_API)}/${anchor}`;
    }
     
    const response = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
    if (response?.objects?.length > 0) {
      anchor = response?.pages?.next;
      countries.push(...response.objects);
    }
  } while (anchor);

  const listOfCountries = [...new Set(countries.map(store => store?.address?.country))];
  listOfCountries.sort();
  const countryArray = listOfCountries?.map(country => ({
    value: country,
    label: country,
  }));
  return countryArray;
};

export const fetchStoreNumbers = async (countryCode) => {
  let anchor = null;
  const storeNumbers = [];
  let url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}?search=((address.country==(${countryCode})) and (${filterStatus}))&fields=storeNumber,storeStatus&count=2000`;
  do {
    if (anchor) {
      url = `${baseUrl(routerUrls.NIKE_API)}/${anchor}`;
    }
     
    const response = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
    if (response?.objects?.length > 0) {
      anchor = response?.pages?.next;
      storeNumbers.push(...response.objects);
    }
  } while (anchor);

  const listOfStoreNumbers = [...new Set(storeNumbers?.filter(num => statuses.includes(num?.storeStatus)))].map(store => store.storeNumber);
  listOfStoreNumbers.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));

  return listOfStoreNumbers.map(number => ({
    label: number,
    value: number,
  }));
};
