 
 

import React, { useState, createContext, useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { object, node } from 'prop-types';
import { parseUserRoles } from '../utils/parseUserRoles.ts';
import { newRelicAction, newRelicAttribute, logErrorAction } from '../utils/newRelicPageActions';
import setToHappen from '../utils/setToHappen';
import { Messages } from '../services/iframeMessaging';
import { TranslationsContext } from './Translations.provider';
import { ErrorContext } from './Error.provider';
import env from '../environment';
import { generalAxiosRequest, wait } from '../axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from '../axios/endpoints';
import { version } from '../../package.json';

export const getAccessToken = () => {
  const userDetails = JSON.parse(sessionStorage.getItem('login'));
  return userDetails?.access_token;
};

export const setAccessToken = (value) => sessionStorage.setItem('accessToken', value);

export const LoginContext = createContext({});

/**
  * A Login Provider that handles login tokens and properites
  * @param {node} children - data that we iterate
  * @param {object}  - mockedValue context object
*/
const LoginProvider = ({ children, mockedValue }) => {
  const { Provider } = LoginContext;
  const history = useHistory();

  const { setLanguage } = useContext(TranslationsContext);
  const { setRedirectError } = useContext(ErrorContext);

  const [appId, setAppId] = useState('nike.athlete.platform');
  const [athleteId, setAthleteId] = useState(null);
  const [country, setCountry] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [nikeStoreRoles, setNikeStoreRoles] = useState(null);
  const [storeNumber, setStoreNumber] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);
  const [source, setSource] = useState(sessionStorage.getItem('source')); // expect oneOf: [retail, IOS, true]
  const [isAdmin, setIsAdmin] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(0);

  // Returned for WrapperLogin
  const [store, setStore] = useState(null);
  const [storeId, setStoreId] = useState(null);

  const INVALID_REDIRECT_PAGES = ['/login', '/', '/index.html'];

  const addNewRelicAttributes = (authSource, athlete, country, storeNumber, countryStore) => {
    if (athlete) newRelicAttribute('athlete', athlete);
    if (authSource) newRelicAttribute('authSource', authSource);
    if (country) newRelicAttribute('country', country);
    if (storeNumber) newRelicAttribute('storeNumber', storeNumber);
    if (countryStore) newRelicAttribute('countryStore', countryStore);
  };

  /**
   * This either moves user to previous page or sends them from a fresh login to the dashboard.
   */
  const loginRedirect = () => {
    const currentLastPage = history?.location?.pathname;
    const trueParent = localStorage.getItem('true.parentUrl');

    const shouldRedirectToSelfCheckout = source === 'true' && trueParent?.includes('selfCheckout');
    const isValidLastPage = currentLastPage && currentLastPage !== 'null' && !INVALID_REDIRECT_PAGES.includes(currentLastPage.toLowerCase());

    if (shouldRedirectToSelfCheckout) {
      history.push('/selfCheckoutDeviceAssignment');
    } else if (isValidLastPage) {
      history.push(currentLastPage);
    } else if (currentLastPage !== '/dashboard') {
      history.push('/dashboard');
    }
  };

  /**
   * Sets the values needed in order to properly log into the application.
   * @param {object} user -> token returned from auth source
   */
  const setLoggedInValues = (user) => {
    const athlete = user?.prn?.split(';')[2];
    const exp = parseInt(user.expires_at, 10) - Date.now();

    setIsLoggedIn(true);
    setRefreshTimer(exp);
    setNikeStoreRoles(parseUserRoles(user.roles));
    setAthleteId(athlete);
    setUserDetails(user);
    if (env.isTest() || athlete === '99999') setIsAdmin(true);
    addNewRelicAttributes('retail-auth', athlete, country);
    newRelicAction('sim-login', { action: 'retail Login' });
  };

  /**
   * Logs a user into the application via the browser.
   * @param {object} loginData - contains username, password, and storeNumber
   */
  const performLogIn = async (loginData) => {
    setIsFetching(true);
    setError(null);

    const country = loginData.store.split('-')[0].toUpperCase();
    const storeNumber = loginData.store.split('-')[1];
    const assertion = btoa(`${country};${storeNumber};${loginData.athleteLogin}:${loginData.password}`);
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.LEGACY_BERM.url}`;

    sessionStorage.setItem('appId', 'nike.athlete.platform');

    setCountry(country.toUpperCase());
    setStoreNumber(storeNumber);

    try {
      const response = await generalAxiosRequest('POST', url, endpoints.LEGACY_BERM, false, null, { assertion });
      if (response) {
        const { roles } = response;
        if ((parseUserRoles(roles).maxValue) < 20) {
          throw new Error('unauthorizedError');
        }

        const updatedData = { ...response, expires_at: Date.now() + response.expires_in * 1000 };

        newRelicAction('simweb-version', { version });
        sessionStorage.setItem('login', JSON.stringify(updatedData));
        setLoggedInValues(updatedData);
        loginRedirect();
      }
    } catch (error) {
      logErrorAction('[Login error]', error);
      setUserDetails(null);
      setIsLoggedIn(false);
      setError(error.message === 'unauthorizedError' ? 'unauthorizedError' : 'generalError');
    }
    setIsFetching(false);
  };

  /**
   * Used for logging out users
   */
  const resetState = () => {
    sessionStorage.clear();

    setIsLoggedIn(false);
    setIsFetching(false);
    setUserDetails(null);
    setAthleteId(null);
    setError(null);
    setIsAdmin(null);
    setAccessToken(null);
    setNikeStoreRoles(null);
    setCountry(null);
    setStoreNumber(null);
    setRedirectError(null);
    setAppId('nike.athlete.platform');

    // Returned for wrapper login
    setStore(null);
    setStoreId(null);
  };

  /**
   * logs users out of their respective platforms
   */
  const logout = () => {
    resetState(); // session is cleared

    if (source === 'true') {
      const docRef = document.referrer.replace(/\/$/, '');
      window.parent.postMessage({ name: Messages.SetTitle, title: 'T.R.U.E' }, docRef);
      window.parent.postMessage({ name: Messages.Close }, docRef);
    }

    if (source === 'iOS' && isLoggedIn) {
      newRelicAction('sim-login', { action: 'iOS Logout' });
      window?.webkit?.messageHandlers?.logout?.postMessage('');
      sessionStorage.setItem('loginSource', 'iOS');
    }

    if (source === 'retail') {
      setRedirectError(null);
      sessionStorage.setItem('language', 'en');
      sessionStorage.setItem('loginSource', 'retail');
      setLanguage('en');
      history.push('/login');
    }
  };

  /**
   * Refreshes the user log in state or logs them out if unable to.
   */
  const refreshToken = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem('login'));
    try {
      const { access_token, refresh_token } = { ...userDetails };
      if (!access_token || !refresh_token) {
        throw new Error('Response "access_token" or "refresh_token" property is null.');
      }
      const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.LEGACY_BERM.url}${endpoints.LEGACY_BERM.actions.refresh}`;
      const data = {
        accessToken: access_token,
        refreshToken: refresh_token,
      };
      const response = await generalAxiosRequest('POST', url, endpoints.LEGACY_BERM, false, null, data);
      if (!response.expires_at || !response.access_token) {
        throw new Error('Access token or expiration date is missing from the response.');
      }
      const updatedData = { ...response, expires_at: Date.now() + response.expires_in * 1000 };
      sessionStorage.setItem('login', JSON.stringify(updatedData));
      setAccessToken(response?.access_token);
      setLoggedInValues(updatedData);
    } catch {
      logout();
    }
  };

  /**
   * Determines whether or not a user is logging in via TRUE or iOS device and sets auth values appropriately
   * @param {object} decodedJwt
   * @param {object} token
   */
  const setTrueOrIOSLogin = (decodedJwt, token) => {
    const { sub, nike_storeRoles, given_name, exp, prn } = decodedJwt;
    const appId = source === 'iOS' ? token?.clientID : sub;
    const userDetails = {
      access_token: token?.accessToken,
      roles: nike_storeRoles,
      given_name,
      expires_at: exp,
      prn,
    };
    sessionStorage.setItem('login', JSON.stringify(userDetails));
    sessionStorage.setItem('appId', appId);
    const cachedLogin = JSON.parse(sessionStorage.getItem('login'));

    const country = prn.split(';')[0].toUpperCase();
    const storeNumber = prn.split(';')[1];

    setCountry(country);
    setStoreNumber(storeNumber);
    setLoggedInValues(userDetails);
    setAccessToken(token?.accessToken);
    setAppId(appId);
    loginRedirect();

    // New relic actions for iOS logins
    if (source === 'iOS') {
      const countryStore = `${country}-${storeNumber}`;
      addNewRelicAttributes('workforce-oidc', userDetails?.athlete, country, storeNumber, countryStore);
      newRelicAction('sim-login', { action: 'iOS Login' });
      // update the login
      const updatedLogin = { prn: `${country};${storeNumber};`, ...cachedLogin };
      sessionStorage.setItem('login', JSON.stringify(updatedLogin));
    }

    // Token refreshes from either the wrapper or from True
    const d = new Date(decodedJwt.exp * 1000);
    const timeTo = new Date(d.getTime() + 1); // get new token 1 ms after token experiation
    const callGetAccessData = () => {
      if (source === 'iOS') {
        return window.webkit.messageHandlers.getAccessData.postMessage('');
      }
      return window.parent.postMessage({ name: 'requestAccessToken' }, '*');
    };
    setToHappen(callGetAccessData, timeTo);
  };

  // TODO: why are getting the email value (AthleteId) from the storeViewsResponse
  // This function is only used in the wrapper login
  const storeViewsData = storeViewsResponse => {
    const { email, address, storeNumber, sid } = { ...storeViewsResponse };
    const country = address?.country;
    const cachedLoginStr = sessionStorage.getItem('login');
    const cachedLogin = JSON.parse(cachedLoginStr);
    const storeNumAsInt = parseInt(storeNumber, 10);
    const countryStore = `${country}-${storeNumAsInt}`;

    setUserDetails({ ...userDetails, athlete: email });
    setAthleteId(email);
    setStore(countryStore);
    setStoreId(sid);
    const updatedLogin = { prn: `${country};${storeNumber};`, ...cachedLogin };
    sessionStorage.setItem('login', JSON.stringify(updatedLogin));
    addNewRelicAttributes('workforce-oidc', userDetails?.athlete, country, store, countryStore);
    newRelicAction('sim-login', { action: 'iOS Login', operation: 'storeViewsData' });
  };

  const setOidcAccessToken = (decodedJwt, token) => {
    const { nike_storeRoles, given_name, family_name, exp, prn, sid } = decodedJwt;

    // TODO: come up with the same structure for all types
    const userDetails = {
      access_token: token?.accessToken,
      athlete: prn,
      roles: nike_storeRoles,
      given_name,
      family_name,
      expires_at: exp,
    };

    sessionStorage.setItem('login', JSON.stringify(userDetails));

    setSource('iOS');
    setUserDetails(userDetails);
    setNikeStoreRoles(parseUserRoles(nike_storeRoles));
    setAthleteId(prn);
    setStoreId(sid);
    setAppId(token?.clientID);
    sessionStorage.setItem('appId', token?.clientID);
    setIsLoggedIn(true);
    setAccessToken(token?.accessToken);

    const country = localStorage.getItem('country');
    const store = localStorage.getItem('storeNumber');
    const countryStore = `${country}-${store}`;
    addNewRelicAttributes('workforce-oidc', userDetails?.athlete, country, store, countryStore);
    newRelicAction('sim-login', { action: 'iOS Login' });
    // the jwt is the athlete jwt the iOS wrapper returns
    const d = new Date(decodedJwt.exp * 1000);
    const timeTo = new Date(d.getTime() + 1);
    const callGetAccessData = () => { window.webkit.messageHandlers.getAccessData.postMessage(''); };
    // get new token 1 ms after token experiation
    setToHappen(callGetAccessData, timeTo);
  };

  /**
   * This will update the browser local storage with the country and store number, should they change
   */
  useEffect(() => {
    if (country) {
      localStorage.setItem('country', country.toUpperCase());
      localStorage.removeItem('storeNumber');

      sessionStorage.setItem('country', country.toUpperCase());
      sessionStorage.removeItem('storeNumber');
    }
    if (storeNumber) {
      localStorage.setItem('storeNumber', storeNumber);
      sessionStorage.setItem('storeNumber', storeNumber);
    }
  }, [country, storeNumber]);

  /**
   * This re-sets the logged in state on a page refresh
   */
  useEffect(() => {
    const country = sessionStorage.getItem('country') ?? localStorage.getItem('country');
    const storeNumber = sessionStorage.getItem('storeNumber') ?? localStorage.getItem('storeNumber');

    if (!country || !storeNumber) return;

    const cachedLoginStr = sessionStorage.getItem('login');
    const cachedLogin = JSON.parse(cachedLoginStr);
    if (cachedLogin) {
      setCountry(country);
      setStoreNumber(storeNumber);
      setLoggedInValues(cachedLogin);
      loginRedirect();
    }
  }, [sessionStorage.getItem('country'), sessionStorage.getItem('storeNumber'), localStorage.getItem('country'), localStorage.getItem('storeNumber')]);

  /**
   * Sets a timer to reset the auth state
   * TODO: this shouldn't be taken care of here, it should refresh on the first 401/403 failure in the apis.
   */
  useEffect(() => {
    const engageRefreshTimer = async () => {
      await wait(refreshTimer);
      await refreshToken();
    };
    if (userDetails && refreshTimer > 0) {
      engageRefreshTimer();
    }
  }, [userDetails, refreshTimer]);

  // exposed for testing
  const ext = env.isTest() ? {
    setLoggedInValues,
    resetState,
    refreshToken,
    source,
    store,
    setSource,
  } : null;

  const contextValue = useMemo(() => ({
    appId,
    athleteId,
    country,
    storeNumber,
    error,
    isFetching,
    isLoggedIn,
    nikeStoreRoles,
    userDetails,
    isAdmin,
    storeId,
    setError,
    setOidcAccessToken,
    storeViewsData,
    loginRedirect,
    logout,
    addNewRelicAttributes,
    performLogIn,
    setTrueOrIOSLogin,
    setIsLoggedIn,
    setCountry,
    setStoreNumber,
    setIsAdmin,
    isAthleteAdmin: () => env.isTest() || athleteId === '99999',
    ext,
  }), [appId, athleteId, country, error, isFetching, isLoggedIn, nikeStoreRoles, store, source, storeNumber, storeId, userDetails, performLogIn, loginRedirect, logout, setTrueOrIOSLogin, setIsLoggedIn, setOidcAccessToken, storeViewsData, resetState, setSource]);

  return (
    <Provider value={mockedValue ?? contextValue}>
      {children}
    </Provider>
  );
};

LoginProvider.defaultProps = {
  children: null,
  mockedValue: null,
};

LoginProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default LoginProvider;
