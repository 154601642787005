 
import React, { useContext, useRef, useEffect } from 'react';
import {
  array, bool, string, number, func,
} from 'prop-types';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from 'mui-datatables';
import { sortBy } from 'lodash';
import { SimWebContext } from '../../context/SimWeb.provider';
import DialogHeader from '../../components/dialogHeader/DialogHeader';

const useStyles = makeStyles({
  downloadIcon: {
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
});

/**
 * Takes in asn tracking data and formats it to be show in the dialog that is shown on the
 * ASN tracking page when an order is clicked on.
 * @param {array} dialogData the data being shown in the dialog
 * @param {function} setDialogData upates the data in the dialog whenever clicking through the chevron buttons
 * @param {string} storeNum the currently logged into store
 * @param {array} rows - array of shipping details per selected date
 * @param {array} setPROIndex - index of the PO Number to control the data in the modal
 * @param {array} proIndex - index of the PO the user has selected for the modal
 * @param {Function} setOpen - func that opens/closes the modal
 */
const DialogDetails = ({
  dialogData, setDialogData, storeNum, rows, setPROIndex, proIndex, setOpen, loading,
}) => {
  const {
    getMessage, formatMessage, csvFormat, storeConfig,
  } = useContext(SimWebContext);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const classes = useStyles();
  const csvLink = useRef();

  useEffect(() => {
    const newDialogData = [
      {
        ...rows[proIndex],
        documentNumber: (rows?.[proIndex]?.deliveryNumber || rows?.[proIndex]?.proNumber),
      },
    ];
    setDialogData(newDialogData);
  }, [proIndex, rows]);

  if (!dialogData || !dialogData?.length) {
    return null;
  }

  if (dialogData[0].purchaseOrders === undefined) {
    return null;
  }
  const columns = [
    {
      name: 'poNumber',
      label: getMessage('poNumber'),
    },
    {
      name: 'cartonNumber',
      label: getMessage('cartonID'),
    },
    {
      name: 'styleColor',
      label: getMessage('style-color'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'class',
      label: getMessage('class'),
    },
    {
      name: 'unitCount',
      label: getMessage('unitsExpected'),
    },
    {
      name: 'soh',
       
      label: (rfidEnabled) ? getMessage('rfidSoh') : getMessage('soh'),
    },
  ];

  const buildDownloadData = () => {
    const toDownload = [];
    dialogData?.[0]?.purchaseOrders?.map(r => toDownload.push({
      'Carton Number': `${csvFormat}${r?.cartonNumber}` || '-',
      'Purchase Order Number': r?.purchaseOrderNumber || '-',
      'Style Color': r?.styleColor || '-',
      Description: r?.description || '-',
      Class: r?.class || '-',
      Division: r?.division || '-',
      'Stock On Hand': r?.stockOnHand || '-',
      'Unit Count': r?.unitCount || '-',
      'Current Price': r?.currentPrice || '-',
      'Retail Price': r?.retailPrice || '-',
    }));
    return toDownload;
  };

  const TableHeaderElements = () => (
    <>
      <IconButton
        onClick={() => csvLink?.current?.link.click()}
        className={classes.downloadIcon}
        data-testid="dialogDetails-downloadIcon"
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
      <CSVLink
        data={buildDownloadData()}
        filename="ASN Tracking Details"
        ref={csvLink}
        target="_blank"
      />
    </>
  );

  const renderData = dialogData?.[0]?.purchaseOrders?.map(_ => ({
    poNumber: _.purchaseOrderNumber || '-',
    cartonNumber: _.cartonNumber || '-',
    styleColor: _.styleColor || '-',
    description: _.description || '-',
    class: _.class || '-',
    unitCount: _.unitCount || '-',
    soh: _.stockOnHand || '-',
  }));

  const sortedByStyleColor = sortBy(renderData, 'styleColor');
  const sortedByCartonNumber = sortBy(sortedByStyleColor, 'cartonNumber');
  const sortedByPONumber = sortBy(sortedByCartonNumber, 'poNumber');

  return (
    <div data-testid="dialog-muiDataTable">
      <DialogHeader
        loading={loading}
        index={proIndex}
        setIsOpen={setOpen}
        rowCount={rows?.length}
        setIndex={setPROIndex}
        nodeTitle={(
          <>
            <strong>{formatMessage('purchaseOrderListStore', { store: storeNum })}</strong>
            {` ${dialogData[0].documentNumber}`}
          </>
        )}
      />
      <DialogContent>
        <MUIDataTable
          columns={columns}
          data={sortedByPONumber}
          options={{
            selectableRows: 'none',
            rowsPerPage: 100,
            rowsPerPageOptions: [],
            print: false,
            filter: false,
            viewColumns: false,
            download: false,
            customToolbar: () => (<TableHeaderElements />),
          }}
        />
      </DialogContent>
    </div>
  );
};

DialogDetails.propTypes = {
  dialogData: array.isRequired,
  setDialogData: func.isRequired,
  storeNum: string.isRequired,
  rows: array.isRequired,
  setPROIndex: func.isRequired,
  proIndex: number.isRequired,
  setOpen: func.isRequired,
  loading: bool,
};

DialogDetails.defaultProps = {
  loading: false,
};

export default DialogDetails;
