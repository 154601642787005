 
export const inboundStepArray = [
  {
    content: 'inboundVisibilityS1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="date-pickers-container"]',
    title: 'inboundVisibilityS1Title',
  },
  {
    content: 'inboundVisibilityS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="date-range-submit-button"]',
    title: 'inboundVisibilityS2Title',
  },
  {
    content: 'inboundVisibilityS3',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: '[data-testid="shipDate-facet-container"] > div:nth-child(1)',
    title: 'inboundVisibilityS3Title',
  },
  {
    content: 'inboundVisibilityS4',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-search-tab-po-number"]',
    title: 'inboundVisibilityS4Title',
  },
  {
    content: 'inboundVisibilityS5',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-genderAge-visual"]',
    title: 'inboundVisibilityS5Title',
  },
  {
    content: 'inboundVisibilityS6',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '.toggle-vertical',
    title: 'inboundVisibilityS6Title',
  },
  {
    content: 'inboundVisibilityS7',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="inbound-visibility-clear-facets-button"]',
    title: 'inboundVisibilityS7Title',
  },
  {
    content: 'inboundVisibilityS8',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'inboundVisibilityS9',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'stockRoomOrgS2Title',
  },
  {
    content: 'inboundVisibilityS10',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'thead tr th',
    title: 'inboundVisibilityS10Title',
  },
  {
    content: 'inboundVisibilityS11',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'inboundVisibilityS11Title',
  },
  {
    content: 'inboundVisibilityS12',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-1"]',
    title: 'inboundVisibilityS12Title',
  },
  {
    content: 'inboundVisibilityS13',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '[data-testid="inbound-visibility-custom-tool-bar"]',
    title: 'inboundVisibilityS13Title',
  },
  {
    content: 'inboundVisibilityS14',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: '[data-testid="inbound-visibility-custom-tool-bar"] img',
    title: 'inboundVisibilityS14Title',
    config: true, // only if isDropZonesEnabled is true
  },
  {
    content: 'inboundVisibilityS15',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '#printDropZoneDialog-footer > div',
    title: 'inboundVisibilityS15Title',
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
    config: true, // only if isDropZonesEnabled is true
  },
  {
    content: 'inboundVisibilityS16',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'inboundVisibilityS16Title',
  },
  {
    content: 'inboundVisibilityS17',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="modal-next-button"]',
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
    title: 'inboundVisibilityS17Title',
  },
  {
    content: 'inboundVisibilityS18',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="modalCloseButton"]',
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
    title: 'inboundVisibilityS18Title',
  },
];

 
export const inboundVisibilitySteps = (isDropZonesEnabled, translatedSteps) => {
  let newSteps = translatedSteps;

  if (!isDropZonesEnabled) {
    newSteps = inboundStepArray.filter(value => value.config !== true);
  }

  return newSteps;
};
