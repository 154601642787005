 
import React, { useContext } from 'react';
import { object } from 'prop-types';
import { camelCase } from 'lodash';
import { TranslationsContext } from '../../context/Translations.provider';

const SearchTooltipContent = ({ contents }) => {
  const { getMessage } = useContext(TranslationsContext);

  const wrapperStyle = {
    paddingLeft: '5px',
    display: 'grid',
    gridTemplateColumns: '125px 200px',
    gridGap: '5px',
  };

  const tooltipContents = [];
  Object.keys(contents).forEach((key) => {
    tooltipContents.push((contents[key].translate)
       
      ? <div key={`${key}-name`}>{`${getMessage(camelCase(contents[key].name))}:`}</div>
      : <div key={`${key}-name`}>{contents[key].name}</div>);
    tooltipContents.push(<div key={`${key}-value`}>{contents[key].value}</div>);
  });

  return (
    <div data-testid="sizeComplianceSearchTooltipContext">
      <div style={wrapperStyle}>
        {tooltipContents}
        <div>{`${getMessage('and')}:`}</div>
        <div>{'("AIR MAX") + (2XL)'}</div>
        <div>{`${getMessage('or')}:`}</div>
        <div>{'("007" | "008" | "009")'}</div>
        <div>{`${getMessage('andOr')}:`}</div>
        <div>{'("AIR MAX") + ( 10 | 11.5)'}</div>
        <div>{`${getMessage('not')}:`}</div>
        <div>{'-XL'}</div>
        <div>{`${getMessage('prefix')}:`}</div>
        <div>{'Sports*'}</div>
      </div>
    </div>
  );
};

SearchTooltipContent.propTypes = {
  contents: object.isRequired,
};

export default SearchTooltipContent;
