 
import { SimDateTime } from '../../utils/datetime';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

/**
 * A helper function to filter the adjustment data by indexes
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */
export const filterSelectedData = (data, selectedItems) => {
  if (selectedItems.length === 0) return data;
  return data?.filter((item, i) => selectedItems.includes(i));
};

/**
 * A helper function to build filtered adjustment data for print or CSV download
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */
export const buildDownloadData = (data, selectedItems) => {
  const downloadData = filterSelectedData(data, selectedItems);
  const toDownload = [];

  downloadData?.forEach(item => {
    item?.tableData?.forEach(adjustment => {
      toDownload.push({
        creationDate: (SimDateTime.toLocaleString(adjustment?.creationDate, DATE_SHORT_WITH_APPENDED_ZEROS)),
        opId: adjustment?.opId,
        type: adjustment?.type,
        styleColor: adjustment?.styleColor,
        class: adjustment?.class,
        description: adjustment?.description,
        size: `${adjustment?.size}`,
        upc: `${adjustment?.upc}`,
        units: adjustment?.units,
        price: adjustment?.price,
      });
    });
    if (item?.totalPrice || item?.totalUnits) {
      toDownload.push({
        creationDate: '',
        opId: '',
        type: '',
        styleColor: '',
        class: '',
        description: '',
        size: '',
        upc: 'TOTALS',
        units: item?.totalUnits ?? '',
        price: item?.totalPrice ?? '',
      });
    }
  });

  return toDownload;
};
