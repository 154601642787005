import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import MUIDataTable from 'mui-datatables';
import { orderBy } from 'lodash';
import Dialog from '@mui/material/Dialog';
import { LoadingIndicator } from '../../assets/Svgs/index';
import ProductDetailContainer from '../../components/productDetails/ProductDetailContainer';
import createBinsFilter from '../../components/customTableFilters/CustomBinFilter';
import Error from '../../components/alerts/Error';
import { newRelicAction } from '../../utils/newRelicPageActions';

import { SimWebContext } from '../../context/SimWeb.provider';
import { StockroomContext } from './StockroomOrganization.provider';
import { useTutorial } from '../../components/tutorial/useTutorial';

const StockroomOrganizationPage = () => {
  const { getMessage, isIOS, isPrintingEnabled } = useContext(SimWebContext);
  const {
    stepIndex, setStepIndex, startTutorial, handleStartTutorial, tutorial,
  } = useTutorial('stockroomOrganization');

  const {
    allBins,
    loading,
    error,
    rows,
    setRows,
    modalStyleColor,
    setModalStyleColor,
    scIndex,
    setSCIndex,
    styleColors,
  } = useContext(StockroomContext);

  const [isOpen, setOpen] = useState(false);

  const startTime = Date.now();

  // log session time after page unrendered
  useEffect(() => () => {
    const duration = Date.now() - startTime;
    newRelicAction('stockroomOrganizationSessionDuration', { duration });
  }, []);

  const columns = useMemo(() => {
    if (loading) return null;

    return [{
      name: 'bin',
      label: getMessage('bin'),
      options: {
        filter: true,
        filterType: 'custom',
        ...createBinsFilter({ getMessage, bins: allBins }),
      },
    }, {
      name: 'division',
      label: getMessage('division'),
      options: {
        filterType: 'multiselect',
      },
    }, {
      name: 'class',
      label: getMessage('class'),
      options: {
        filterType: 'multiselect',
      },
    }, {
      name: 'category',
      label: getMessage('category'),
      options: {
        filterType: 'multiselect',
        display: !isIOS,
      },
    }, {
      name: 'genderName',
      label: getMessage('gender'),
      options: {
        filterType: 'multiselect',
        display: !isIOS,
      },
    }, {
      name: 'description',
      label: getMessage('description'),
      options: {
        filterType: 'textField',
      },
    }, {
      name: 'color',
      label: getMessage('color'),
      options: {
        filter: false,
        display: !isIOS,
      },
    }, {
      name: 'styleColor',
      label: getMessage('style-color'),
      options: {
        filterType: 'textField',
        setCellHeaderProps: (val) => ({
          className: val.index === 7 && 'columnStep',
          'data-testid': 'columnStep',
        }),
      },
    }];
  }, [loading, allBins, styleColors, isIOS, createBinsFilter, getMessage]);

  const pageContent = useMemo(() => {
    if (loading) return <LoadingIndicator />;

    if (error || (!loading && !styleColors)) {
      return (
        <Error
          apiName="Stockroom Organization API"
          errorObject={error}
        />
      );
    }

    return (
      <div data-testid="newStockroomOrganizationTable">
        {/* <Tutorial setTutorialState={handleStartTutorial} /> */}
        {tutorial}
        <MUIDataTable
          data={rows}
          columns={columns}
          options={{
            selectableRows: 'none',
            rowsPerPage: 100,
            rowsPerPageOptions: [15, 25, 50, 100],
            print: isPrintingEnabled,
            responsive: 'standard',
            setRowProps: (row) => ({
              className: row[0] && 'firstRowInDataTable',
            }),
            sort: true,
            sortOrder: {
              name: 'bin',
              direction: 'asc',
            },
            // update data for modal navigation - DO NOT DELETE
             
            customSort: (data, dataIndex, rowIndex) => data?.sort((a, b) => (rowIndex === 'desc' ? (a?.data[dataIndex] < b?.data[dataIndex] ? 1 : -1) : (b?.data[dataIndex] > a?.data[dataIndex] ? -1 : 1))),
            onColumnSortChange: (changedColumn, direction) => {
              setRows(orderBy(rows, changedColumn, [`${direction}`]));
            },
            onRowClick: (rowData, { dataIndex }) => {
              setSCIndex(dataIndex);
              const styleColor = rowData[rowData.length - 1];
              setModalStyleColor(styleColor);
              setOpen(true);
              // tutorial magic
              if (startTutorial && stepIndex === 2) handleStartTutorial(false);
              setStepIndex(stepIndex === 2 ? 3 : stepIndex);
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            // ignoring lint rule bc the data isn't rendering properly if i updated the temp literals
             
            onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
          }}
        />
      </div>
    );
  }, [loading, styleColors, startTutorial, scIndex, stepIndex, rows]);

  const productDetails = useMemo(() => ((
    <ProductDetailContainer
      rowCount={rows?.length}
      styleColor={modalStyleColor}
      setIsOpen={setOpen}
      setIndex={setSCIndex}
      index={scIndex}
      modal
      optionalColumns={['qualityCode']}
    />
  )), [rows, modalStyleColor, scIndex]);

  return (
    <div className="stockroom-organization" data-testid="stockroom-organization">
      <div className="header flex-row">
        <div className="title-block">
          <div className="feature-title">
            {getMessage('stockroomOrganization')}
          </div>
        </div>
      </div>
      <br />
      { pageContent }
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        data-testid="stockroomOrganizationDialog"
        PaperProps={{
          style: {
            minHeight: '60%',
            minWidth: '85%',
            maxWidth: '85%',
          },
        }}
      >
        {productDetails}
      </Dialog>
    </div>
  );
};

export default StockroomOrganizationPage;
