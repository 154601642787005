 
export const stockRoomOrgSteps = [
  {
    content: 'stockRoomOrgS1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'stockRoomOrgS1Title',
  },
  {
    content: 'stockRoomOrgS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'stockRoomOrgS2Title',
  },
  {
    content: 'stockRoomOrgS3',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'table tbody tr:first-of-type',
    title: 'stockRoomOrgS3Title',
  },
];
