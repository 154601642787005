import React, { useContext } from 'react';
import { ScanAccuracyWidget } from '../../../components/dashboardWidgets';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { useTutorial } from '../../../components/tutorial/useTutorial';

const ScanAccuracy = () => {
  const { scansLoading, data } = useContext(DashboardContext);
  const {
    locale, storeConfig, getMessage, activateTutorials,
  } = useContext(SimWebContext);
  const { tutorial } = useTutorial('scanAccuracy');

  const rfidScanAccuracyWidgetEnabled = storeConfig?.rfidscanAccuracyWidget?.value;

  return (rfidScanAccuracyWidgetEnabled
    && (
      <div>
        <div style={{ color: 'white', paddingTop: '10px' }}>
          {activateTutorials && tutorial}
        </div>

        <div className="scanAccuracyWidget-container">
          <div className="scanAccuracyWidget-header">
            <h4>{getMessage('accuracy')}</h4>
          </div>
          <ScanAccuracyWidget locale={locale} data={data?.scanReport} loading={scansLoading} />
        </div>
      </div>
    )
  );
};

export default ScanAccuracy;
