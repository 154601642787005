import React, { useContext, useState } from 'react';
import { Dialog } from '@mui/material';
import Button from '../../components/Button';
import ReceivingLogSummaryTable from '../../components/receivingLogSummaryTable/ReceivingLogSummaryTable';
import { LoadingIndicator } from '../../assets/Svgs/index';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import ReceivingLogTable from './ReceivingLogTable';
import ReceiveShipmentModal from './receiveShipment/ReceiveShipmentModal';
import BarcodeScanner from '../../components/barcodeScanner/BarcodeScanner';
import ErrorAlert from '../../components/alerts/Error';

import { SimWebContext } from '../../context/SimWeb.provider';
import { ReceivingLogContext } from './ReceivingLog.provider';

import './ReceivingLogPage.css';

export const ReceivingLogPage = () => {
  const { getMessage, isMobile } = useContext(SimWebContext);
  const {
    details, summary, startDate, endDate, onGoClick, reportType, isFetchingJobId, isFetchingSummary, error, resetModal,
    receiveShipmentInput, setReceiveShipmentInput, isOutsideRange,
  } = useContext(ReceivingLogContext);

  const [openModal, setOpenModal] = useState(false);
  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);

  const openReceiveShipmentModal = () => {
    resetModal();
    setOpenModal(true);
  };

  const updateSearchParam = (val) => {
    const values = receiveShipmentInput.concat(val);
    setIsBarcodeOpen(false);
    setReceiveShipmentInput(values);
  };

  const enabled = false;

  return (
    <div className="receiving-log flex-column" data-testid="receiving-log">
      <div className="header flex-row">
        <div className="title-block">
          <div className="feature-title">
            {getMessage('receivingLog')}
          </div>
          <div className="feature-subtitle">
            {reportType === 'MISSING' ? getMessage('missingCartons') : getMessage('fullReport')}
          </div>
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block">
            <DateRangeWithOptions
              optionsLabel="View"
              options={[
                { label: getMessage('missingCartons'), value: 'MISSING' },
                { label: getMessage('fullReport'), value: 'FULL' },
              ]}
              start={startDate.toISO()}
              end={endDate.toISO()}
              onClick={onGoClick}
              isOutsideRange={isOutsideRange}
              disabled={isFetchingJobId && isFetchingSummary}
            />
          </div>
        </div>
      </div>
      {!isFetchingJobId && !isFetchingSummary && error
        && (
          <ErrorAlert
            errorObject={error.message}
            apiName="Receiving Log Page"
            pageCode=""
          />
        )}
      {!isFetchingJobId && !isFetchingSummary && (
        <>
          <div className="summary flex-row">
            <div className="summary-table-container">
              <span className="summary-table-title">{`${getMessage('receiving')} ${getMessage('summary')}`}</span>
              <div className="summary-table-data-container">
                <ReceivingLogSummaryTable data={summary} />
              </div>
            </div>
            {enabled && (
              <Button
                onClick={() => openReceiveShipmentModal()}
                buttonText={getMessage('receive')}
                extraClasses="receive-shipment-button"
                data-testid="receiving-log-receive-shipment-button"
                darkMode
              />
            )}
          </div>
          <div className="content">
            <ReceivingLogTable
              data={details}
              reportType={reportType}
            />
          </div>
        </>
      )}
      { (isFetchingJobId || isFetchingSummary) && (<div className="content"><LoadingIndicator /></div>) }
      {enabled && (
        <>
          <Dialog
            open={openModal}
            fullScreen={false}
            maxWidth="md"
            fullWidth
          >
            <ReceiveShipmentModal setOpenModal={setOpenModal} setIsBarcodeOpen={setIsBarcodeOpen} />
          </Dialog>
          <Dialog
            open={isBarcodeOpen}
            onClose={() => setIsBarcodeOpen(false)}
            fullScreen={isMobile}
            maxWidth="lg"
          >
            <BarcodeScanner
              updateSearchParam={updateSearchParam}
              setIsBarcodeOpen={setIsBarcodeOpen}
              multiScanEnabled
            />
          </Dialog>
        </>
      )}
    </div>
  );
};

export default (ReceivingLogPage);
