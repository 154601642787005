 
export const oosAuditSteps = [
  {
    content: 'Select a start and end date to search for specific audit details between a range of dates',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="date-pickers-container"]',
    title: 'inboundVisibilityS1Title',
  },
  {
    content: 'Click on the "Go" button to search the audit details for the selected dates',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="date-range-submit-button"]',
    title: 'inboundVisibilityS2Title',
  },
  {
    content: 'Use the options in the header to search, download, print, or filter the audit data',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'Select a single Style-Color from the data table for additional product information',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'Select An Audit Number',
  },
  {
    content: 'Select the print icon to print the audit details',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="oos-dialog-muiDataTable-print"]',
    title: 'Printng Specific Audit',
  },
];
