 
 
import React, { useContext } from 'react';
import { tutorialContext } from './Tutorial.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import Tutorial from './Tutorial';

export const useTutorial = (widgetName = null, disabled = false) => {
  const { activateTutorials, isTutorialEnabled } = useContext(SimWebContext);
  const context = useContext(tutorialContext);
  const {
    startTutorial, handleStartTutorial, stepIndex, setStepIndex, dashboardWidget, setTutorialRestart, isbackClicked, loadingTutorial, restartTutorial,
  } = context;

  return {
    activateTutorials,
    startTutorial,
    handleStartTutorial,
    isTutorialEnabled,
    stepIndex,
    setStepIndex,
    dashboardWidget,
    setTutorialRestart,
    restartTutorial,
    isbackClicked,
    loadingTutorial,
    tutorial: isTutorialEnabled && <Tutorial setTutorialState={handleStartTutorial} disabled={disabled} widgetName={widgetName} />,
    getTutorial: (disabled, widgetName) => isTutorialEnabled && <Tutorial setTutorialState={handleStartTutorial} disabled={disabled} widgetName={widgetName} />,
  };
};
