import React, { useContext } from 'react';
import { string } from 'prop-types';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { ScanComplianceWidget } from '../../../components/dashboardWidgets';
import { useTutorial } from '../../../components/tutorial/useTutorial';

const ScanCompliance = ({ rfidFullStoreScanDate }) => {
  const { scansLoading, data } = useContext(DashboardContext);
  const {
    locale, storeConfig, getMessage, activateTutorials,
  } = useContext(SimWebContext);
  const { tutorial } = useTutorial('scanCompliance');

  const rfidScanComplianceWidgetEnabled = storeConfig?.rfidscanComplianceWidget?.value;

  return (rfidScanComplianceWidgetEnabled
    && (
      <div>
        <div style={{ color: 'white' }}>
          {activateTutorials && tutorial}
        </div>
        <div className="scanComplianceWidget-container">
          <div className="scanComplianceWidget-header">
            <h4>{getMessage('compliance')}</h4>
          </div>
          <ScanComplianceWidget
            locale={locale}
            data={data?.getScanCompliance(locale, rfidFullStoreScanDate)}
            loading={scansLoading}
            rfidFullStoreScanDate={rfidFullStoreScanDate}
          />
        </div>
      </div>
    )
  );
};

ScanCompliance.propTypes = {
  rfidFullStoreScanDate: string.isRequired,
};

export default ScanCompliance;
