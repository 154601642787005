 
 

import axios from 'axios';
import { routerUrls, baseUrl } from './endpoints';
import { logErrorAction, newRelicAction } from '../utils/newRelicPageActions';
import {
  setDefaultHeaders, wait, setCache, refreshToken,
} from './axiosFunctions';

// 1 hour
const CACHE_EXPIRES_TIME = 60 * 60 * 1000;

function JobPullingException(errors, jobId) {
  this.name = 'Job Error';
  this.message = `${errors[0].code}: ${errors[0].message}. JobId: ${jobId}`;
}

/**
 * Creates a data pull job and checks the status until it is completed.
 *
 * @param {*} router the router the backend service is behind
 * @param {*} endpoint the service to pull data from
 * @param {*} payload object being sent to the backend
 * @param {boolean} cache a value that controls caching the data
 * @param {*} timeout how long to wait in between job status checks
 * @returns a jobId string
 */
export const createAndMonitorJob = async (router, endpoint, payload, cache, timeout = 3000) => {
  let jobId;
  let jobStatus;
  let count = 0;
  const startTime = Date.now();

  const authStr = sessionStorage.getItem('login');
  const auth = JSON.parse(authStr);
  let authToken = auth?.access_token;

  if (authToken) {
    setDefaultHeaders(authToken);
    // Create the job and retrieve the jobId
    const job = await axios.post(baseUrl(router) + endpoint.url, payload)
      .then(resp => resp?.data)
      .catch(error => {
        logErrorAction(`${endpoint.name} error`, error, 'error fetching data', {
          customMessage: `${error?.message}.`,
        });
        throw error;
      });
    jobId = job?.id || job?.jobId;
    jobStatus = job?.status;

    // poll until the job returns with a status of completed.
    while (!(jobStatus === 'COMPLETED' || jobStatus === 'DONE')) {
      const jobUrl = `${baseUrl(router)}${endpoint.url}/jobs/${jobId}?${Math.random()}`;

      setDefaultHeaders(authToken);

      await axios.get(jobUrl)
        .then(resp => {
          jobStatus = resp?.data?.status;
          if (cache && jobStatus === 'COMPLETED') {
            setCache(endpoint.url, {
              job,
              params: endpoint,
            }, CACHE_EXPIRES_TIME,
            sessionStorage);
          }
          // This is to catch errors that have recently been returning with jobs since they're not throwing
          // an error in the response body.  Potentially temporary.
          if (resp?.data?.errors?.length > 0) {
            throw new JobPullingException(resp.data.errors, jobId);
          }
        })
        .catch(async error => {
          if (error?.response?.status === 401 || error?.response?.status === 403) {
            authToken = await refreshToken();
            count += 1;
            if (count >= 5) {
              logErrorAction(`[${endpoint.name} error]`, error, 'auth token failure', {
                customMessage: `[${endpoint.name} Job Auth Error]: Message: ${error?.message}`,
              });
              throw error;
            }
          } else {
            logErrorAction(`[${endpoint.name} error]`, error, 'error while waiting for job', {
              customMessage: `[${endpoint.name} Job Error]: Message: ${error?.message}.  JobId: ${jobId}`,
            });
            throw error;
          }
        });
      await wait(timeout);
    }
  }

  const duration = Date.now() - startTime;
  newRelicAction(
    'sim-backend-performance',
    {
      source: 'axios',
      queryName: `${endpoint.name}-job-polling`,
      url: endpoint.url,
      report: window.location.href.split('/')[3],
      duration,
    },
  );

  return jobId;
};

/**
 * Loops until all the pages are pulled from a completed job
 * @param {*} jobId id of the completed job
 * @param {*} router the router the backend service is behind
 * @param {*} endpoint the service to pull data from
 * @param {boolean} cache a value that controls caching the data
 * @returns an array of objects, or a single object or an error message on failure.
 */
export const retrieveJobData = async (jobId, router, endpoint, cache, count = 25) => {
  let anchor;
  let results = [];
  const startTime = Date.now();

  const authStr = sessionStorage.getItem('login');
  const auth = JSON.parse(authStr);
  const authToken = auth?.access_token;

  if (jobId && authToken) {
    setDefaultHeaders(authToken);

    while (true) {
      let jobUrl = `${baseUrl(router)}${endpoint.url}/${jobId}?count=${count}`;

      if (anchor) {
        if (router === routerUrls.SIMWEB_BFF) {
          const newAnchor = /[^/]*$/.exec(anchor)[0];
          jobUrl = `${baseUrl(router)}${endpoint.url}/${newAnchor}`;
        } else {
          jobUrl = baseUrl(router) + anchor;
        }
      }

      await axios.get(jobUrl)
        .then(response => {
          if (response && Array.isArray(response?.data?.objects)) {
            /* eslint-disable-next-line no-unsafe-optional-chaining */
            results = [...results, ...response?.data?.objects];
            anchor = response?.data?.pages?.next;
            if (cache && response) {
              setCache(endpoint.url, {
                jobId,
                params: endpoint,
              }, CACHE_EXPIRES_TIME,
              sessionStorage);
            }
          } else {
            results = response?.data || {};
            if (cache && response) {
              setCache(endpoint.url, {
                results: response,
                params: endpoint,
              }, CACHE_EXPIRES_TIME,
              sessionStorage);
            }
          }
        })
        .catch(error => {
          logErrorAction(`[${endpoint.name} error]`, error, 'error fetching data', {
            customMessage: `[${endpoint.name} Error]: Message: ${error?.message}. JobId: ${jobId}`,
          });
          throw error;
        });
      if (!anchor) {
        break;
      }
    }
    return results;
  }

  const duration = Date.now() - startTime;
  newRelicAction(
    'sim-backend-performance',
    {
      source: 'axios',
      queryName: `${endpoint.name}-paginated-fetching`,
      report: window.location.href.split('/')[3],
      url: endpoint.url,
      duration,
    },
  );

  return results;
};
