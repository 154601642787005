import React, { useMemo, useContext, useEffect } from 'react';
import { orderBy } from 'lodash';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import { LoadingIndicator } from '../../assets/Svgs/index';
import MissingStylesDialog from './MissingStylesDialog';
import { SimWebContext } from '../../context/SimWeb.provider';
import { sortByDefault } from './missingStyles.utils';
import { defaultSort } from '../../utils/sort/defaultSort';
import { newRelicAction } from '../../utils/newRelicPageActions';

import './MissingStylesReport.css';
import { MissingStylesContext } from './MissingStyles.provider';
import ErrorAlert from '../../components/alerts/Error';

/**
 * MissingStylesReport
 * A Report that tracks missing styles within a store
*/
const MissingStylesPage = () => {
  const { getMessage, isPrintingEnabled } = useContext(SimWebContext);
  const {
    onGoClick, startDate, endDate, fetchingData, rows, setRows, setSessionId, index,
    setIndex, newSort, setNewSort, handleDialogOpen, setMissingStyleDate, missingStyleDate,
    rowCount, isDialogOpen, setDialogOpen, error, isOutsideRange,
  } = useContext(MissingStylesContext);

  const startTime = Date.now();

  // log session time after page unrendered
  useEffect(() => () => {
    const duration = Date.now() - startTime;
    newRelicAction('missingStylesSessionDuration', { duration });
  }, []);

  const theme = createTheme(({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
    },
  }));

  const pageContent = useMemo(() => {
    if (fetchingData) return <LoadingIndicator />;
    if (!fetchingData && error) {
      return (
        <ErrorAlert
          errorObject={error.message}
          apiName="Missing Styles Page"
          pageCode=""
        />
      );
    }

    const columns = [{
      name: 'date',
      label: getMessage('date'),
      options: {
        filterType: 'multiselect',
        sort: true,
      },
    }, {
      name: 'time',
      label: getMessage('time'),
      options: {
        filter: false,
      },
    }, {
      name: 'unrepresentedProductCount',
      label: getMessage('missingStyleColors'),
      options: {
        filter: false,
      },
    }, {
      name: 'unrepresentedApparelCount',
      label: getMessage('missingApparel'),
      options: {
        filter: false,
      },
    }, {
      name: 'unrepresentedFootwearCount',
      label: getMessage('missingFootwear'),
      options: {
        filter: false,
      },
    }];

    return (
      <div data-testid="missingScanReportTable">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              data={rows}
              columns={columns}
              options={{
                selectableRows: 'none',
                rowsPerPage: 100,
                rowsPerPageOptions: [15, 25, 50, 100],
                print: isPrintingEnabled,
                responsive: 'standard',
                sort: true,
                sortOrder: !newSort ? { name: 'date', direction: 'desc' } : null, // default sort set here
                onRowClick: (rowData, { dataIndex }) => {
                  const { sessionId, date } = rows[dataIndex];
                  setMissingStyleDate(date);
                  setIndex(dataIndex);
                  setSessionId(sessionId);
                  handleDialogOpen();
                },
                onColumnSortChange: (changedColumn, direction) => {
                  setRows(orderBy(rows, changedColumn, [`${direction}`]));
                  setNewSort(true);
                },
                customSort: (data, dataIndex, rowIndex) => {
                  // need to obtain the default sorted data for modal navigation
                  if (rows?.length && dataIndex === 0 && !newSort) {
                    setRows(sortByDefault(rows, 'date', rowIndex));
                    setNewSort(true);
                    return data;
                  }
                  return defaultSort(data, dataIndex, rowIndex);
                },
                downloadOptions: {
                  filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                  },
                },
                // ignoring lint rule bc the data isn't rendering properly if i update to the perfered-template
                 
                onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
              }}
              className="missing-scan-table"
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }, [fetchingData]);

  const dialogContent = useMemo(() => (
    <MissingStylesDialog
      isDialogOpen={isDialogOpen}
      handleDialogClose={setDialogOpen}
      index={index}
      rowCount={rowCount}
      setIndex={setIndex}
      nodeTitle={missingStyleDate}
    />
  ), [index, setIndex, missingStyleDate, rowCount, isDialogOpen]);

  return (
    <div
      className="missing-scan-report flex-column"
      data-testid="missing-scan-report"
    >
      <div className="header flex-row">
        <div className="title-block">
          <div className="title feature-title">
            {getMessage('missingStylesReport')}
          </div>
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block">
            <DateRangeWithOptions
              optionsLabel="View"
              start={startDate}
              end={endDate}
              onClick={onGoClick}
              disabled={fetchingData}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
      </div>
      {dialogContent}
      {pageContent}
    </div>
  );
};

export default MissingStylesPage;
