 
 
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import withStyles from '@mui/styles/withStyles';
import { CheckCircleOutlineSharp, TimerOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Review, Time, Close, Checkmark, LoadingIndicator,
} from '../../../assets/Svgs/index';
import LoadingAnnimation from '../../loadingAnnimation/LoadingAnnimation';
import { SimDateTime } from '../../../utils/datetime';
import { ScanTimerContext, scanTypes } from '../../../context/ScanTimer.provider';
import { tutorialContext } from '../../tutorial/Tutorial.provider';
import { setScanTimerWidgetTutorialSteps } from './scanTimerWidget.utils';

import './ScanTimerWidget.css';
import { SimWebContext } from '../../../context/SimWeb.provider';

const ScanStatusToolTip = withStyles(() => ({
  arrow: {
    color: '#292929',
  },
  tooltip: {
    backgroundColor: '#292929',
    color: '#DEDEDE',
    accentColor: '#292929',
    fontWeight: 500,
    fontSize: '10px',
    border: '1px solid black',
    padding: '10px',
  },
}))(Tooltip);

const ScanTimerWidget = () => {
  const { activateTutorials, getMessage } = useContext(SimWebContext);
  const {
    scanStatus, scanType, scanStartDate, scanTimer, scanTimedOut, addLeadingZeros, setShowScan, inReviewScanId,
  } = useContext(ScanTimerContext);
  const {
    isbackClicked, restartTutorial, stepIndex, startTutorial, dashboardWidget,
  } = useContext(tutorialContext);

  const [tutorialScanStartDate, setTutorialScanStartDate] = useState('2023-01-01T21:00:00.000Z');
  const [tutorialScanStatus, setTutorialScanStatus] = useState('IN_REVIEW');
  const [tutorialScanTimer, setTutorialScanTimer] = useState({ hours: 3, minutes: 12, seconds: 59 });
  const [tutorialScanTimeout, setTutorialScanTimeout] = useState(false);
  const [tutorialScanType, setTutorialScanType] = useState('FULL_STORE_SCAN');
  const [currentScanStatus, setCurrentScanStatus] = useState(null);

  const endDate = SimDateTime.endOfDay(SimDateTime.now()).toISO();

  const getScanTimerClock = (data) => {
    if (data) {
      return (
        <p data-testid="timerWidget-timer" style={{ color: data && data.hours < 1 ? 'red' : 'inherit' }}>
          {`${addLeadingZeros(data.hours)}:${addLeadingZeros(data.minutes)}:${addLeadingZeros(data.seconds)}`}
        </p>
      );
    }

    return (
      <div className="timerWidget_timer_loading">
        <LoadingIndicator width="75px" height="75px" fill="#FFFFFF" />
      </div>
    );
  };

  const getReviewSubmittedDate = (date) => (date ? `${getMessage('submittedForReview')} ${new Date(date).toLocaleString('en-us')}` : null);

  useEffect(() => {
    setScanTimerWidgetTutorialSteps(
      isbackClicked, restartTutorial, startTutorial, stepIndex, dashboardWidget, setTutorialScanStartDate,
      setTutorialScanStatus, setTutorialScanTimer, setTutorialScanTimeout, setTutorialScanType,
    );
  }, [startTutorial, isbackClicked, restartTutorial, stepIndex, dashboardWidget]);

  useEffect(() => {
    setCurrentScanStatus(activateTutorials ? tutorialScanStatus : scanStatus);
  }, [activateTutorials, scanStatus, tutorialScanStatus]);

  const onScanTimerWidgetClick = () => {
    setShowScan(true);
  };

  const statuses = useMemo(() => {
    const getDescription = (message) => <div className="timerWidget_scanStatus_description"><p>{getMessage(message)}</p></div>;
    const getNoScanInReview = (message) => (
      <div className="timerWidget_unavailable" data-testid="timerWidget-timerReplacement">
        <TimerOff color="error" fontSize="large" />
        <br />
        <p>
          {getMessage(message)}
        </p>
      </div>
    );

    switch (currentScanStatus) {
      case 'SUBMITTED':
        return {
          name: currentScanStatus,
          description: getDescription('scanTimerNoPendingScan'),
          icon: <Checkmark width="20px" height="20px" fill="#8AA58F" />,
          noScanInReview: (
            <div className="timerWidget_unavailable" data-testid="timerWidget-timerReplacement">
              <CheckCircleOutlineSharp style={{ color: '#8AA58F' }} fontSize="large" />
              <br />
              <p>{getMessage('scanTimerSubmitted')}</p>
            </div>
          ),
        };
      case 'IN_REVIEW':
        return {
          name: currentScanStatus,
          description: getDescription('scanTimerPendingReview'),
          icon: <Review fill="#17a2b8" width="20px" height="20px" />,
          noScanInReview: null,
        };
      case 'CANCELLED':
        return {
          name: currentScanStatus,
          description: getDescription('messageResubmitScan'),
          icon: <Close width="16px" height="16px" fill="#c05850" />,
          noScanInReview: getNoScanInReview('scanTimerCancelled'),
        };
      case 'TIMED_OUT':
        return {
          name: currentScanStatus,
          description: getDescription('messageResubmitScan'),
          icon: <Time width="20px" height="20px" fill="#F2A127" />,
          noScanInReview: getNoScanInReview('scanTimerTimedOut'),
        };
      case 'SCANNING':
        return {
          name: currentScanStatus,
          description: getDescription('scanTimerScanning'),
          icon: <LoadingAnnimation />,
          noScanInReview: null,
        };

      default:
        return {};
    }
  }, [currentScanStatus, getMessage]);

  const scanTimerBody = useMemo(() => {
    const scanIsNotTimedOut = activateTutorials ? !tutorialScanTimeout : !scanTimedOut;

    return (
      <div className="timerWidget_card-details">
        <div className="timerWidget_text_container">
          <div className="timerWidget_text_title">
            <ScanStatusToolTip title={scanStatus} placement="top">
              <div className={(statuses?.name === 'SCANNING' ? 'timerWidget_scanning_toolTip' : 'timerWidget_toolTip')}>
                {statuses?.icon}
              </div>
            </ScanStatusToolTip>
            <p className="scanType_title">
              { getMessage(scanTypes[activateTutorials ? tutorialScanType : scanType]) }
            </p>
          </div>
          <p className="scan_date">
            { getReviewSubmittedDate(activateTutorials ? tutorialScanStartDate : scanStartDate) }
          </p>
          {statuses?.description}
        </div>
        <div className="timerWidget_timer_container" data-testid="timerWidget_timer_container">
          { scanIsNotTimedOut && statuses?.name === 'IN_REVIEW'
            ? (
              <div className="timerWidget_timer" data-testid="timerWidget-timerContainer">
                <p>
                  { getMessage('timeRemaining') }
                </p>
                {' '}
                { getScanTimerClock(activateTutorials ? tutorialScanTimer : scanTimer) }
              </div>
            ) : statuses?.noScanInReview}
        </div>
      </div>
    );
  }, [statuses, scanStatus, scanType, scanStartDate, scanTimer, activateTutorials, tutorialScanType, tutorialScanTimer, tutorialScanTimeout, tutorialScanStartDate]);

  const body = useMemo(() => {
    let body = (
      <Link to={`/scan/${inReviewScanId}/${endDate}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div onClick={() => onScanTimerWidgetClick()}>
          {scanTimerBody}
        </div>
      </Link>
    );

    if (activateTutorials) {
      body = (
        <div data-testid="timerWidget-card-tutorial">
          {scanTimerBody}
        </div>
      );
    }
    return body;
  }, [scanTimerBody, inReviewScanId, activateTutorials]);

  return (
    <div className="timerWidget_dashboard-card" data-testid="scanTimerWidget">
      {body}
    </div>
  );
};

export default (ScanTimerWidget);
