export const mockScanDetails = {
  aggregations: {
    accuracy: 99,
    expected: 30379,
    extra: 347,
    missing: 339,
    offsite: 0,
    salesFloor: 19745,
    scanned: 30387,
    stockRoom: 10642,
  },
  approvalDuration: 12,
  graphAggregations: {
    genderAge: {
      Mens: {
        apparel: {
          missing: {
            units: 12,
            value: 49.98,
          },
          extra: {
            units: 10,
            value: 0,
          },
        },
        footwear: {
          missing: {
            units: 40,
            value: 10,
          },
          extra: {
            units: 20,
            value: 0,
          },
        },
        equipment: {
          missing: {
            units: 40,
            value: 10,
          },
          extra: {
            units: 10,
            value: 0,
          },
        },
      },
      Womens: {
        apparel: {
          missing: {
            units: 20,
            value: 49.98,
          },
          extra: {
            units: 19,
            value: 0,
          },
        },
        footwear: {
          missing: {
            units: 86,
            value: 19,
          },
          extra: {
            units: 0,
            value: 0,
          },
        },
        equipment: {
          missing: {
            units: 10,
            value: 0,
          },
          extra: {
            units: 0,
            value: 0,
          },
        },
      },
    },
    retailCategory: {
      ActionSports: {
        apparel: {
          extra: { unit: 2, value: 59.98 },
          missing: { units: 2, value: 59.98 },
        },
        footwear: {
          extra: { unit: 2, value: 0 },
          missing: { units: 1, value: 0 },
        },
        AmericanFootball: {
          apparel: {
            extra: { unit: 5, value: 59.98 },
            missing: { units: 12, value: 59.98 },
          },
          footwear: {
            extra: { unit: 10, value: 30.50 },
            missing: { units: 1, value: 80.00 },
          },
          equipment: {
            extra: { unit: 2, value: 0 },
            missing: { units: 1, value: 0 },
          },
        },
        Basketball: {
          apparel: {
            extra: { unit: 5, value: 59.98 },
            missing: { units: 12, value: 59.98 },
          },
          footwear: {
            extra: { unit: 10, value: 30.50 },
            missing: { units: 1, value: 80.00 },
          },
          equipment: {
            extra: { unit: 2, value: 0 },
            missing: { units: 1, value: 0 },
          },
        },
        Jordan: {
          apparel: {
            extra: { unit: 1, value: 120.00 },
            missing: { units: 6, value: 80.00 },
          },
          footwear: {
            extra: { unit: 7, value: 0 },
            missing: { units: 2, value: 0 },
          },
        },
        MensTraining: {
          apparel: {
            extra: { unit: 1, value: 120.00 },
            missing: { units: 6, value: 80.00 },
          },
          footwear: {
            extra: { unit: 7, value: 0 },
            missing: { units: 2, value: 0 },
          },
        },
        WomensTraining: {
          apparel: {
            extra: { unit: 2, value: 220.00 },
            missing: { units: 10, value: 80.00 },
          },
          footwear: {
            extra: { unit: 4, value: 45.96 },
            missing: { units: 12, value: 120.33 },
          },
        },
      },
    },
  },
  numberOfScanners: 3,
  resumeCount: 1,
  scanDate: 'Tuesday, December 31, 2024',
  scanDuration: 60,
  scanEndDate: '2024-12-31T11:13:09.865610Z',
  scanStartDate: '2024-12-31T10:01:34.666316Z',
  scanType: 'FULL_STORE_SCAN',
  sessionId: '9e7f0d08-2716-3851-9cd6-4b8d5ec0c832',
  status: 'SUBMITTED',
};

export const mockScaninReviewDetails = {
  aggregations: {
    accuracy: 99,
    expected: 30379,
    extra: 347,
    missing: 339,
    offsite: 0,
    salesFloor: 19745,
    scanned: 30387,
    stockRoom: 10642,
  },
  approvalDuration: 12,
  graphAggregations: {
    genderAge: {
      Mens: {
        apparel: {
          missing: {
            units: 12,
            value: 49.98,
          },
          extra: {
            units: 10,
            value: 0,
          },
        },
        footwear: {
          missing: {
            units: 40,
            value: 10,
          },
          extra: {
            units: 20,
            value: 0,
          },
        },
        equipment: {
          missing: {
            units: 40,
            value: 10,
          },
          extra: {
            units: 10,
            value: 0,
          },
        },
      },
      Womens: {
        apparel: {
          missing: {
            units: 20,
            value: 49.98,
          },
          extra: {
            units: 19,
            value: 0,
          },
        },
        footwear: {
          missing: {
            units: 86,
            value: 19,
          },
          extra: {
            units: 0,
            value: 0,
          },
        },
        equipment: {
          missing: {
            units: 10,
            value: 0,
          },
          extra: {
            units: 0,
            value: 0,
          },
        },
      },
    },
    retailCategory: {
      ActionSports: {
        apparel: {
          extra: { unit: 2, value: 59.98 },
          missing: { units: 2, value: 59.98 },
        },
        footwear: {
          extra: { unit: 2, value: 0 },
          missing: { units: 1, value: 0 },
        },
        AmericanFootball: {
          apparel: {
            extra: { unit: 5, value: 59.98 },
            missing: { units: 12, value: 59.98 },
          },
          footwear: {
            extra: { unit: 10, value: 30.50 },
            missing: { units: 1, value: 80.00 },
          },
          equipment: {
            extra: { unit: 2, value: 0 },
            missing: { units: 1, value: 0 },
          },
        },
        Basketball: {
          apparel: {
            extra: { unit: 5, value: 59.98 },
            missing: { units: 12, value: 59.98 },
          },
          footwear: {
            extra: { unit: 10, value: 30.50 },
            missing: { units: 1, value: 80.00 },
          },
          equipment: {
            extra: { unit: 2, value: 0 },
            missing: { units: 1, value: 0 },
          },
        },
        Jordan: {
          apparel: {
            extra: { unit: 1, value: 120.00 },
            missing: { units: 6, value: 80.00 },
          },
          footwear: {
            extra: { unit: 7, value: 0 },
            missing: { units: 2, value: 0 },
          },
        },
        MensTraining: {
          apparel: {
            extra: { unit: 1, value: 120.00 },
            missing: { units: 6, value: 80.00 },
          },
          footwear: {
            extra: { unit: 7, value: 0 },
            missing: { units: 2, value: 0 },
          },
        },
        WomensTraining: {
          apparel: {
            extra: { unit: 2, value: 220.00 },
            missing: { units: 10, value: 80.00 },
          },
          footwear: {
            extra: { unit: 4, value: 45.96 },
            missing: { units: 12, value: 120.33 },
          },
        },
      },
    },
  },
  numberOfScanners: 2,
  resumeCount: 0,
  scanDate: 'Tuesday, December 31, 2024',
  scanDuration: 60,
  scanEndDate: '2024-12-31T11:13:09.865610Z',
  scanStartDate: '2024-12-31T10:01:34.666316Z',
  scanType: 'FULL_STORE_SCAN',
  sessionId: '9e7f0d08-2716-3851-9cd6-4b8d5ec0c832',
  status: 'IN_REVIEW',
  totalVariances: {
    missing: { units: 2, value: 240 },
    extra: { units: 6, value: 509 },
  },
};

export const mockScanItems = [
  {
    absValueVariance: 2,
    productCode: 'BQ5538-091',
    description: 'M J SS CTN AJ85 CREW',
    currentPrice: '24.99',
    division: 'Apparel',
    genderAge: 'Mens',
    retailCategory: 'Jordan',
    missing: 2,
    extra: 0,
    scanned: 10,
    accuracy: 83,
    expected: 12,
    valueVariance: -2,
    rfidSoh: '-',
    totalRfidSoh: 0,
    inventory: {
      fullStore: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 83,
          expected: 12,
        },
        {
          gtin: '001931511706630',
          size: 'S',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 95,
          expected: 12,
        },
        {
          gtin: '0019300531356',
          size: 'M',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 83,
          expected: 12,
        },
        {
          gtin: '00195117032340',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 83,
          expected: 12,
        },
        {
          gtin: '00193123457030',
          size: 'L',
          missing: 2,
          extra: 0,
          scanned: 2,
          accuracy: 50,
          expected: 4,
        },
        {
          gtin: '001931237038641',
          size: 'L',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 10,
        },
      ],
      salesFloor: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 10,
        },
      ],
      stockRoom: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 0,
          accuracy: 0,
          expected: 2,
        },
      ],
      offsite: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 0,
          accuracy: 100,
          expected: 0,
        },
      ],
    },
  },
  {
    absValueVariance: 2,
    productCode: 'A55334-011',
    description: 'Performance Crew Socks',
    currentPrice: '14.99',
    division: 'Apparel',
    genderAge: 'Mens',
    retailCategory: 'Basketball',
    missing: 0,
    extra: 0,
    scanned: 12,
    accuracy: 100,
    expected: 12,
    valueVariance: 20,
    rfidSoh: '-',
    totalRfidSoh: 0,
    inventory: {
      fullStore: [
        {
          gtin: '00193151170320',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 83,
          expected: 12,
        },
      ],
      salesFloor: [
        {
          gtin: '00193151170123',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 2,
        },
      ],
      stockRoom: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 0,
          accuracy: 0,
          expected: 0,
        },
      ],
      offsite: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 0,
          accuracy: 100,
          expected: 2,
        },
      ],
    },
  },
  {
    absValueVariance: 2,
    productCode: 'F31684-093',
    description: 'Womens Training Tights',
    currentPrice: '80.00',
    division: 'Apparel',
    genderAge: 'Womens',
    retailCategory: 'Lifestyle',
    missing: 0,
    extra: 0,
    scanned: 12,
    accuracy: 100,
    expected: 12,
    valueVariance: 20,
    rfidSoh: '-',
    totalRfidSoh: 0,
    inventory: {
      fullStore: [
        {
          gtin: '00193151170320',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 94,
          expected: 12,
        },
      ],
      salesFloor: [
        {
          gtin: '00193151170123',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 2,
        },
      ],
      stockRoom: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 0,
          accuracy: 0,
          expected: 0,
        },
      ],
      offsite: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 0,
          accuracy: 100,
          expected: 2,
        },
      ],
    },
  },
  {
    absValueVariance: 2,
    productCode: 'M3184-212',
    description: 'Mens Soccer Jersey',
    currentPrice: '80.00',
    division: 'Apparel',
    genderAge: 'Men',
    retailCategory: 'Global Football',
    missing: 0,
    extra: 0,
    scanned: 12,
    accuracy: 100,
    expected: 12,
    valueVariance: 130.00,
    rfidSoh: '-',
    totalRfidSoh: 0,
    inventory: {
      fullStore: [
        {
          gtin: '00193151170320',
          size: 'XS',
          missing: 7,
          extra: 0,
          scanned: 10,
          accuracy: 97,
          expected: 10,
        },
      ],
      salesFloor: [
        {
          gtin: '00193151170123',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 10,
        },
      ],
      stockRoom: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 0,
          accuracy: 0,
          expected: 0,
        },
      ],
      offsite: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 5,
          scanned: 10,
          accuracy: 100,
          expected: 2,
        },
      ],
    },
  },
  {
    absValueVariance: 2,
    productCode: 'D21832-030',
    description: 'Womens Running Shoes',
    currentPrice: '160.00',
    division: 'Footwear',
    genderAge: 'Womens',
    retailCategory: 'Lifestyle',
    missing: 1,
    extra: 0,
    scanned: 30,
    accuracy: 100,
    expected: 30,
    valueVariance: 0,
    rfidSoh: '-',
    totalRfidSoh: 0,
    inventory: {
      fullStore: [
        {
          gtin: '00193151170320',
          size: 'XS',
          missing: 2,
          extra: 0,
          scanned: 10,
          accuracy: 100,
          expected: 12,
        },
      ],
      salesFloor: [
        {
          gtin: '00193151170123',
          size: 'XS',
          missing: 0,
          extra: 0,
          scanned: 10,
          accuracy: 87,
          expected: 2,
        },
      ],
      stockRoom: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 3,
          extra: 0,
          scanned: 0,
          accuracy: 0,
          expected: 0,
        },
      ],
      offsite: [
        {
          gtin: '00193151170316',
          size: 'XS',
          missing: 0,
          extra: 8,
          scanned: 0,
          accuracy: 100,
          expected: 0,
        },
      ],
    },
  },
];
