 
 
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  object, bool, string,
} from 'prop-types';
import { cloneDeep } from 'lodash';
import Button from '../../../components/Button';
import InfoTooltip from '../../../components/InfoTooltip';

import { filterButtonTypes } from '../../styleManagement/styleManagementConstants';
import { newRelicAction } from '../../../utils/newRelicPageActions';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../../components/tutorial/Tutorial.provider';
import './BaseViewButtons.css';

/**
 * A reuseable component to render a list of filtering buttons
 * @param {object} buttons the filter buttons config list
 * @param {string} id the name of the report
 * @param {boolean} isLoading value representing the API call is still loading thus disable the btn
 * @param {function} customButtonSelected a function to handle paired buttons selected - ex: binned and unbinned buttons - omit this if not needed
 * @param {function} updateFilterButtons a function that handles the button obj details
 * @param {function} setButtonFilters a function to set filters used in an API request
 */
const BaseViewButtons = ({ buttons, id, isLoading }) => {
  const { getMessage } = useContext(SimWebContext);
  const { stepIndex, startTutorial, tutorialReport } = useContext(tutorialContext);

  const { list, info, setButtonFilters } = buttons;
  const [filterButtons, updateFilterButtons] = useState(list);

  useEffect(() => {
    updateFilterButtons(list);
  }, [list]);

  const handleButtonSelected = (event, buttons, startTutorial) => {
    const buttonName = event.target.closest('button').id.replace('Button', '');
    let buttonsCopy = cloneDeep(buttons);
    const selectedButton = buttonsCopy?.[buttonName];

    if (selectedButton && typeof (selectedButton.customButtonSelected) === 'function') {
      buttonsCopy = selectedButton.customButtonSelected(buttons, buttonName);
    } else {
      selectedButton.selected = !selectedButton?.selected || false;
    }

    const filters = [];
    if (!startTutorial) {
      Object.values(buttonsCopy).map(filterButton => {
        if (filterButton?.selected && filterButton?.filter) filters.push(filterButton.filter);
      });
      setButtonFilters(filters);
    }

    updateFilterButtons(buttonsCopy);

    if (filters.length > 0) {
      newRelicAction(`${id}-filter-buttons`, { filterType: buttonName });
    } else {
      newRelicAction('no-filter-buttons-selected', { filterType: 'none' });
    }
  };

  const getButtonDetails = useMemo(() => {
    if (!filterButtons) return null;

    return Object.values(filterButtons).map(item => {
      const {
        i18nKey, selected, name, enabled,
      } = item;

      if (!enabled) return null;

      const buttonId = `${name}Button`;
      const svgIcon = filterButtonTypes().getSvgIcon(i18nKey);
      const translatedText = i18nKey === 'Nike.com' ? i18nKey : getMessage(i18nKey);

      // tutorial magic
      let forceSelected = false;
      const tutorialActive = startTutorial && tutorialReport === 'styleManagement' && i18nKey === 'unbinnedStyles';
      if (stepIndex === 1) {
        forceSelected = true;
      }

      return (
        <Button
          onClick={(e) => handleButtonSelected(e, filterButtons, startTutorial)}
          isSelected={tutorialActive ? forceSelected : selected}
          disabled={isLoading}
          id={buttonId}
          key={buttonId}
          dataTestId={`${id}-${buttonId}`}
          buttonText={(
            <div className="buttonContent" key={`${buttonId}-content`}>
              {translatedText}
              {svgIcon}
            </div>
          )}
        />
      );
    });
  }, [isLoading, buttons, filterButtons, getMessage, startTutorial, tutorialReport, stepIndex]);

  return (
    <>
      {info?.title && (
        <div data-testid={`${id}-filter-tooltip`}>
          <InfoTooltip
            title={info?.title}
            content={info?.content}
            disableFocusListener
            disableTouchListener
            placement="right"
          />
        </div>
      )}
      <div className={`${id}Buttons buttonContainer`}>
        <div className="buttonColumn" data-testid={buttons.length > 0 ? 'baseViewButtons' : 'undefined-baseViewButtons'}>
          {getButtonDetails}
        </div>
      </div>
    </>
  );
};

BaseViewButtons.propTypes = {
  buttons: object.isRequired,
  id: string.isRequired,
  isLoading: bool.isRequired,
};

export default BaseViewButtons;
