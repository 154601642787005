import React from 'react';
import { string, oneOfType, number } from 'prop-types';

import './svgs.css';

/**
   * An Eye SVG used in the Scan Report to help display the status of a
   * scan to the  users in the summary table
   * @param {string} fill - adjustable color of SVG
   * @param {string | number} height - height of the SVG
   * @param {string | number} width - width of the SVG
   * @param {string} label - optional value that can be rendered with the SVG
   */
 
export const EyeIcon = ({
  fill, height, width, label,
}) => {
  const svg = (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      className="eyeIcon_svg"
      width={width}
      height={height}
      fill={fill}
    >
      <g>
        <g fill="#231F20">
          <path d="m34,256l26.2,26.2c108,108 283.7,108 391.7,0l26.1-26.2-26.2-26.2c-108-108-283.7-108-391.7,0l-26.1,26.2zm222,126.2c-75.8,0-151.6-28.9-209.3-86.6l-32.9-32.9c-3.7-3.7-3.7-9.7 0-13.5l32.9-32.9c115.4-115.4 303.2-115.4 418.6,0l32.9,32.9c3.7,3.7 3.7,9.7 0,13.5l-32.9,32.9c-57.7,57.7-133.5,86.6-209.3,86.6z" />
          <path d="m256,183.5c-40,0-72.5,32.5-72.5,72.5s32.5,72.5 72.5,72.5c40,0 72.5-32.5 72.5-72.5s-32.5-72.5-72.5-72.5zm0,164c-50.5,0-91.5-41.1-91.5-91.5 0-50.5 41.1-91.5 91.5-91.5s91.5,41.1 91.5,91.5c0,50.5-41,91.5-91.5,91.5z" />
        </g>
      </g>
    </svg>
  );

  return label ? (
    <>
      {`${label} `}
      {svg}
    </>
  ) : svg;
};

EyeIcon.propTypes = {
  fill: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  label: string,
};

EyeIcon.defaultProps = {
  fill: '#000000',
  width: '26px',
  height: '28px',
  label: null,
};
